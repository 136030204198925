import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormService } from 'src/app/core/form.service';

@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.scss']
})
export class PublishComponent implements OnInit {

  private formService
  constructor(public form: FormService) {
    this.formService = form
  }

  ngOnInit(): void {
    console.log("publsg formservice", this.formService)
    console.log(this.status);
  }

  @Input()
  public show;

  @Input()
  public status:boolean;

  @Output()
  public clicked: EventEmitter<any> = new EventEmitter();

  public enable = true;

  clickSave(event) {
    if (event == 'publish') {
      if(this.enable && !this.status){
        this.enable = false;
        this.clicked.next("publish");
      }
    }
    else {
      this.clicked.next('cancel')
    }
  }
}
