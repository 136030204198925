<div class="success">
    <div class="dialog-title">
        <span class="material-icons">
            check_circle_outline
        </span>

        <div class="dialog-desc"> This transaction was successfully sent </div>
    </div>
    <div class="dialog-buttons">

        <div class="dialog-button">
            <a  class="as-btn bg-purple" (click)="clickSave('home')">
                <mat-icon aria-hidden="false" aria-label="Example home icon">
                    home
                </mat-icon>
               <span> My Transactions</span>
            </a>
        </div>


    </div>


</div>

