import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { LoadingComponent } from '../sharedComponent/loading/loading.component';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { type } from 'os';

@Component({
  selector: 'app-et-questions',
  templateUrl: './et-questions.component.html',
  styleUrls: ['./et-questions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EtQuestionsComponent implements OnInit {
  public dialogRef;

  options: string;
  seasons: string[] = ['Response', 'Responsesss', 'Response'];

  selected: { type: string, src: string, title: string, question: string, time: string };
  choices = [];
  ClientAccounts;
  newComDatas: any;
  withoutRefQID = [];
  withRefQID = [];
  selectedAnswer;
  tempAnswer = [];
  loading = true;
  element = document.getElementsByClassName("container-ETModal") as HTMLCollectionOf<HTMLElement>;
  data = this.form.data.etSelectedRow;
  QAData;
  toUpdate = false;
  toDeleteResponse = '';

  constructor(

  public dialog: MatDialog,
  public form: FormService,
  public toastr: ToastrService,
  ) { }

  ngOnInit(): void {

    const loading = this.dialog.open(LoadingComponent, {panelClass: 'custom-dialog'});

    this.loadData().then(newComDatas => {
      this.newComDatas = newComDatas;
      this.form.ETmarkAlert(this.data.alertID, this.data.type, "True").subscribe(data => {
        console.log("newComDatas", this.newComDatas);
        console.log("withoutRefQID", this.withoutRefQID);
        console.log("withRefQID", this.withRefQID);
        this.loading = false;
        loading.close()
      })
    })
  }


  loadData(){
    const loading = this.dialog.open(LoadingComponent, {panelClass: 'custom-dialog'});

    return new Promise((resolve) => {

      var sjon = require('secure-json-parse');
      let clientAccounts = [];
      let newComDatas = [];
      let qaItems = [];

      console.log(this.data)
      let eid;
      // eid = this.form.data.user.eid
      eid = environment.isTestUser == true ? "azam.hashmi" : this.form.data.user.eid;
      // environment.mockClient = false
      if(environment.mockClient){
        this.form.getCDPOperationDataTest(eid).subscribe(data=>{
          let testData = this.form.data.clientAccounts.detail.WeightedOperationDataValues;
          let clientAccountList = [];
          testData.forEach(item => {
            clientAccountList.push({client: item.ClientName})
          })
          clientAccounts = clientAccountList;
          this.ClientAccounts = clientAccountList;
          console.log(clientAccounts);

          this.form.getQADetails(this.data.alertID).subscribe((qaResult:any) => {
            this.QAData = qaResult;
            console.log("Selected Row QA",qaResult,this.form.data.QA);
            for (let i = 0; i < qaResult.length; i++){
              this.checkIsAnswered(qaResult[i]).subscribe((result:any) => {
                console.log("answer result", result);
                let qDetails = this.formatData(sjon.parse(sjon.parse(qaResult[i].Details)), result);
                this.checkRefAnswered(qaResult[i]).subscribe(async (refResult: any) => {
                  let keyAnswer;
                  if(refResult.length > 0) {
                    for (let x = 0; x < refResult.length; x++) {
                      let refKeyAnswer = qaResult[i].keyAnswer
                      keyAnswer = (refResult[x]['Answer' + refKeyAnswer] != ' ') ? true : false
                      if (keyAnswer && this.data.alertStatus === 'Published') {
                        this.markQuestion(qaResult[i].Id).subscribe(res => { console.log("marking question as read", res) })
                        x = refResult.length;
                      }
                    }
                  } else {
                    console.log("refAnswer is less than 0")
                      if ( (qaResult[i].No == 1 || qaResult[i].keyAnswer == undefined ) && this.data.alertStatus === 'Published') {
                        console.log("it should be updating the question status")
                        keyAnswer = true;
                        this.markQuestion(qaResult[i].Id).subscribe(res => { console.log("marking question as read", res) })
                      }
                  }

                  //Handle with and without Client Account/s
                  if (clientAccounts.length == 0) {
                    //No Client Account
                    let resAnswer;
                    let responseDate;
                    let isUpdate;
                    if (refResult.length == 0) {
                      for (let y = 0; y < result.length; y++) {
                        let resultId = result[y].Id.split("|");

                        if (resultId.length == 3) {
                          //Check if question has a response
                          if (result[y].Answer1 != " ") { resAnswer = result[y].Answer1; responseDate = result[y].ResponseDate }
                          if (result[y].Answer2 != " ") { resAnswer = result[y].Answer2; responseDate = result[y].ResponseDate }
                          if (result[y].Answer3 != " ") { resAnswer = result[y].Answer3; responseDate = result[y].ResponseDate }
                          if (result[y].Answer4 != " ") { resAnswer = result[y].Answer4; responseDate = result[y].ResponseDate }
                          if (result[y].Answer5 != " ") { resAnswer = result[y].Answer5; responseDate = result[y].ResponseDate }
                          if (result[y].Answer6 != " ") { resAnswer = result[y].Answer6; responseDate = result[y].ResponseDate }
                          if (result[y].Answer7 != " ") { resAnswer = result[y].Answer7; responseDate = result[y].ResponseDate }
                          if (result[y].Answer8 != " ") { resAnswer = result[y].Answer8; responseDate = result[y].ResponseDate }
                          if (result[y].Answer9 != " ") { resAnswer = result[y].Answer9; responseDate = result[y].ResponseDate }
                          if (result[y].Answer10 != " ") { resAnswer = result[y].Answer10; responseDate = result[y].ResponseDate }

                          if(result[y].isUpdate == "true"){
                            isUpdate = true
                          }else{
                            isUpdate = false;
                          }
                        }
                      }

                      let tempData = {
                        qid: qaResult[i].Id,
                        isAnswered: typeof (resAnswer) != "undefined" ? true : false,
                        question: qDetails.q,
                        choices: qDetails.c,
                        answer: typeof (resAnswer) != "undefined" ? resAnswer : "null",
                        isShow: keyAnswer,
                        no: qaResult[i].No,
                        createdDate: qaResult[i].createdDate,
                        refQID: qaResult[i].refQID,
                        responseDate : typeof (responseDate) != "undefined" ? responseDate : "null",
                        isUpdate: isUpdate ? true : false
                      }
                      qaItems.push(tempData);
                      newComDatas.push({ No: i, isCollapsed: typeof (resAnswer) != "undefined" ? true : false, isShow: keyAnswer, qaItems });
                      newComDatas = newComDatas.sort((a, b) => a.No - b.No)
                      qaItems = [];
                    } else {
                      let isReferenced = false;

                      for (let x = 0; x < refResult.length; x++) {
                        let refClientAccount = null;
                        let resAnswer;
                        let responseDate;
                        let isUpdate;

                        let refResultID = refResult[x].Id.split("|")
                        let refQID = refResultID[0] + "|" + refResultID[1];

                        //Check if reference question has answer
                        if (result.length > 0) {
                          for (let y = 0; y < result.length; y++) {
                            let resultId = result[y].Id.split("|");

                            if (resultId.length == 3) {
                              //Check what Client Account has response
                              if (result[y].Answer1 != " ") { resAnswer = result[y].Answer1; responseDate = result[y].ResponseDate }
                              if (result[y].Answer2 != " ") { resAnswer = result[y].Answer2; responseDate = result[y].ResponseDate }
                              if (result[y].Answer3 != " ") { resAnswer = result[y].Answer3; responseDate = result[y].ResponseDate }
                              if (result[y].Answer4 != " ") { resAnswer = result[y].Answer4; responseDate = result[y].ResponseDate }
                              if (result[y].Answer5 != " ") { resAnswer = result[y].Answer5; responseDate = result[y].ResponseDate }
                              if (result[y].Answer6 != " ") { resAnswer = result[y].Answer6; responseDate = result[y].ResponseDate }
                              if (result[y].Answer7 != " ") { resAnswer = result[y].Answer7; responseDate = result[y].ResponseDate }
                              if (result[y].Answer8 != " ") { resAnswer = result[y].Answer8; responseDate = result[y].ResponseDate }
                              if (result[y].Answer9 != " ") { resAnswer = result[y].Answer9; responseDate = result[y].ResponseDate }
                              if (result[y].Answer10 != " ") { resAnswer = result[y].Answer10; responseDate = result[y].ResponseDate }
                            }

                            if(result[y].isUpdate == "true"){
                              isUpdate = true
                            }else{
                              isUpdate = false;
                            }
                          }
                        }

                        //Set reference client account
                        if (refResultID.length == 4) {
                          refClientAccount = refResultID[3];
                        }

                        //Check if the question is being referenced
                        if (qaResult[i].refQID == refQID && refResult[x]['Answer' + qaResult[i].keyAnswer] != " ") {
                          isReferenced = true;
                        }

                        if (isReferenced == true) {
                          let tempData = {
                            qid: qaResult[i].Id,
                            isAnswered: typeof (resAnswer) != "undefined" ? true : false,
                            question: qDetails.q,
                            choices: qDetails.c,
                            answer: typeof (resAnswer) != "undefined" ? resAnswer : "null",
                            isShow: keyAnswer,
                            no: qaResult[i].No,
                            createdDate: qaResult[i].createdDate,
                            refQID: qaResult[i].refQID,
                            responseDate : typeof (responseDate) != "undefined" ? responseDate : "null",
                            isUpdate: isUpdate ? true : false
                          }

                          qaItems.push(tempData);
                          isReferenced = false;
                        }

                        if (x == refResult.length - 1 && qaItems.length > 0) {
                          let isCollapsed = true;
                          qaItems.forEach(qaData => {
                            if (qaData.isAnswered == false) isCollapsed = false;
                          });
                          newComDatas.push({ No: i, isCollapsed: isCollapsed, isShow: keyAnswer, qaItems });
                          newComDatas = newComDatas.sort((a, b) => a.No - b.No)
                          qaItems = [];
                        }

                      }
                    }

                  }
                  else {
                    //with Client Account
                    if (!qaResult[i].refQID) {
                      //without refQID

                      this.form.getAllAnswered(this.data.alertID).subscribe((answeredData:any)=>{
                        let allAnswers = answeredData.body;
                        let answeredClients = [];
                        let answeredList = [];
                        console.log(allAnswers);
                        for(let a=0; a<allAnswers.length; a++){
                          // if(allAnswers[a].Responder != this.form.data.user.eid && !answeredClients.includes(allAnswers[a].Id.split("|")[3])){
                          if(!answeredClients.includes(allAnswers[a].Id.split("|")[3])){
                            answeredClients.push(allAnswers[a].Responder);
                            answeredList.push({
                              eid: allAnswers[a].Responder,
                              client: allAnswers[a].Id.split("|")[3]
                            })
                          }
                        }

                        for (let x = 0; x < clientAccounts.length; x++) {
                          let resAnswer;
                          let isSelf = false;
                          let userEID = this.form.data.user.eid;
                          let responseDate;
                          let isUpdate;

                          if (result.length > 0) {
                            for (let y = 0; y < result.length; y++) {
                              let resultId = result[y].Id.split("|");

                              if (resultId.length == 4) {
                                //Check what Client Account has response
                                if (resultId[3] == clientAccounts[x].client) {
                                  if (result[y].Answer1 != " ") { resAnswer = result[y].Answer1; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer2 != " ") { resAnswer = result[y].Answer2; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer3 != " ") { resAnswer = result[y].Answer3; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer4 != " ") { resAnswer = result[y].Answer4; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer5 != " ") { resAnswer = result[y].Answer5; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer6 != " ") { resAnswer = result[y].Answer6; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer7 != " ") { resAnswer = result[y].Answer7; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer8 != " ") { resAnswer = result[y].Answer8; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer9 != " ") { resAnswer = result[y].Answer9; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer10 != " ") { resAnswer = result[y].Answer10; responseDate = result[y].ResponseDate }

                                  if (result[y].Responder == this.form.data.user.eid) {
                                    isSelf = true;
                                  } else {
                                    userEID = result[y].Responder;
                                  }

                                  if(result[y].isUpdate == "true"){
                                    isUpdate = true
                                  }else{
                                    isUpdate = false;
                                  }

                                }
                              }
                            }
                          }

                          let answeredEID;
                          let isRequired = true;
                          for(let a=0; a<answeredList.length; a++){
                            if(clientAccounts[x].client == answeredList[a].client){
                              answeredEID = answeredList[a].eid;
                              isRequired = false;
                              if(answeredEID == this.form.data.user.eid){
                                isRequired = true;
                              }
                            }
                          }

                          let tempData = {
                            qid: qaResult[i].Id,
                            isAnswered: typeof (resAnswer) != "undefined" ? true : false,
                            question: qDetails.q,
                            choices: qDetails.c,
                            answer: typeof (resAnswer) != "undefined" ? resAnswer : "null",
                            isShow: keyAnswer,
                            isSelf: isSelf,
                            no: qaResult[i].No,
                            createdDate: qaResult[i].createdDate,
                            refQID: qaResult[i].refQID,
                            clientAccount: clientAccounts[x].client,
                            userEID: userEID,
                            answeredEID: answeredEID,
                            isRequired: isRequired,
                            responseDate: typeof (responseDate) != "undefined" ? this.formatDate(responseDate) : "null",
                            isUpdate: isUpdate? true : false
                          }
                          qaItems.push(tempData);

                          if (x == clientAccounts.length - 1) {
                            qaItems = qaItems.sort(this.sortNewDatas);
                            let isCollapsed = true;
                            qaItems.forEach(qaData => {
                              if (qaData.isAnswered == false) isCollapsed = false;
                            });
                            newComDatas.push({ No: i, isCollapsed: isCollapsed, isShow: keyAnswer, refQID:qaResult[i].refQID, qaItems });
                            this.withoutRefQID.push({ No: i, isCollapsed: isCollapsed, isShow: keyAnswer, refQID:qaResult[i].refQID, ID:qaResult[i].Id, qaItems });
                            newComDatas = newComDatas.sort((a, b) => a.No - b.No)
                            this.withoutRefQID = this.withoutRefQID.sort((a, b) => a.No - b.No);
                            qaItems = [];
                          }
                        }

                      })

                    } else {
                      let isReferenced = false;
                      let isSelf = false;
                      let userEID = this.form.data.user.eid;

                      //with refQID
                      for (let x = 0; x < refResult.length; x++) {
                        let refClientAccount = null;
                        let resAnswer;
                        let responseDate;
                        let isUpdate;

                        let refResultID = refResult[x].Id.split("|")
                        let refQID = refResultID[0] + "|" + refResultID[1];
                        let refEID = refResultID[3];

                        //Check if reference question has answer
                        if (result.length > 0) {
                          for (let y = 0; y < result.length; y++) {
                            let resultId = result[y].Id.split("|");

                            if (resultId.length == 4) {
                              //Check what Client Account has response
                              if (resultId[3] == refEID) {
                                if (result[y].Answer1 != " ") { resAnswer = result[y].Answer1; responseDate = result[y].ResponseDate }
                                if (result[y].Answer2 != " ") { resAnswer = result[y].Answer2; responseDate = result[y].ResponseDate }
                                if (result[y].Answer3 != " ") { resAnswer = result[y].Answer3; responseDate = result[y].ResponseDate }
                                if (result[y].Answer4 != " ") { resAnswer = result[y].Answer4; responseDate = result[y].ResponseDate }
                                if (result[y].Answer5 != " ") { resAnswer = result[y].Answer5; responseDate = result[y].ResponseDate }
                                if (result[y].Answer6 != " ") { resAnswer = result[y].Answer6; responseDate = result[y].ResponseDate }
                                if (result[y].Answer7 != " ") { resAnswer = result[y].Answer7; responseDate = result[y].ResponseDate }
                                if (result[y].Answer8 != " ") { resAnswer = result[y].Answer8; responseDate = result[y].ResponseDate }
                                if (result[y].Answer9 != " ") { resAnswer = result[y].Answer9; responseDate = result[y].ResponseDate }
                                if (result[y].Answer10 != " ") { resAnswer = result[y].Answer10; responseDate = result[y].ResponseDate }

                                if (result[y].Responder == this.form.data.user.eid) {
                                  isSelf = true;
                                } else {
                                  userEID = result[y].Responder;
                                }

                                if(result[y].isUpdate == "true"){
                                  isUpdate = true
                                }else{
                                  isUpdate = false;
                                }

                              }
                            }
                          }
                        }

                        //Set reference client account
                        if (refResultID.length == 4) {
                          refClientAccount = refResultID[3];
                        }

                        //Check if the question is being referenced
                        if (qaResult[i].refQID == refQID && refResult[x]['Answer' + qaResult[i].keyAnswer] != " ") {
                          for(let y=0; y<clientAccounts.length; y++){
                            if(clientAccounts[y].client == refClientAccount){
                              isReferenced = true;
                            }
                          }
                        }

                        if (isReferenced == true) {
                          let tempData = {
                            qid: qaResult[i].Id,
                            isAnswered: typeof (resAnswer) != "undefined" ? true : false,
                            question: qDetails.q,
                            choices: qDetails.c,
                            answer: typeof (resAnswer) != "undefined" ? resAnswer : "null",
                            isShow: keyAnswer,
                            isSelf: isSelf,
                            no: qaResult[i].No,
                            createdDate: qaResult[i].createdDate,
                            refQID: qaResult[i].refQID,
                            clientAccount: refClientAccount,
                            userEID: userEID,
                            isRequired: true,
                            responseDate: typeof (responseDate) != "undefined" ? this.formatDate(responseDate) : "null",
                            isUpdate: isUpdate? true: false
                          }

                          qaItems.push(tempData);
                          isReferenced = false;
                        }

                        if (x == refResult.length - 1 && qaItems.length > 0) {
                          qaItems = qaItems.sort(this.sortNewDatas);
                          let isCollapsed = true;
                          qaItems.forEach(qaData => {
                            if (qaData.isAnswered == false) isCollapsed = false;
                          });
                          newComDatas.push({ No: i, isCollapsed: isCollapsed, isShow: keyAnswer, refQID:qaResult[i].refQID, qaItems });
                          this.withRefQID.push({ No: i, isCollapsed: isCollapsed, isShow: keyAnswer, refQID:qaResult[i].refQID, ID:qaResult[i].Id, qaItems });
                          newComDatas = newComDatas.sort((a, b) => a.No - b.No)
                          this.withRefQID = this.withRefQID.sort((a, b) => a.No - b.No);
                          qaItems = [];
                        }
                      }
                    }
                  }
                  if (i == qaResult.length - 1) {
                    newComDatas = newComDatas.sort((a, b) => a.No - b.No)
                    newComDatas = newComDatas.filter(item => item.isShow == true);
                    this.loading = true;
                    console.log(newComDatas);
                    return resolve(newComDatas);
                  }

                })
              })

            }
          })
          loading.close();
        })
      }else{
        this.form.getAlertCDPDetail(this.data.alertID, eid).subscribe((cdpDetails:any) => {
          let clientAccountList = [];
          if (cdpDetails.CDPDetails) {
            cdpDetails.CDPDetails.forEach(element => {
              clientAccountList.push({client: element.ClientName });
            });
            clientAccounts = clientAccountList;
            this.ClientAccounts = clientAccountList;
          }

          this.form.getQADetails(this.data.alertID).subscribe((qaResult:any) => {
            this.QAData = qaResult;
            console.log("Selected Row QA",qaResult,this.form.data.QA);
            for (let i = 0; i < qaResult.length; i++){
              this.checkIsAnswered(qaResult[i]).subscribe((result:any) => {
                console.log("answer result", result);
                let qDetails = this.formatData(sjon.parse(sjon.parse(qaResult[i].Details)), result);
                this.checkRefAnswered(qaResult[i]).subscribe(async (refResult: any) => {
                  let keyAnswer;
                  if(refResult.length > 0) {
                    for (let x = 0; x < refResult.length; x++) {
                      let refKeyAnswer = qaResult[i].keyAnswer
                      keyAnswer = (refResult[x]['Answer' + refKeyAnswer] != ' ') ? true : false
                      if (keyAnswer && this.data.alertStatus === 'Published') {
                        this.markQuestion(qaResult[i].Id).subscribe(res => { console.log("marking question as read", res) })
                        x = refResult.length;
                      }
                    }
                  } else {
                    console.log("refAnswer is less than 0")
                      if ( (qaResult[i].No == 1 || qaResult[i].keyAnswer == undefined ) && this.data.alertStatus === 'Published') {
                        console.log("it should be updating the question status")
                        keyAnswer = true;
                        this.markQuestion(qaResult[i].Id).subscribe(res => { console.log("marking question as read", res) })
                      }
                  }

                  //Handle with and without Client Account/s
                  if (clientAccounts.length == 0) {
                    //No Client Account
                    let resAnswer;
                    let responseDate;
                    let isUpdate;

                    if (refResult.length == 0) {
                      for (let y = 0; y < result.length; y++) {
                        let resultId = result[y].Id.split("|");

                        if (resultId.length == 3) {
                          //Check if question has a response
                          if (result[y].Answer1 != " ") { resAnswer = result[y].Answer1; responseDate = result[y].ResponseDate }
                          if (result[y].Answer2 != " ") { resAnswer = result[y].Answer2; responseDate = result[y].ResponseDate }
                          if (result[y].Answer3 != " ") { resAnswer = result[y].Answer3; responseDate = result[y].ResponseDate }
                          if (result[y].Answer4 != " ") { resAnswer = result[y].Answer4; responseDate = result[y].ResponseDate }
                          if (result[y].Answer5 != " ") { resAnswer = result[y].Answer5; responseDate = result[y].ResponseDate }
                          if (result[y].Answer6 != " ") { resAnswer = result[y].Answer6; responseDate = result[y].ResponseDate }
                          if (result[y].Answer7 != " ") { resAnswer = result[y].Answer7; responseDate = result[y].ResponseDate }
                          if (result[y].Answer8 != " ") { resAnswer = result[y].Answer8; responseDate = result[y].ResponseDate }
                          if (result[y].Answer9 != " ") { resAnswer = result[y].Answer9; responseDate = result[y].ResponseDate }
                          if (result[y].Answer10 != " ") { resAnswer = result[y].Answer10; responseDate = result[y].ResponseDate }
                        }

                        if(result[y].isUpdate == "true"){
                          isUpdate = true
                        }else{
                          isUpdate = false;
                        }

                      }

                      let tempData = {
                        qid: qaResult[i].Id,
                        isAnswered: typeof (resAnswer) != "undefined" ? true : false,
                        question: qDetails.q,
                        choices: qDetails.c,
                        answer: typeof (resAnswer) != "undefined" ? resAnswer : "null",
                        isShow: keyAnswer,
                        no: qaResult[i].No,
                        createdDate: qaResult[i].createdDate,
                        refQID: qaResult[i].refQID,
                        responseDate: typeof (responseDate) != "undefined" ? this.formatDate(responseDate) : "null",
                        isUpdate: isUpdate? true:false
                      }
                      qaItems.push(tempData);
                      newComDatas.push({ No: i, isCollapsed: typeof (resAnswer) != "undefined" ? true : false, isShow: keyAnswer, qaItems });
                      newComDatas = newComDatas.sort((a, b) => a.No - b.No)
                      qaItems = [];
                    } else {
                      let isReferenced = false;

                      for (let x = 0; x < refResult.length; x++) {
                        let refClientAccount = null;
                        let resAnswer;
                        let responseDate;
                        let isUpdate;

                        let refResultID = refResult[x].Id.split("|")
                        let refQID = refResultID[0] + "|" + refResultID[1];

                        //Check if reference question has answer
                        if (result.length > 0) {
                          for (let y = 0; y < result.length; y++) {
                            let resultId = result[y].Id.split("|");

                            if (resultId.length == 3) {
                              //Check what Client Account has response
                              if (result[y].Answer1 != " ") { resAnswer = result[y].Answer1; responseDate = result[y].ResponseDate }
                              if (result[y].Answer2 != " ") { resAnswer = result[y].Answer2; responseDate = result[y].ResponseDate }
                              if (result[y].Answer3 != " ") { resAnswer = result[y].Answer3; responseDate = result[y].ResponseDate }
                              if (result[y].Answer4 != " ") { resAnswer = result[y].Answer4; responseDate = result[y].ResponseDate }
                              if (result[y].Answer5 != " ") { resAnswer = result[y].Answer5; responseDate = result[y].ResponseDate }
                              if (result[y].Answer6 != " ") { resAnswer = result[y].Answer6; responseDate = result[y].ResponseDate }
                              if (result[y].Answer7 != " ") { resAnswer = result[y].Answer7; responseDate = result[y].ResponseDate }
                              if (result[y].Answer8 != " ") { resAnswer = result[y].Answer8; responseDate = result[y].ResponseDate }
                              if (result[y].Answer9 != " ") { resAnswer = result[y].Answer9; responseDate = result[y].ResponseDate }
                              if (result[y].Answer10 != " ") { resAnswer = result[y].Answer10; responseDate = result[y].ResponseDate }
                            }

                            if(result[y].isUpdate == "true"){
                              isUpdate = true
                            }else{
                              isUpdate = false;
                            }
                          }
                        }

                        //Set reference client account
                        if (refResultID.length == 4) {
                          refClientAccount = refResultID[3];
                        }

                        //Check if the question is being referenced
                        if (qaResult[i].refQID == refQID && refResult[x]['Answer' + qaResult[i].keyAnswer] != " ") {
                          isReferenced = true;
                        }

                        if (isReferenced == true) {
                          let tempData = {
                            qid: qaResult[i].Id,
                            isAnswered: typeof (resAnswer) != "undefined" ? true : false,
                            question: qDetails.q,
                            choices: qDetails.c,
                            answer: typeof (resAnswer) != "undefined" ? resAnswer : "null",
                            isShow: keyAnswer,
                            no: qaResult[i].No,
                            createdDate: qaResult[i].createdDate,
                            refQID: qaResult[i].refQID,
                            responseDate: typeof (responseDate) != "undefined" ? this.formatDate(responseDate) : "null",
                            isUpdate: isUpdate? true:false
                          }


                          qaItems.push(tempData);
                          isReferenced = false;
                        }

                        if (x == refResult.length - 1 && qaItems.length > 0) {
                          let isCollapsed = true;
                          qaItems.forEach(qaData => {
                            if (qaData.isAnswered == false) isCollapsed = false;
                          });
                          newComDatas.push({ No: i, isCollapsed: isCollapsed, isShow: keyAnswer, qaItems });
                          newComDatas = newComDatas.sort((a, b) => a.No - b.No)
                          qaItems = [];
                        }

                      }
                    }

                  }
                  else {
                    //with Client Account
                    if (!qaResult[i].refQID) {
                      //without refQID

                      this.form.getAllAnswered(this.data.alertID).subscribe((answeredData:any)=>{
                        let allAnswers = answeredData.body;
                        let answeredClients = [];
                        let answeredList = [];
                        console.log(allAnswers);
                        for(let a=0; a<allAnswers.length; a++){
                          // if(allAnswers[a].Responder != this.form.data.user.eid && !answeredClients.includes(allAnswers[a].Id.split("|")[3])){
                          if(!answeredClients.includes(allAnswers[a].Id.split("|")[3])){
                            answeredClients.push(allAnswers[a].Responder);
                            answeredList.push({
                              eid: allAnswers[a].Responder,
                              client: allAnswers[a].Id.split("|")[3]
                            })
                          }
                        }

                        for (let x = 0; x < clientAccounts.length; x++) {
                          let resAnswer;
                          let isSelf = false;
                          let userEID = this.form.data.user.eid;
                          let responseDate;
                          let isUpdate;

                          if (result.length > 0) {
                            for (let y = 0; y < result.length; y++) {
                              let resultId = result[y].Id.split("|");

                              if (resultId.length == 4) {
                                //Check what Client Account has response
                                if (resultId[3] == clientAccounts[x].client) {
                                  if (result[y].Answer1 != " ") { resAnswer = result[y].Answer1; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer2 != " ") { resAnswer = result[y].Answer2; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer3 != " ") { resAnswer = result[y].Answer3; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer4 != " ") { resAnswer = result[y].Answer4; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer5 != " ") { resAnswer = result[y].Answer5; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer6 != " ") { resAnswer = result[y].Answer6; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer7 != " ") { resAnswer = result[y].Answer7; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer8 != " ") { resAnswer = result[y].Answer8; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer9 != " ") { resAnswer = result[y].Answer9; responseDate = result[y].ResponseDate }
                                  if (result[y].Answer10 != " ") { resAnswer = result[y].Answer10; responseDate = result[y].ResponseDate }

                                  if (result[y].Responder == this.form.data.user.eid) {
                                    isSelf = true;
                                  } else {
                                    userEID = result[y].Responder;
                                  }

                                  if(result[y].isUpdate == "true"){
                                    isUpdate = true
                                  }else{
                                    isUpdate = false;
                                  }

                                }
                              }
                            }
                          }

                          let answeredEID;
                          let isRequired = true;
                          for(let a=0; a<answeredList.length; a++){
                            if(clientAccounts[x].client == answeredList[a].client){
                              answeredEID = answeredList[a].eid;
                              isRequired = false;
                              if(answeredEID == this.form.data.user.eid){
                                isRequired = true;
                              }
                            }
                          }

                          let tempData = {
                            qid: qaResult[i].Id,
                            isAnswered: typeof (resAnswer) != "undefined" ? true : false,
                            question: qDetails.q,
                            choices: qDetails.c,
                            answer: typeof (resAnswer) != "undefined" ? resAnswer : "null",
                            isShow: keyAnswer,
                            isSelf: isSelf,
                            no: qaResult[i].No,
                            createdDate: qaResult[i].createdDate,
                            refQID: qaResult[i].refQID,
                            clientAccount: clientAccounts[x].client,
                            userEID: userEID,
                            answeredEID: answeredEID,
                            isRequired: isRequired,
                            responseDate: typeof (responseDate) != "undefined" ? this.formatDate(responseDate) : "null",
                            isUpdate: isUpdate? true: false
                          }

                          qaItems.push(tempData);

                          if (x == clientAccounts.length - 1) {
                            qaItems = qaItems.sort(this.sortNewDatas);
                            let isCollapsed = true;
                            qaItems.forEach(qaData => {
                              if (qaData.isAnswered == false) isCollapsed = false;
                            });
                            newComDatas.push({ No: i, isCollapsed: isCollapsed, isShow: keyAnswer, refQID:qaResult[i].refQID, qaItems });
                            this.withoutRefQID.push({ No: i, isCollapsed: isCollapsed, isShow: keyAnswer, refQID:qaResult[i].refQID, ID:qaResult[i].Id, qaItems });
                            newComDatas = newComDatas.sort((a, b) => a.No - b.No)
                            this.withoutRefQID = this.withoutRefQID.sort((a, b) => a.No - b.No);
                            qaItems = [];
                          }
                        }

                      })

                    } else {
                      let isReferenced = false;
                      let isSelf = false;
                      let userEID = this.form.data.user.eid;

                      //with refQID
                      for (let x = 0; x < refResult.length; x++) {
                        let refClientAccount = null;
                        let resAnswer;
                        let responseDate;
                        let isUpdate;

                        let refResultID = refResult[x].Id.split("|")
                        let refQID = refResultID[0] + "|" + refResultID[1];
                        let refEID = refResultID[3];

                        //Check if reference question has answer
                        if (result.length > 0) {
                          for (let y = 0; y < result.length; y++) {
                            let resultId = result[y].Id.split("|");

                            if (resultId.length == 4) {
                              //Check what Client Account has response
                              if (resultId[3] == refEID) {
                                if (result[y].Answer1 != " ") { resAnswer = result[y].Answer1; responseDate = result[y].ResponseDate }
                                if (result[y].Answer2 != " ") { resAnswer = result[y].Answer2; responseDate = result[y].ResponseDate }
                                if (result[y].Answer3 != " ") { resAnswer = result[y].Answer3; responseDate = result[y].ResponseDate }
                                if (result[y].Answer4 != " ") { resAnswer = result[y].Answer4; responseDate = result[y].ResponseDate }
                                if (result[y].Answer5 != " ") { resAnswer = result[y].Answer5; responseDate = result[y].ResponseDate }
                                if (result[y].Answer6 != " ") { resAnswer = result[y].Answer6; responseDate = result[y].ResponseDate }
                                if (result[y].Answer7 != " ") { resAnswer = result[y].Answer7; responseDate = result[y].ResponseDate }
                                if (result[y].Answer8 != " ") { resAnswer = result[y].Answer8; responseDate = result[y].ResponseDate }
                                if (result[y].Answer9 != " ") { resAnswer = result[y].Answer9; responseDate = result[y].ResponseDate }
                                if (result[y].Answer10 != " ") { resAnswer = result[y].Answer10; responseDate = result[y].ResponseDate }

                                if (result[y].Responder == this.form.data.user.eid) {
                                  isSelf = true;
                                } else {
                                  userEID = result[y].Responder;
                                }

                                if(result[y].isUpdate == "true"){
                                  isUpdate = true
                                }else{
                                  isUpdate = false;
                                }

                              }
                            }
                          }
                        }

                        //Set reference client account
                        if (refResultID.length == 4) {
                          refClientAccount = refResultID[3];
                        }

                        //Check if the question is being referenced
                        if (qaResult[i].refQID == refQID && refResult[x]['Answer' + qaResult[i].keyAnswer] != " ") {
                          for(let y=0; y<clientAccounts.length; y++){
                            if(clientAccounts[y].client == refClientAccount){
                              isReferenced = true;
                            }
                          }
                        }

                        if (isReferenced == true) {
                          let tempData = {
                            qid: qaResult[i].Id,
                            isAnswered: typeof (resAnswer) != "undefined" ? true : false,
                            question: qDetails.q,
                            choices: qDetails.c,
                            answer: typeof (resAnswer) != "undefined" ? resAnswer : "null",
                            isShow: keyAnswer,
                            isSelf: isSelf,
                            no: qaResult[i].No,
                            createdDate: qaResult[i].createdDate,
                            refQID: qaResult[i].refQID,
                            clientAccount: refClientAccount,
                            userEID: userEID,
                            isRequired: true,
                            responseDate: typeof (responseDate) != "undefined" ? this.formatDate(responseDate) : "null",
                            isUpdate: isUpdate? true: false
                          }

                          qaItems.push(tempData);
                          isReferenced = false;
                        }

                        if (x == refResult.length - 1 && qaItems.length > 0) {
                          qaItems = qaItems.sort(this.sortNewDatas);
                          let isCollapsed = true;
                          qaItems.forEach(qaData => {
                            if (qaData.isAnswered == false) isCollapsed = false;
                          });
                          newComDatas.push({ No: i, isCollapsed: isCollapsed, isShow: keyAnswer, refQID:qaResult[i].refQID, qaItems });
                          this.withRefQID.push({ No: i, isCollapsed: isCollapsed, isShow: keyAnswer, refQID:qaResult[i].refQID, ID:qaResult[i].Id, qaItems });
                          newComDatas = newComDatas.sort((a, b) => a.No - b.No)
                          this.withRefQID = this.withRefQID.sort((a, b) => a.No - b.No);
                          qaItems = [];
                        }
                      }
                    }
                  }
                  if (i == qaResult.length - 1) {
                    newComDatas = newComDatas.sort((a, b) => a.No - b.No)
                    newComDatas = newComDatas.filter(item => item.isShow == true);
                    this.loading = true;
                    console.log(newComDatas);
                    return resolve(newComDatas);
                  }

                })
              })

            }
          })
          loading.close();
        });

      }

    })

  }

  checkIsAnswered(eachQA){
    return new Observable(observable => {
      let aid = eachQA.Id;
      this.form.getAnswer(aid).subscribe((answer:any) => {
        answer = Object.assign(answer.body, { qid: eachQA.Id })
        observable.next(answer)
      })
    })
  }

  formatData(eachQA, answerResult){
    let choices = [];
    let answer;
    this.form.data.Question = eachQA.Question;

    for(let prop in eachQA){
      if(prop.includes('Answer')){
        choices.push(eachQA[prop])
      }
    }

    if(answerResult.length > 0) {
      for(let ans in answerResult[0]){
        if(ans.includes('Answer')){
          if(answerResult[0][ans] != ' '){
            answer = answerResult[0][ans]
          }
        }
      }
    }
    else{
      answer = ""
    }

    this.choices = choices;
    // this.selected.question = this.form.data.Question;
    return {c: choices, q: eachQA.Question, a: answer}
  }


  checkRefAnswered(eachQA){
    return new Observable((observable) => {
      if (eachQA.hasOwnProperty('refQID')) {
        let eid = this.form.data.user.eid
        let aid = eachQA.refQID + "|" + eid
        this.form.getAnswer(aid).subscribe((answer: any) => {
          console.log("check reference question answer result", answer)
          observable.next(answer.body)
        })
      } else {
        observable.next([]);
      }
    })
  }

  markQuestion(qid) {
    return new Observable((observable) => {
      this.form.markQA(qid, 'true').subscribe(res => {
        console.log("marking question", res)
        observable.next(res)
      }, error => observable.error(error))
    })

  }

  sortNewDatas(a, b) {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;

    var aA = a.clientAccount.replace(reA, "");
    var bA = b.clientAccount.replace(reA, "");
    if (aA === bA) {
      var aN = parseInt(a.clientAccount.replace(reN, ""), 10);
      var bN = parseInt(b.clientAccount.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }

  }

  submit(refQID = ""){

    const loading = this.dialog.open(LoadingComponent, {panelClass: 'custom-dialog'});


    let answer = this.selectedAnswer.answer;
    let i = parseInt(this.selectedAnswer.index) + 1;
    let clientAccount = this.selectedAnswer.clientAccount;
    let prop = "Answer" + i
    let id = this.selectedAnswer.QID;
    let selected;

    if(refQID == ""){
      selected = this.withoutRefQID[this.selectedAnswer.newComsIndex].qaItems[this.selectedAnswer.qaItemsIndex];
    } else {
      selected = this.withRefQID[this.selectedAnswer.newComsIndex].qaItems[this.selectedAnswer.qaItemsIndex];
    }


    //check if selected qaItem is for update
    // let selected = this.newComDatas[this.selectedAnswer.newComsIndex].qaItems[this.selectedAnswer.qaItemsIndex]
    if(selected.responseDate == 'null'){
      this.toUpdate = false;
      this.toDeleteResponse = '';
    }


    if (this.data.alertStatus === 'Published') {
      let selectedClientAccount = "";

      if (clientAccount) {
        selectedClientAccount = "|" + clientAccount;
      }

      this.loading = true;

      let data = {
        Id: id + "|" + this.form.data.user.eid + selectedClientAccount,
        Responder: this.form.data.user.eid,
        Answer1: prop == "Answer1" ? answer : " ",
        Answer2: prop == "Answer2" ? answer : " ",
        Answer3: prop == "Answer3" ? answer : " ",
        Answer4: prop == "Answer4" ? answer : " ",
        Answer5: prop == "Answer5" ? answer : " ",
        Answer6: prop == "Answer6" ? answer : " ",
        Answer7: prop == "Answer7" ? answer : " ",
        Answer8: prop == "Answer8" ? answer : " ",
        Answer9: prop == "Answer9" ? answer : " ",
        Answer10: prop == "Answer10" ? answer : " ",
        RespondedVia: "Web",
        ResponseDate: new Date().toLocaleDateString("en-US",{
          month:"2-digit",
          day:"2-digit",
          year:"numeric",
          hour12:true,
          hour:"2-digit",
          minute:"2-digit",
          timeZone:"UTC"
        }),
        ResponseDateCST: new Date().toLocaleDateString("en-US",{
          month:"2-digit",
          day:"2-digit",
          year:"numeric",
          hour12:true,
          hour:"2-digit",
          minute:"2-digit",
          timeZone:"America/Chicago"
        }),
        isUpdate:this.toUpdate ? 'true' : 'false',
        toDeleteResponse: this.toUpdate ? this.toDeleteResponse : " "
      }
      console.log("data for submission", data)

      let qid = id + "|" + this.form.data.user.eid + selectedClientAccount;

      this.form.getAllAnswered(qid).subscribe(res => {
        console.log(res);
        let hasAnswered = false;
        if(res){
          if(!this.toUpdate){
            let returnRes: any = res;
            for(let x=0; x<returnRes.body.length; x++){
              let answeredClient = returnRes.body[x].Id.split("|");
              let allAnswers = returnRes.body;
              // if(answeredClient[3] == clientAccount){
              //   if(allAnswers[x].Responder != this.form.data.user.eid){
              //     let QID = id.split("|");
              //     if((QID[0] == answeredClient[0]) && (QID[1] == answeredClient[1])){
                //     hasAnswered = true
                //     break;
                //   }
                // }else{
                //   let QID = id.split("|");
                //   if((QID[0] == answeredClient[0]) && (QID[1] == answeredClient[1])){
                //     hasAnswered = true
                //     break;
                //   }
              //   }
              // }

              let QID = id.split("|");
              if((QID[0] == answeredClient[0]) && (QID[1] == answeredClient[1])){
                if(answeredClient[3] == clientAccount){
                  hasAnswered = true;
                  break;
                }
              }

            }
          }

          if(!hasAnswered){
            console.log("to submitted", data)
            this.form.submitAnswer(data).subscribe(result => {
              if (result) {
                console.log("answer submitted")
                this.loadData().then(async newComDatas => {
                  this.newComDatas = newComDatas;
                  this.loading = false;

                  //update ET STATUS
                  this.form.getAllAnswered(this.data.alertID).subscribe(res => {
                    console.log(res);
                    if(res){
                      let returnRes: any = res;
                      let count = [];
                      let arr = [];
                      console.log(this.QAData)
                      let refQA = this.QAData.filter(item => (item.hasOwnProperty('refQID') && item.hasOwnProperty('keyAnswer')));
                      let GenQA = this.QAData.filter(item => !(item.hasOwnProperty('refQID') && item.hasOwnProperty('keyAnswer')));
                      // GenQA.forEach(item => {
                      for(let i=0;i<GenQA.length;i++){
                        let param = {
                          refQID:'',
                          keyAnswer:''
                        }
                        arr.push(param)
                      }
                      // refQA.forEach((item) => {
                      for(let i=0;i<refQA.length;i++){
                        let param = {
                          refQID: refQA[i].refQID,
                          keyAnswer: refQA[i].keyAnswer
                        }
                        arr.push(param)
                      }
                      arr.forEach((item,index) => {
                      // for(let index=0; i<arr.length; index++){
                        this.form.getAnsEids(this.data.alertID, arr[index].refQID, arr[index].keyAnswer).subscribe((eids: any) => {
                          let firstQcount = 0;
                          let total = 0;

                          if(arr[index].refQID == '' && arr[index].keyAnswer == ''){
                            count.push(this.ClientAccounts.length)
                          }else{
                            if(eids.length > 0){
                              count.push(eids.length);
                            }
                          }
                          count.forEach(item => {
                            total = total + item;
                          })

                          returnRes.body.forEach(item => {
                            let id = item.Id.split('|');
                            if(id[0] == id[1]){
                              firstQcount++;
                            }
                          })
                          if(index+1 == arr.length){
                            console.log(total,count);

                            // if(returnRes.body.length == total){
                            //   this.form.updateETstatus('Responded',this.data.alertID).subscribe(ret => {
                            //     console.log('Responded')
                            //   });
                            // }else{
                            //   if(firstQcount == this.ClientAccounts.length){
                            //     this.form.updateETstatus('Awaiting Response to Follow-up Question', this.data.alertID).subscribe(ret => {
                            //       console.log('Awaiting Response to Follow-up Question')
                            //     });
                            //   }else{
                            //     this.form.updateETstatus('Awaiting Response', this.data.alertID).subscribe(ret => {
                            //       console.log('Awaiting Response')
                            //     });
                            //   }
                            // }

                          }
                        })
                      })

                    }
                  })
                  loading.close();
                  this.toUpdate = false;
                  this.toDeleteResponse='';
                  this.showToast('success',this.selectedAnswer.clientAccount);

                })
              }
            })
          }
          else{
            console.log("answer not submitted")
            this.showToast('error',this.selectedAnswer.clientAccount);
            this.loadData().then(newComDatas => {
              this.newComDatas = newComDatas;
              this.loading = false;
              this.toUpdate = false;
              this.toDeleteResponse='';
              loading.close();
            })
          }
          console.log(this.newComDatas)
        }
      })
    }

    this.tempAnswer = [];
    this.withRefQID = [];
    this.withoutRefQID = [];
    console.log('newcomdatas',this.newComDatas)
  }

  onClick(event,index,clientAccount,Qid,newComsIndex,qaItemsIndex, refQID = ""){
    let selectedAnswer = {
      answer: event,
      index: index,
      clientAccount:clientAccount,
      QID:Qid,
      newComsIndex:newComsIndex,
      qaItemsIndex:qaItemsIndex
    }
    this.selectedAnswer = selectedAnswer;
    this.tempAnswer.push(event,index,clientAccount,Qid);

    console.log('I am clicked!',this.tempAnswer,this.selectedAnswer);
    console.log(this.newComDatas[selectedAnswer.newComsIndex].qaItems[selectedAnswer.qaItemsIndex])


    let selected;

    if(refQID == ""){
      selected = this.withoutRefQID[selectedAnswer.newComsIndex].qaItems[selectedAnswer.qaItemsIndex]
    } else {
      selected = this.withRefQID[selectedAnswer.newComsIndex].qaItems[selectedAnswer.qaItemsIndex]
    }

    
    this.toDeleteResponse = Qid + '|' + selected.userEID + '|' + selected.clientAccount;

    this.newComDatas.forEach((question,questionIndex) => {
      question.qaItems.forEach((qaItem,index) => {
        if(selected != qaItem){
          if(qaItem.isAnswered == false){
            this.newComDatas[questionIndex].qaItems[index].answer='null';
          }
        }
      })
    })
  }

  showToast(type, clientAccount){
    return new Promise((resolve) => {
      if(type === 'error'){
        let message:string = 'The question for client '  + clientAccount +  ' has already been answered by other user.';
        this.toastr.error(message,'Error')
      }else{
        let message:string = 'The question for client ' + clientAccount + ' is now answered.'
        this.toastr.success(message,'Success')
      }
      resolve('okay');
    })
  }
  toggleDisplay(isCollapsed, index){
    this.newComDatas[index].isCollapsed = !isCollapsed;
  }

  setChevronImage(isCollapsed) {
    let chevronUp = "../../../assets/icons/chevron-circle-gray-up.png";
    let chevronDown = "../../../assets/icons/chevron-circle-gray-down.png";
    return isCollapsed == true ? chevronDown : chevronUp;
  }

  showAnswers(isCollapsed, index) {
    //expand and collapse
    this.newComDatas[index].isCollapsed = !isCollapsed;
  }

  refresh(){

    const loading = this.dialog.open(LoadingComponent, {panelClass: 'custom-dialog'});
    this.withRefQID = [];
    this.withoutRefQID = [];
    this.loadData().then(newComDatas => {
      this.newComDatas = newComDatas;
      this.loading = false;
      this.toUpdate = false;
      this.toDeleteResponse = '';
      loading.close();
    })
  }

  formatDate(responseDate){
    let resdate = Date.parse(responseDate);
    let d = new Date(resdate);

    let newdate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes()));
    let formattedDate =
                ("00" + (newdate.getMonth() + 1)).slice(-2)
                + "/" + ("00" + newdate.getDate()).slice(-2)
                + "/" + newdate.getFullYear() + " - "
                + ("00" + newdate.getHours() % 12 ).slice(-2) + ":"
                + ("00" + newdate.getMinutes()).slice(-2)
                + (newdate.getHours() >= 12 ? " PM" : " AM");

      return formattedDate;
  }

  update(qaItem){
    this.toDeleteResponse = qaItem.qid + '|' + qaItem.userEID + '|' + qaItem.clientAccount;
    console.log(this.toDeleteResponse);
    this.toUpdate = true;
    qaItem.isAnswered = false;
    qaItem.answer='null';

    this.newComDatas.forEach((question,questionIndex) => {
      question.qaItems.forEach((qaItems,index) => {
        if(qaItem != qaItems){
          if(qaItems.isAnswered == false){
            this.newComDatas[questionIndex].qaItems[index].answer='null';
          }
        }
      })
    })
  }

  checkExpanded(qid, qaItems){
    let isExpanded = false;
    qaItems.forEach(item => {
  
      if(item.isAnswered == false){
        isExpanded = true;
      }
      else{
        
        let followupquestions = this.newComDatas.filter(item1 => item1.refQID == qid);
        if (followupquestions.length > 0 && followupquestions[0].qaItems.length > 0){
          followupquestions.forEach(firstQuestion => {
            firstQuestion.qaItems.forEach(firstQA => {
  
              if (firstQA.isAnswered == false){
                  isExpanded = true;
                } 
              else{
                  let nestedfollowupquestions = this.newComDatas.filter(item2 => item2.refQID != undefined && item2.refQID == firstQA.qid);
                  if (nestedfollowupquestions.length > 0 && nestedfollowupquestions[0].qaItems.length > 0){
                    nestedfollowupquestions.forEach(secondQuestion => {
                      secondQuestion.qaItems.forEach(secondQA=> {
                        if (secondQA.isAnswered == false){
                          isExpanded = true;
                        }
                      })
                    })
                  }
                }
            })
          })
        }
      }
    })
    return isExpanded;
  }
}