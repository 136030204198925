import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormService } from 'src/app/core/form.service';
import { LoadingComponent } from '../sharedComponent/loading/loading.component';


@Component({
  selector: 'app-et-com',
  templateUrl: './et-com.component.html',
  styleUrls: ['./et-com.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class EtComComponent implements OnInit {
  public dialogRef;

  etCount;
  PendingColumns: string[] = ['title','etStatus', 'publishedDate','respond/view'];
  PendingTransactionSource = [];
  selectedIndex = 0;

  constructor(public dialog: MatDialog, public form: FormService, public router: Router) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    const loading = this.dialog.open(LoadingComponent, {panelClass: 'custom-dialog'});
    this.form.getNotifications(this.form.data.user.eid).subscribe(data => {
      let alertIDs = []
      this.form.data.etNotifs.forEach(element => {
        alertIDs.push(element.alertID)
      });
      if(alertIDs.length > 0){
        this.form.checkEtStatus(alertIDs, this.form.data.user.eid).subscribe((data : any) => {
  
          this.form.data.etNotifs.forEach(element => {
            let status = data.filter(etStatus => element.alertID == etStatus.alertid)
  
            console.log("status", status)
  
            element.etStatus = status[0].status
  
          });
  
          
          this.PendingTransactionSource = this.form.data.etNotifs;
          this.etCount = this.PendingTransactionSource.length + " items";
          loading.close();
        })
      } else {
        this.PendingTransactionSource = this.form.data.etNotifs;
        this.etCount = this.PendingTransactionSource.length + " items";
        loading.close();
      }
    })
  }

  selectedRow(data){
    console.log("DATA:", data);
    this.form.data.etSelectedRow = data;
    this.router.navigateByUrl('/et-questions');
  }

}
