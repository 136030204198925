import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormService } from 'src/app/core/form.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoadingComponent } from '../sharedComponent/loading/loading.component';
import { FollowupQuestionComponent } from '../view-transaction/followup-question/followup-question.component';
import { ReminderQuestionComponent } from '../view-transaction/reminder-question/reminder-question.component';
import { EmergingquestionsComponent } from './emergingquestions/emergingquestions.component';
import { observable, Observable } from 'rxjs';
import { DeliverydetailComponent } from './deliverydetail/deliverydetail.component';

@Component({
  selector: 'app-view-transaction',
  templateUrl: './view-transaction.component.html',
  styleUrls: ['./view-transaction.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewTransactionComponent implements OnInit {
  status: boolean;
  dialogRef: any;
  isUnauthorized: boolean = false;

  constructor(
    public router: Router,
    public form: FormService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) { }

  @ViewChild(EmergingquestionsComponent) etQComponent: EmergingquestionsComponent;
  @ViewChild(DeliverydetailComponent) dDeailComponent: DeliverydetailComponent;

  red = '../../../../assets/icons/red-grey-outline.png'
  orange = '../../../../assets/icons/orange-grey-outline.png'
  green = '../../../../assets/icons/green-grey-outline.png'
  pencil = '../../../../assets/icons/pencil_edit_blue.svg'

  totalRecipients: string;

  ngOnInit(): void {
    console.log("this this transaction", this.form.data.transaction)
    this.isUnauthorized = true;
    console.log("TEST isSuperAdmin:", this.form.data.user.isSuperAdmin);
    console.log("TEST isStandardAdmin:", this.form.data.user.isStandardAdmin);
    console.log("TEST isCDP", this.form.data.isCDP);
    if(this.form.data.user.isSuperAdmin == false && this.form.data.user.isStandardAdmin == false){
      if(this.form.data.isCDP){
        // this.router.navigateByUrl('/emergingthreatresponse');
      }else{
        //redirect to unauthorized page
        this.router.navigateByUrl('/unauthorized');
      }
    }else{
      this.isUnauthorized = false;
      if (this.form.data.transaction == undefined) {
        this.router.navigateByUrl('/transaction');
      } else {
        this.initData().subscribe(data =>{

        })
      }
    }
  }

  initData(){
    return new Observable(observer =>{
      // loading spinner
      const loading = this.dialog.open(LoadingComponent, { panelClass: 'custom-dialog'});

      this.activatedRoute.params.subscribe(params => {
        const alertIDParam = params['alertID'];
        //console.log('Url Id: ',id);
        let alertID  = alertIDParam ? alertIDParam :this.form.data.transaction.alertID;

        this.form.getTransactionDeliveryDetail(alertID).subscribe(data => {
          console.log('done getting delivery details')
          observer.next(true)
        })

        this.form.checkStatus().subscribe(data => {
          this.status = data;
          console.log('Batch Status:',data);
        })

        this.totalRecipients = "";
        loading.close();
        this.processTransactionRecipient(alertID,0);
      })
    })
  }

  reload(){
    //reload total read
    let eids
    this.form.getTotalReads(this.form.data.transaction.alertID).subscribe(retEids => {
      eids = retEids
      this.form.data.newTotalReads = eids.length;
    })

    //reload report
    if (this.form.data.transaction.alertType.type == 'EmergingThreat') this.etQComponent.getReport(this.form.data.transaction.alertID)

    // if(this.form.data.transaction.alertType.type=='EmergingThreat')
    //   this.etQComponent.ngOnInit()
    // this.dDeailComponent.ngOnInit()
    this.ngOnInit();
  }

  processTransactionRecipient(alertID,startItem){
    const loading = this.dialog.open(LoadingComponent, { panelClass: 'custom-dialog'});
    this.form.getTransactionRecipient(alertID,startItem).subscribe((data:any) => {
      console.log("YELLO", data)
      for(let i=0; i < data.users.length; i++){
        let userNotificationId = data.users[i].split("|");
        this.totalRecipients += userNotificationId[0] + ";";
      }
      this.form.data.transaction.recipientCount = data.totalCount;
      let recipients = this.totalRecipients.split(';');
      recipients.pop();
      for(let x=0; x<recipients.length; x++){
      if(recipients[x].includes('.')){
        recipients[x] = recipients[x] + "@accenture.com";
      }else{
        recipients[x] = recipients[x] + "";
      }

      }
      let recipientlist = recipients.join(";");
      this.form.data.transaction.recipients.eid = recipientlist;
      if(data.returnCount == data.totalCount - data.startItem){
        //end API call
        loading.close();
      }else{
        //retrigger API for remaining users
        this.processTransactionRecipient(alertID,data.startItem + data.maxItems);
        loading.close();
      }
    });
  }

  downloadFile() {
    this.form.download.downloadCsv(this.form.data.transaction.recipients.fileName, this.form.data.transaction.recipients.fileValue.toLowerCase());
  }

  downloadFileTotalRecipients() {
    this.form.download.downloadCsv("TotalRecipients.csv", this.totalRecipients);
  }

  clickMyTransaction() {
    this.router.navigateByUrl('/transaction');
  }

  openDialog() {
    this.dialogRef = this.dialog.open(FollowupQuestionComponent, {
      disableClose: true,
      width: '85vw',
      maxWidth: '85vw',
      maxHeight: '500px',
      data: {
        formData: this.form.data
      },
    });

    this.dialogRef.afterClosed().subscribe(data => {
      // this will refreshed the page after closed.
      this.initData().subscribe(data=>{
        this.etQComponent.initData();
      })
    })
  }
  createFollowupQuestion() {
    if(this.status){
      return;
    }
    this.openDialog();
  }

  openReminderDialog() {
    this.dialogRef = this.dialog.open(ReminderQuestionComponent, {
      disableClose: true,
      maxWidth: '50vw',
      maxHeight: '500px',
      data: {
        formData: this.form.data
      },
    });

    this.dialogRef.afterClosed().subscribe(data => {
      // this will refreshed the page after closed.
      this.initData().subscribe(data=>{
        this.etQComponent.initData();
      })
    })
  }
  sendReminder() {
    this.openReminderDialog();
  }
}
