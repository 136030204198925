export const Config  = {
    
    AlertStatus: {
        Draft: "Draft",
        Verified: "Verification Sent",
        Published: "Published",
        Resolved: "Resolved"
    }
}

