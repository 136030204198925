
<div class="delete">
  <div class="dialog-title">
        <img src={{icon}}>

      <div class = "medium-font"> Are you sure you want to delete the </div>

      <div class="dialog-desc medium-font" *ngIf="data > 1 ;else OneTransaction">
         <p>selected transactions?</p>
      </div>
      <ng-template #OneTransaction>
        <p class=" medium-font">selected transaction?</p>
     </ng-template>

  </div>

  <p *ngIf="data > 1 ;else OneTransactionRows"> If you click Yes, selected items will be deleted immediately.</p>
  <ng-template #OneTransactionRows>
    <p> If you click Yes, selected item will be deleted immediately.</p>
  </ng-template>
  <div class="dialog-buttons">

    <br>

      <div class="dialog-button">
        <span>
          <a mat-raised-button class="green-button" (click)="yesClick()">
            YES
          </a>
        </span>
        <span>
          <a mat-raised-button class="red-button" (click)="cancelClick()">
            CANCEL
          </a>
        </span>
      </div>


  </div>


</div>
