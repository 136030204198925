<div class="white-box" *ngIf="isUnauthorized"></div>
<app-nav></app-nav>
<div role="region" aria-label="Skip" >
    <a class="skip-main" href="#main">Skip to main content</a>
</div>
<div class="admin-container" id="main" tabindex="-1">
    <div tabindex="0" class="admin-button" role="img" aria-label="My Transactions button">

        <a class="as-btn-create bg-purple" (click)="clickMyTransactions()">
            <mat-icon  aria-hidden="false" role="region" aria-label="Example home icon" >
                home
            </mat-icon>
            <span aria-label="My Transactions button" role="region">My Transactions</span>
        </a>

    </div>
    <div class="create-container" role="region" aria-label="Create Transaction">

            <div class="title-container">
                <h3 class="headerTitle" tabindex="0">CREATE TRANSACTION</h3>
                <ul class="breadcrumb create-breadcrumb">
                    <li><a class="current" tabindex="0">Message</a></li>
                    <li><a tabindex="0">Preview</a></li>
                </ul>
            </div>

            <div class="transaction-form">
                <h3 class="form-title bold-font" tabindex="0">MESSAGE</h3>

                <form class="form-field" appFormfocus [formGroup]="createTransaction">
                    <div class="form-data">
                        <div class="form-data-field" role="region" aria-label="Notification Type">
                            <div class="form-label bold-font" tabindex="0">NOTIFICATION TYPE</div>
                            <!-- <div *ngIf="!isStandard" tabindex="0"> -->
                              <div tabindex="0">
                                <mat-form-field appearance="outline">
                                    <mat-select formControlName="alertType" placeholder="Alert Type" (selectionChange)="typeChange($event.value)" [(ngModel)]="form.data.transaction.alertType" required aria-busy="true">
                                     <mat-option *ngFor="let alert of form.config.alerts"
                                           [value]="alert">
                                       {{alert.description}}
                                     </mat-option>
                                   </mat-select>
                              </mat-form-field>
                                 <div *ngIf="submitted && f['alertType'].errors" class="invalid">
                                       <div *ngIf="f['alertType'].errors['required']">Alert Type is required</div>
                                  </div>
                            </div>
                            <!-- <div *ngIf="isStandard">
                                <label for="isStandard" class="sr-only">Standard</label>
                                <mat-form-field appearance="outline"  tabindex="0">
                                    <input matInput formControlName="alertType" [(ngModel)]="form.data.transaction.alertType.type" id="isStandard">
                                </mat-form-field>
                            </div> -->

                        </div>

                        <div class="form-data-field" role="region" aria-label="Rag Status" >
                            <div class=" form-label bold-font" tabindex="0">RAG STATUS</div>
                            <div class="rag-status">
                                <mat-radio-group formControlName="ragStatusStandard" tabindex="0" #ragStatusGroup *ngIf="form.data.transaction.alertType.type != 'EmergingThreat'" aria-label="Select an option" labelPosition="before" [(ngModel)]="form.data.transaction.ragStatus" aria-busy="true">
                                    <mat-radio-button tabindex="0" class="urgent" value="Urgent" (change)="ragStatusChange($event)">Urgent</mat-radio-button>&nbsp;
                                    <mat-radio-button tabindex="0" class="warning" value="Warning" (change)="ragStatusChange($event)">Warning </mat-radio-button>&nbsp;
                                    <mat-radio-button tabindex="0" *ngIf="form.data.user.isSuperAdmin && form.data.transaction.alertType.type == 'CriticalCyberNotifications'" class="cdpred" value="CDP Red Accounts" (change)="ragStatusChange($event)">CDP Red Accounts </mat-radio-button>
                                    <mat-radio-button tabindex="0" *ngIf="form.data.transaction.alertType.type != 'CriticalCyberNotifications'" class="awareness" value="Awareness" (change)="ragStatusChange($event)">Awareness</mat-radio-button>
                                </mat-radio-group>

                                <mat-radio-group formControlName="ragStatusEmergingThreat" #ragStatusGroupET  *ngIf="form.data.transaction.alertType.type == 'EmergingThreat'" aria-label="Select an option" labelPosition="before" [(ngModel)]="form.data.transaction.ragStatus" aria-busy="true">
                                    <mat-radio-button tabindex="0" class="urgent" value="Emerging Threat" [checked]='true' selected='true'>Emerging Threat </mat-radio-button>&nbsp;
                                </mat-radio-group>

                                <div *ngIf="submitted && f['ragStatusStandard'].errors" class="invalid">
                                    <div tabindex="0" *ngIf="f['ragStatusStandard'].errors['required']">RAG status is required</div>
                               </div>
                               <div *ngIf="submitted && f['ragStatusEmergingThreat'].errors" class="invalid">
                                    <div tabindex="0" *ngIf="f['ragStatusEmergingThreat'].errors['required']">RAG status is required</div>
                                </div>
                            </div>


                        </div>

                    </div>
                    <div  class="form-data" role="region" aria-label="Category and Expiration Date">
                        <div class="form-data-field">
                            <div class="form-label  bold-font" tabindex="0">CATEGORY</div>
                            <mat-form-field appearance="outline">
                                <!--mat-label>Category</mat-label-->
                                <mat-select formControlName="category" #myElement placeholder="Category Type" [(ngModel)] ="form.data.transaction.categories" required tabindex="0" aria-busy="true">

                                    <mat-option *ngFor="let category of  form.config.categories"  [value]="category">
                                    {{category.description }}
                                    </mat-option>
                                </mat-select>
                              </mat-form-field>
                              <div *ngIf="submitted && f['category'].errors" class="invalid" tabindex="0">
                                <div *ngIf="f['category'].errors['required']">Category is required</div>
                            </div>
                        </div>
                        <div class="form-data-field" *ngIf="form.data.transaction.alertType.type != 'EmergingThreat'">
                            <div class="form-label" tabindex="0"><span class="bold-font"> EXPIRATION DATE </span> (Optional)</div>
                            <mat-form-field appearance="outline" MatFormFieldControl class="form-datepicker"  tabindex="0">
                                <label for="datePicker" class="sr-only">EXPIRATION DATE</label>
                                <input matInput readonly formControlName="expiration" [matDatepicker]="picker" [min]="minDate" [(ngModel)]="form.data.transaction.expiration" id="datePicker">
                                <mat-datepicker-toggle matSuffix [for]="picker"  ></mat-datepicker-toggle>
                                <mat-datepicker  #picker></mat-datepicker>
                            </mat-form-field>
                            <div *ngIf="submitted && f['expiration'].errors" class="invalid">
                                <div *ngIf="f['expiration'].errors['matDatepickerMin']">Expiration date should be atleast today</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-data">

                        <div class="form-data-field" >
                            <div class="form-label  bold-font" tabindex="0">RECIPIENTS</div>
                            <div class="recipient-row">
                                <mat-form-field appearance="outline" tabindex="0" *ngIf="form.data.transaction.recipients.type != 'file'" class="toDisplay">
                                    <input  matInput placeholder="To:" disabled>
                                 </mat-form-field>

                                <mat-form-field appearance="outline" tabindex="0" *ngIf="form.data.transaction.recipients.option == 'ALL'"  MatFormFieldControl style="width: 100%;">
                                    <input formControlName="recipientAll" matInput placeholder="" value="All Mobile Users">
                                </mat-form-field>
                                <!-- <mat-form-field appearance="outline" tabindex="0" *ngIf="form.data.transaction.recipients.option == 'allsmishing'"  MatFormFieldControl style="width: 100%;">
                                    <input formControlName="recipientAllsmishing" matInput placeholder="" value="All Smishing recipients">
                                </mat-form-field> -->
                                <mat-form-field appearance="outline" tabindex="0" *ngIf="form.data.transaction.recipients.option == 'eids' ||  form.data.transaction.recipients.option == undefined "  MatFormFieldControl style="width: 100%;">
                                    <input (keydown.enter)="focusout($event)" (focusout)="focusout($event)" formControlName="recipient" matInput [(ngModel)]="form.data.transaction.recipients.eid" >
                                </mat-form-field>

                                <!-- <mat-form-field appearance="outline" tabindex="0" *ngIf="form.data.transaction.recipients.option == 'eidsmishing' "  MatFormFieldControl style="width: 100%;">
                                    <input (keydown.enter)="focusout($event)" (focusout)="focusout($event)" formControlName="recipientsmishing" matInput [(ngModel)]="form.data.transaction.recipients.eidsmishing" >
                                </mat-form-field> -->
                                <!-- <div appearance="outline" *ngIf="form.data.transaction.recipients.option == 'file' && !fileReaded" style="width: 100%;">

                                    <input matInput placeholder="No file choosen" (click)="openDialog()">


                                </div> -->
                                <mat-form-field appearance="outline" tabindex="0" class="fileupload" *ngIf="form.data.transaction.recipients.option == 'file' && !form.data.transaction.recipients.fileReaded"   style="width: 100%;">
                                    <span class="material-icons"  matPrefix>
                                        attach_file
                                    </span>
                                    <input matInput formControlName="recipientFileHolder"  placeholder="  No file chosen"  (click)="openDialog()" >
                                </mat-form-field>
                                <mat-form-field appearance="outline" tabindex="0" class="filetext" *ngIf="form.data.transaction.recipients.option == 'file' && form.data.transaction.recipients.fileReaded"  MatFormFieldControl style="width: 100%;">
                                    <input formControlName="recipientFileName"  matInput placeholder="">
                                </mat-form-field>

                                <mat-form-field appearance="outline" tabindex="0" *ngIf="form.data.transaction.recipients.option == 'ad'"  MatFormFieldControl style="width: 100%;">
                                    <input (keydown.enter)="focusout($event)" (focusout)="focusout($event)" formControlName="recipientAD" matInput placeholder="Please enter AD group..." [(ngModel)]="form.data.transaction.recipients.ad" >
                                </mat-form-field>

                                <!-- <mat-form-field appearance="outline" tabindex="0" class="filetext" *ngIf="form.data.transaction.recipients.option == 'fileNumbers' && form.data.transaction.recipients.fileReaded"  MatFormFieldControl style="width: 100%;">
                                    <input formControlName="fileNumbers"  matInput placeholder="">
                                </mat-form-field>

                                <mat-form-field appearance="outline" tabindex="0" class="fileupload" *ngIf="form.data.transaction.recipients.option == 'fileNumbers' && !form.data.transaction.recipients.fileReaded"   style="width: 100%;">
                                    <span class="material-icons"  matPrefix>
                                        attach_file
                                    </span>
                                    <input matInput formControlName="recipientFileHolder"  placeholder="  No file chosen"  (click)="openDialog()" >
                                </mat-form-field> -->

                                <!-- <mat-form-field  *ngIf="form.data.transaction.alertType.type == 'SmishingTest'" tabindex="0" appearance="outline" style="width: 300px;" class="toSelectRecipient">
                                    <mat-select [disableOptionCentering]="true" formControlName="recipientSelect" placeholder="ADD RECIPIENT BY: "  [(value)]="form.data.transaction.recipients.option" aria-busy="true">
                                        <mat-option value="allsmishing">
                                            All Smishing recipients
                                        </mat-option>
                                        <mat-option value="eidsmishing">
                                            EID
                                        </mat-option>
                                         <mat-option value="ad" (click)="isAD()">
                                            AD Group
                                         </mat-option>
                                         <mat-option value="fileNumbers" (click)="openDialog()">
                                            Upload CSV file
                                        </mat-option>
                                   </mat-select>
                                 </mat-form-field> -->

                                <mat-form-field   *ngIf="form.data.transaction.alertType.type != 'EmergingThreat'" tabindex="0" appearance="outline" style="width: 300px;" class="toSelectRecipient">
                                    <mat-select [disableOptionCentering]="true" formControlName="recipientSelect" placeholder="ADD RECIPIENT BY: "  [(value)]="form.data.transaction.recipients.option" aria-busy="true">
                                        <mat-option value="ALL">
                                            All Mobile Users
                                        </mat-option>
                                        <mat-option value="eids">
                                            Individual Names (Enter ID)
                                        </mat-option>
                                        <mat-option value="file" (click)="openDialog()">
                                            Individual Names (Upload CSV)
                                        </mat-option>
                                        <mat-option value="ad" (click)="isAD()">
                                            AD Group
                                        </mat-option>
                                   </mat-select>
                                 </mat-form-field>

                                 <mat-form-field  *ngIf="form.data.transaction.alertType.type == 'EmergingThreat'" tabindex="0" appearance="outline" style="width: 300px;" class="toSelectRecipient">
                                    <mat-select formControlName="recipientSelectEmergingThreat" placeholder="ADD RECIPIENT BY: "  [(value)]="form.data.transaction.recipients.option" aria-busy="true">
                                         <mat-option value="eids">
                                            Individual Names (Enter ID)
                                         </mat-option>
                                         <mat-option value="file" (click)="openDialog()">
                                            Individual Names (Upload CSV)
                                         </mat-option>
                                         <mat-option value="ad" (click)="isAD()">
                                            AD Group
                                         </mat-option>
                                   </mat-select>
                                 </mat-form-field>
                            </div>
                            <!-- FOR UAT Enhancement -->
                            <div *ngIf="form.data.transaction.recipients.option == 'eids'">
                                <div><b>NOTE:</b> Use a semicolon as a separator when entering multiple names.</div>
                            </div>
                            <div *ngIf="form.data.transaction.recipients.option == 'ad'">
                                <div><b>NOTE:</b> Use a semicolon as a separator when entering multiple AD groups.</div>
                            </div>
                            <div *ngIf="submitted && f['recipient'].errors" class="invalid">
                                <div *ngIf="f['recipient'].errors['required']" tabindex="0">Recipients are required</div>
                            </div>
                            <div *ngIf="submitted && f['recipientAll'].errors" class="invalid">
                                <div *ngIf="f['recipientAll'].errors['required']" tabindex="0">Recipients are required</div>
                            </div>

                            <div *ngIf="submitted && f['recipientSelectEmergingThreat'].errors" class="invalid">
                                <div *ngIf="f['recipientSelectEmergingThreat'].errors['required']" tabindex="0">Recipients are required</div>
                            </div>
                            <div *ngIf="submitted && f['recipientSelect'].errors" class="invalid">
                                <div *ngIf="f['recipientSelect'].errors['required']" tabindex="0">Recipients are required</div>
                            </div>

                            <div *ngIf="submitted && f['recipientFileName'].errors" class="invalid">
                                <div *ngIf="f['recipientFileName'].errors['required']" tabindex="0">No file chosen</div>
                            </div>

                            <div *ngIf="submitted && f['recipientAD'].errors && adNotDisplay == true || submitted && f['recipientAD'].errors && adDisplay == true" class="invalid">
                                <div *ngIf="f['recipientAD'].errors['required']" tabindex="0">AD Group required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-data">

                        <div class="form-data-field" >
                            <div class='text-desc'>
                                <div class="form-label">
                                    <div class="form-label bold-font" tabindex="0">TITLE</div>
                                    <span class="charCount" tabindex="0">Character: {{ counterTitle}} / 50</span>
                                </div>

                                <div class="title-row">
                                    <mat-form-field appearance="outline" MatFormFieldControl style="width: 100%;" tabindex="0">
                                        <input formControlName="title" matInput placeholder="Title" [(ngModel)]="form.data.transaction.title" required maxlength="50">
                                    </mat-form-field>

                                    <div *ngIf="submitted && f['title'].errors" class="invalid">
                                        <div *ngIf="f['title'].errors['required']" tabindex="0">Title is required</div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="form-data">

                        <div class="form-data-field" >
                            <div class='text-desc'>
                                <div class="form-label">
                                    <div class=" bold-font" tabindex="0">SHORT DESCRIPTION</div>
                                    <!-- <span #shortDesc [innerHTML]="htmlValue" ></span> -->
                                    <span class="charCount" tabindex="0">Character: {{ counterDescription}} / 200</span>
                                </div>

                                <div class="text-editor shortDesc">
                                    <quill-editor  #shortDesc formControlName="shortDescription" tabindex="0"
                                    (onContentChanged)="textChanged($event,'short')"
                                    [modules]="editor_modules"
                                    [styles]="shortStyle"
                                    [(ngModel)] ="form.data.transaction.shortDescription"
                                    [class.errorChecker]="errorChecker"
                                    ></quill-editor>
                                    <!-- remove required -->
                                </div>
                                <div *ngIf="submitted && f['shortDescription'].errors" class="invalid">
                                    <div *ngIf="f['shortDescription'].errors['required']" tabindex="0">Short Description is required</div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div class="form-data" >

                        <div class="form-data-field" >
                            <div class='text-desc '>
                                <div class="form-label">
                                    <div class=" bold-font" tabindex="0">DETAILED DESCRIPTION</div>
                                    <!-- <span #shortDesc [innerHTML]="htmlValue" ></span> -->
                                    <span class="charCount" tabindex="0">Character: {{ counterDetailedDescription}} / 1000</span>
                                </div>

                                <div class="text-editor detailDesc" #quillEditor>
                                    <quill-editor tabindex="0"
                                    formControlName="body"
                                    [modules]="editor_detail"
                                     (onContentChanged)="textChanged($event,'detail')"
                                     [styles]="detailStyle"
                                     [(ngModel)]= "form.data.transaction.bodyHtml"
                                     [class.errorChecker]="errorChecker"
                                     > </quill-editor>
                                     <!-- remove required -->
                                </div>

                                <div *ngIf="submitted && f['body'].errors" class="invalid">
                                    <div *ngIf="f['body'].errors['required']" tabindex="0">Detailed Description is required</div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- <div class="form-data" *ngIf="form.data.transaction.alertType.type == 'SmishingTest'">

                      <div class="form-data-field" >
                          <div class='text-desc '>
                              <div class="form-label">
                                  <div class=" bold-font" tabindex="0">SMS MESSAGE</div>
                                  <span class="charCount" tabindex="0">Character: {{ CounterSmsMessage }} / 140</span>
                              </div>

                              <div class="text-editor smsMessage">
                                <mat-form-field appearance="outline" MatFormFieldControl style="width: 100%; height: 100px;" tabindex="0">
                                    <textarea matInput
                                    #shortDesc formControlName="sms" tabindex="0"
                                    (onContentChanged)="textChanged($event,'sms')"
                                    [(ngModel)] ="form.data.transaction.smsMessage"
                                    [class.errorChecker]="errorChecker"
                                    style="width: 100%; height: 100px; resize: none;"
                                    [maxlength]="140"
                                    required></textarea>
                                  </mat-form-field>
                            </div>
                              <div *ngIf="submitted && f['sms'].errors" class="invalid">
                                  <div *ngIf="f['sms'].errors['required']" tabindex="0">SMS Message is required</div>
                              </div>
                          </div>

                      </div>
                  </div> -->

                     <div *ngIf="form.data.transaction.alertType.type == 'EmergingThreat'" tabindex="0">
                        <app-emergingthreat *ngIf="isload" [createTransaction] ='createTransaction'
                        [submitted]='submitted' [errorChecker] ="errorChecker"> </app-emergingthreat>
                     </div>



                    <div class="form-data">

                        <div class="form-data-field" >
                            <div class=" form-label  bold-font" tabindex="0">VERIFICATION</div>
                            <!--div class="reviewer-row">
                                <mat-form-field appearance="outline" MatFormFieldControl style="flex-grow: 1;">

                                    <textarea matInput rows="5" cols="40"  placeholder="Ex. It makes me feel..."></textarea>

                                </mat-form-field>
                                <a mat-flast-button class="checkButton" href="#" target="_blank">
                                    <mat-icon aria-hidden="false" aria-label="Example home icon">
                                        check
                                    </mat-icon>
                                    <span> CHECK NAME</span>
                                </a>
                            </div-->
                            <div class="reviewer-row">
                                <mat-form-field appearance="outline" tabindex="0" *ngIf="form.data.transaction.recipients.type != 'file'" class="toDisplay">
                                    <input  matInput placeholder="To:" disabled>
                                 </mat-form-field>

                                <mat-form-field appearance="outline" tabindex="0" MatFormFieldControl style="width: 100%;">
                                    <label for="verification" class="sr-only">Verification</label>
                                    <input (keydown.enter)="focusout('reviewers')"  (focusout)="focusout('reviewers')" matInput formControlName="reviewers" value=""  [(ngModel)]="form.data.transaction.reviewers" id="verification">

                                </mat-form-field>
                                 <!-- <button mat-stroked-button class="checkButton" (click)="focusout('reviewers')">
                                    <mat-icon aria-hidden="false" aria-label="Example home icon">
                                        check
                                    </mat-icon>
                                    <span> CHECK NAMES</span>
                                 </button> -->

                            </div>
                            <div *ngIf="submitted && f['reviewers'].errors" class="invalid">
                                <div *ngIf="f['reviewers'].errors['required']" tabindex="0">Verification is required</div>
                            </div>


                        </div>
                    </div>

                    <div class="form-data submitButton">
                        <button mat-raised-button class="bg-purple" style="float:right" (click)="next()" tabindex="0">
                            SAVE AND NEXT
                        </button>
                    </div>




                </form>
            </div>

    </div >





</div>

<app-footer></app-footer>
