import { Validators } from '@angular/forms';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {

  icon = '../../../../assets/icons/VECTOR-WARNING.svg'

  constructor(
    public form: FormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteComponent>) {
  }

  ngOnInit(): void { }

  yesClick() {
    this.deleteTransaction()
  }

  cancelClick() {
    this.dialogRef.close("canceled")
  }

  deleteTransaction() {
    let toBeDeleted = []
    toBeDeleted.push(...this.form.data.transaction.toBeDeleted.Pending, ...this.form.data.transaction.toBeDeleted.Publish)
    console.log("delete button: ", toBeDeleted)
    this.form.deleteTransaction(toBeDeleted).subscribe(result => {
      if (result == 200 || result == 201){
        this.dialogRef.close("deleted")
      }else{
        //console.log("error in deleting transactions")
        this.dialogRef.close("error")
      }
    });
  }
}
