<mat-table [dataSource]="deliveryDetail" class="view-table mat-elevation-z8" style="width: 100%;">
    <!-- Position Column -->
    <ng-container matColumnDef="dateSent">
      <mat-header-cell *matHeaderCellDef class="bold-font"> DATE SENT </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.dateSent ? (element.dateSent | date: 'M/d/yyyy' ) : '-' }} </mat-cell>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="timeSent">
      <mat-header-cell *matHeaderCellDef class="bold-font"> TIME SENT </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.dateSent ? (element.dateSent | date: 'h:mm a' ) : '-'}} </mat-cell>
    </ng-container>
    <!-- Weight Column -->
    <ng-container matColumnDef="recipients">
      <mat-header-cell *matHeaderCellDef class="bold-font"> TOTAL RECIPIENTS </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{totalRecipients ? totalRecipients : 0}} </mat-cell>
    </ng-container>
    <!-- Symbol Column -->
    <ng-container matColumnDef="alertread">
      <mat-header-cell *matHeaderCellDef class="bold-font"> TOTAL READ</mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="form.data.newTotalReads == 0 ? 'total-read-zero':'total-read'" (click)="getTotalReads(form.data.transaction.alertID)"> {{form.data.newTotalReads}} </mat-cell>
    </ng-container>
    <!--Total Unread Column-->
    <ng-container matColumnDef="alertUnread">
      <mat-header-cell *matHeaderCellDef class="bold-font"> TOTAL UNREAD</mat-header-cell>
      <mat-cell *matCellDef="let element" class="total-unread"> {{(totalRecipients - form.data.newTotalReads) > 0 ?  totalRecipients - form.data.newTotalReads : 0}} </mat-cell>
    </ng-container>
    <!--Total Pending Column-->
    <ng-container *ngIf='isTotalPending()' matColumnDef="alertPending">
      <mat-header-cell *matHeaderCellDef class="bold-font"> TOTAL PENDING</mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="element.totalPending == 0 ? 'total-read-zero':'total-read'" (click)="getTotalPending(form.data.transaction.alertID)">{{element.totalPending ? element.totalPending : "N/A" }}</mat-cell>
    </ng-container>
    <!-- Total SMS Pending Column -->
    <!-- <ng-container *ngIf="form.data.transaction.alertType.type=='SmishingTest'" matColumnDef="alertPendingSMS">
      <mat-header-cell *matHeaderCellDef class="bold-font"> TOTAL PENDING</mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="element.totalPendingSMS == 0 ? 'total-read-zero':'total-read'" (click)="getTotalPendingSMS(form.data.transaction.alertID)">{{element.totalPendingSMS ? element.totalPendingSMS : "N/A" }}</mat-cell>
    </ng-container> -->

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
