import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ElementRef, AfterViewChecked, AfterViewInit, AfterContentChecked } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import * as Entities from 'html-entities'
import { CompareComponent } from '../../create/compare/compare.component';

@Component({
  selector: 'app-followup-question',
  templateUrl: './followup-question.component.html',
  styleUrls: ['./followup-question.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class FollowupQuestionComponent implements OnInit, AfterViewChecked {

  disableSend: boolean = false
  textSend = "SEND"
  ET_DATA;
  createTransaction: FormGroup
  currentId = 0;
  maxQuestion; maxAnswer;
  disableAnswer = false
  submitted: boolean = false
  fAnswer = ''
  errorChecker = false
  testboolean = true
  addReponses: boolean = true
  isSelectRec: boolean = false
  isFollowUpLimit: boolean = false;
  disableSendSR: boolean = false;

  isUploadCSV: boolean = false;
  public csvError;
  public buttonFileText = "Browse file"
  public retValCSV = undefined;
  public btnUpdate = false;
  public isLoadingCSV = false;
  public errorDomain = [];
  public dialogRefCompare: any;
  public compareDialog: any;

  ansText = 'Reference answer is required'
  questionText = 'Reference question is required'
  tRecipient = 'Recipients are required'

  preQuestion = ''
  listAnswer = []
  listQuestion = []
  listRecipient = [{ index: 0, description: "All Recipients" },
                   { index: 1, description:"Select Recipients"},
                   { index: 2, description:"Upload CSV"}]
  questionIndex: number = 0;
  //isNotFU = false;

  @ViewChild('reference') element : ElementRef;
  @ViewChild('fileUpload') fileUpload: ElementRef;

  constructor(
    public form: FormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dialogRef: MatDialogRef<FollowupQuestionComponent>,
    public formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this.createTransaction = this.formBuilder.group({
      trecipient: ['', Validators.required],
      question: ['', Validators.required],
      fanswer: ['', Validators.required],
      fquestion: ['', Validators.required],
      btnRows: this.formBuilder.array([])
    });

    this.createTransaction.get('fquestion').valueChanges.subscribe(value => {
      console.log("value", value)
      if (value) {
        console.log("has value", value)
        if (value > this.maxQuestion) {
          this.createTransaction.get('fquestion').setValue('')
        }
      }
    })

    this.createTransaction.get('fanswer').valueChanges.subscribe(value => {
      if (value) {
        if ((value > this.maxAnswer) || (value == 0)) {
          this.createTransaction.get('fanswer').setValue('')
        }
      }
    })
  }
  public toolbarOptions = [['bold', 'italic', 'underline'], ['clean'], ['link']];
  public quillLinkRemove = {
    'link': function (value: string) {
      const that: any = this;

      const tooltip = that.quill.theme.tooltip;
      const input = tooltip.root.querySelector("input[data-link]");
      input.dataset.link = "https://";
      input.placeholder = "https://";
      input.dataset.lpignore = true;

      if (value) {
        const range = that.quill.getSelection();
        if (range == null || range.length === 0) { return; }
        let preview = that.quill.getText(range);
        if (
          /^\S+@\S+\.\S+$/.test(preview) &&
          preview.indexOf("mailto:") !== 0
        ) {
          preview = `mailto:${preview}`;

        }
        const { tooltip } = that.quill.theme;
        tooltip.edit("link", preview);
        input.value = "https://" + preview;


      } else {
        that.quill.format("link", false);
      }
    },
  }
  question_detail = {
    toolbar: {
      container: this.toolbarOptions,  // Selector for toolbar container
      handlers: this.quillLinkRemove
    }

  }

  questionStyle = {
    height: '50px',
    background: '#fff',
  }
  // question_detail = {
  //   toolbar: [
  //     ['bold', 'italic', 'underline'],
  //     ['link'],
  //   ]
  // }

  get f() { return this.createTransaction.controls; }

  ngAfterViewChecked(): void {
    //accessing the quill-editor toolbar buttons and adding aria-label for accessibility issues.
    const quillButtonClassNames = ['ql-bold','ql-italic', 'ql-underline','ql-clean','ql-link'];
    quillButtonClassNames.forEach((element,index) => {
      let label = this.element.nativeElement.getElementsByClassName(element).item(0)
      // console.log("label", label);
      if(label)
        label.setAttribute('aria-label',quillButtonClassNames[index]);
    });

    let bodyLabel = document.getElementsByClassName('cdk-focus-trap-anchor').item(0);
    if(bodyLabel){
      bodyLabel.setAttribute('aria-hidden','false');
      bodyLabel = document.getElementsByClassName('cdk-focus-trap-anchor').item(1);
      bodyLabel.setAttribute('aria-hidden','false');
      bodyLabel = document.getElementsByTagName('app-root').item(0);
      bodyLabel.setAttribute('aria-hidden','false');
    }
  }

  ngOnInit(): void {

    console.log('transaction 123', this.form.data.transaction)
    this.form.data.transaction.followup = {}
    //this.form.data.transaction.followup.fanswer = 3
    console.log("dialog data", this.data)
    console.log("controls length", this.createTransaction.controls['btnRows'])
    this.setInitData();
    this.maxQuestion = this.data.formData.Report.report.length
    this.ET_DATA = this.form.data.transaction;
  }
  onChangeQ(value) {
    this.disableSendSR = true
    this.isFollowUpLimit = false;
    console.log("question value", value)
    if (value != '') {
      var qid = value.id
      // if (value > this.maxQuestion) {
      //   this.form.data.transaction.followup.fquestion = ''
      //   this.form.data.transaction.followup.fans = ''
      // } else {
      //   let index = parseInt(value) - 1
      //   if (this.form.data.transaction.followup.fquestion != 0) {
      //     this.maxAnswer = this.data.formData.Report.report[index].responses.length
      //   }
      //   else {
      //     this.form.data.transaction.followup.fquestion = ''
      //     this.questionText = 'Please add a valid value for Pre-condition Question'
      //   }
      //   this.form.data.transaction.followup.fans = ''
      // }
      if (value.index >= 1) {
        this.listAnswer = []
        this.questionIndex = value.index - 1
        var responses = this.data.formData.Report.report[value.index - 1].responses
        responses.forEach(response => {
          var aid = response.aid
          var value = response.response
          this.listAnswer.push({ id: aid, description: value, qid: qid, keyAnswer: aid.replace('Answer', '') })
        });
        this.createTransaction.get('fanswer').enable();
      } else {
        this.form.data.transaction.followup.fquestion = ''
        this.questionText = 'Please add a valid value for Pre-condition Question'
      }
    }
    else {
      this.form.data.transaction.followup.fans = ''
    }
  }

  onChangeA(value) {
    this.isFollowUpLimit = false;
    this.disableSendSR = true
    this.createTransaction.get('fquestion').clearValidators();
    this.createTransaction.get('fanswer').clearValidators();
    this.createTransaction.get('fquestion').enable();
    this.createTransaction.get('fanswer').enable();
    //Get followUpCount from original question first
    this.form.getQALimit(this.data.formData.transaction.alertID, value.qid, value.keyAnswer).subscribe((res: any) => {
      console.log("TEST getQALimit res: ", res);
      console.log("TEST QALimit data: ", this.form.data.transaction.QALimit);
      this.isFollowUpLimit = res.isFollowUpLimit == 1 ? true : false;

      if(this.isFollowUpLimit == true){
        this.createTransaction.get('fquestion').setErrors({'incorrect': true});
        this.createTransaction.get('fanswer').setErrors({'incorrect': true});
      } else {
        this.disableSendSR = false;
      }
    })
  }

  onChangeRecipient(value) {
    console.log("data: ", this.form.data)
    this.disableSendSR = false
    if (value.index == 1) {
      // this.fileUpload.nativeElement.value = "";
      this.buttonFileText = "Browse file";
      this.isLoadingCSV = false;
      this.btnUpdate = false;
      this.createTransaction.get('fquestion').enable();
      this.isSelectRec = true;
      this.isUploadCSV = false;
      this.isFollowUpLimit = false;
      this.csvError = '';
      if(this.submitted) {
        this.createTransaction.get('fquestion').setErrors({'incorrect': true});
      } else {
        this.createTransaction.get('fquestion').markAsUntouched();
      }
    } else if(value.index == 2){
      this.form.data.transaction.followup.fquestion = '';
      this.form.data.transaction.followup.fans = '';
      this.createTransaction.get('fquestion').disable();
      this.createTransaction.get('fanswer').disable();
      this.isSelectRec = false;
      this.isUploadCSV = true;
      this.isFollowUpLimit = false;
    } else {
      // this.fileUpload.nativeElement.value = "";
      this.buttonFileText = "Browse file";
      this.isLoadingCSV = false;
      this.btnUpdate = false;
      this.form.data.transaction.followup.fquestion = '';
      this.form.data.transaction.followup.fans = '';
      this.createTransaction.get('fquestion').disable();
      this.createTransaction.get('fanswer').disable();
      this.isSelectRec = false;
      this.isUploadCSV = false;
      this.isFollowUpLimit = false;
      this.csvError = '';
    }

    console.log("upload csv: " +  this.isUploadCSV + " selected rec: " + this.isSelectRec)

  }

  itemrows(id) {
    return this.formBuilder.group({
      ["Answer" + id]: ['', Validators.required],
    });
  }
  get formArr() {
    return this.createTransaction.get('btnRows') as FormArray;

  }
  append(id) {
    console.log(this.itemrows(id));
    if (this.formArr.controls.length < 10) {
      this.formArr.push(this.itemrows(id));
      this.currentId = id;
    }
    if (this.formArr.controls.length == 10) {
      this.addReponses = false
    }

  }

  public get questionCounter() {
    let retVal = 0;
    if (this.form.data.transaction.followup.Question) {
      let replaced = this.form.data.transaction.followup.Question.replace(/<[^>]+>/g, '');
      replaced = replaced.replace("&nbsp;", ' ');
      retVal = replaced.length + (this.form.data.transaction.followup.Question.split('</p><p>').length - 1);
      // retVal = replaced.length;
      if (retVal >= 200) {
        retVal = 200;
      } else {
        retVal = retVal;
      }

    }

    return retVal;
  }

  reappend(id) {

    this.formArr.removeAt(id);
    this.deleteData(this.formArr.controls.length)


    this.currentId = this.currentId - 1;

    if (this.formArr.controls.length < 10) {
      this.addReponses = true
    }


  }

  setInitData() {
    for (let i = 0; i < 2; i++) {
      console.log("append i", i)
      this.append(i);
    }
    this.setQuestionData();
    // const entities = Entities.XmlEntities
    // var questionIndex = this.data.formData.Report.report.length - 1
    // this.form.data.transaction.followup.fquestion = ''
    // // this.data.formData.transaction.followup.fquestion = this.data.formData.Report.report[questionIndex].question
    // // this.data.formData.transaction.followup.fquestion = this.data.formData.transaction.followup.fquestion.replace(/<\/?[^>]+(>|$)/g, "")
    // // this.data.formData.transaction.followup.fquestion = entities.decode(this.data.formData.transaction.followup.fquestion)
    // var questions = this.data.formData.Report.report
    // var index = 0;
    // questions.forEach(question => {
    //   var qid = question.qid
    //   var value = question.question
    //   value = value.replace(/<\/?[^>]+(>|$)/g, "")
    //   value = entities.decode(value)
    //   index += 1
    //   this.listQuestion.push({ index: index, id: qid, description: value })
    // });

    // this.createTransaction.get('fquestion').disable();
    // this.createTransaction.get('fanswer').disable();

    // setTimeout(() => {
    //   this.createTransaction.markAsUntouched();
    //   this.createTransaction.get('fquestion').enable();
    // }, 1000)

    // var responses = this.data.formData.Report.report[0].responses
    // responses.forEach(response => {
    //   var aid = response.aid
    //   var value = response.response
    //   this.listAnswer.push({ id: aid, description: value })
    // });
    // this.listAnswer = this.data.formData.Report.report[questionIndex].responses

  }

  setQuestionData() {
    const entities = Entities.XmlEntities
    var questions = this.data.formData.Report.report
    var index = 0;
    questions.forEach(question => {
      var qid = question.qid
      var value = question.question
      value = value.replace(/<\/?[^>]+(>|$)/g, "")
      value = entities.decode(value)
      index += 1
      this.listQuestion.push({ index: index, id: qid, description: value })
    });
  }

  deleteData(index) {
    delete this.form.data.transaction.followup['Answer' + index]
    if (this.form.data.transaction.followup.hasOwnProperty('AllAns')) {
      console.log("has all ans")
      delete this.form.data.transaction.followup.AllAns[index]
    }
    console.log("deleted updated answers", this.form.data.transaction.followup)
  }

  textChanged($event, data) {
    console.log("text change from question")
    if ($event.editor.getLength() > 200) {
      $event.editor.deleteText(200, $event.editor.getLength());
      if($event.source == 'api'){
        this.form.data.transaction.followup.Question = $event.html;
      }
    }


  }

  send() {
    // console.log("answer controls", this.f.fanswer.errors.required)
    this.submitted = true
    let hasQuestion = false

    if (this.form.data.transaction.followup.fquestion == '' && (this.form.data.transaction.followup.Question != '' && this.form.data.transaction.followup.Question != undefined)) {
      hasQuestion = true
      if (this.form.data.transaction.followup.Answer0 && this.form.data.transaction.followup.Answer1) {
        //this.isNotFU = true;
        this.errorChecker = false;
      }
    }

    if (this.createTransaction.invalid) {
      console.log("validators", this.createTransaction.controls['fanswer'])
      console.log("validators", this.createTransaction.controls['question'])
      this.createTransaction.markAllAsTouched();
      if (this.form.data.transaction.followup.Question == null) {
        this.errorChecker = true;
      }
      if (this.isUploadCSV == true && this.buttonFileText == "Browse file"){
        this.csvError = "No EIDs found.";
      }
    } else {
      if (this.form.data.transaction.followup.fquestion == 0 && this.isSelectRec) {
        if (!hasQuestion)
        this.form.data.transaction.followup.fquestion = ''
        this.questionText = 'Please add a valid value for Pre-condition Question'
      } else if (this.form.data.transaction.followup.fans == 0 && this.isSelectRec) {
        this.form.data.transaction.followup.fans = ''
        this.ansText = 'Please add a valid value for Pre-condition Answer'
      } else if (this.isFollowUpLimit){
          //Follow-up question should not send if follow-up limit is reached
      } else if (this.disableSendSR){
          //Send button should be disabled 
      } else if (this.isUploadCSV == true && this.buttonFileText == "Browse file"){
        this.csvError = "No EIDs found.";
      } else {
        if (!this.disableSend) {
          const entities = Entities.XmlEntities

          this.disableSend = true;
          this.textSend = "SENDING..."
          if (!this.createTransaction.invalid) {
            let numberId = ((~new Date()) / 100);
            numberId = numberId < 0 ? numberId * -1 : numberId;

            let qid = this.data.formData.transaction.alertID + "|" + new String(Math.floor(numberId))
            let questionno = this.data.formData.Report.report.length + 1;
            //let questionindex = parseInt(this.form.data.transaction.followup.fquestion)
            let questionindex = this.questionIndex + 1;
            console.log("new question index", questionindex)
            let keynswerObj : any = '';
            let keynswer : any = '';
            let refQID : any = '';
            let newQA = {}

            if(this.isSelectRec) {
              // let keynswer = parseInt(this.form.data.transaction.followup.fans)
              console.log("KEYANSWER", this.listAnswer, this.form.data.transaction.followup.fans)
              keynswerObj = this.listAnswer.find(item => item.id == this.form.data.transaction.followup.fans.id)
              console.log("KEYANSWER NEW", keynswerObj)
              keynswer = parseInt(keynswerObj.id.replace('Answer', ''))
              refQID = this.data.formData.Report.report.find(x => x.no == questionindex)['qid']
              console.log("REFQID", refQID)
              
              //Get follow up count

              newQA = {
                Id: qid,
                Details: JSON.stringify(JSON.stringify(this.setQAData())),
                No: questionno,
                refQID: refQID,
                createdDate: new Date(),
                keyAnswer: keynswer,
                isFollowUpLimit: this.form.data.transaction.QALimit[0].FollowUpCount
              }
            } else {
              newQA = {
                Id: qid,
                Details: JSON.stringify(JSON.stringify(this.setQAData())),
                No: questionno,
                createdDate: new Date()
              }
            }

            let toBeUpdated = {
              modifiedDate: new Date(),
              modifiedBy: this.form.data.user.eid,
              hasFollowup: "true"
            }
            this.form.updateTransaction(this.data.formData.transaction.alertID, toBeUpdated).subscribe(updated => {
              console.log("transaction updated");
            })

            this.form.saveTransaction(newQA).subscribe(savedQA => {
              console.log("QA Saved", savedQA)
              //update reference question.


              // let updateQA = {
              //   keyAnswer: keynswer,
              //   FollowUpQID: qid
              // }
              //  console.log("toUpdateQA", updateQA, refQID)
              // this.form.updateQADetails(refQID, updateQA).subscribe(updatedQA => {
              // console.log("updated QA", updatedQA, refQID)
              //get eids of who answer from ref questions
              //parameters will be answer no and q
              this.form.getAnsEids(this.data.formData.transaction.alertID, refQID, keynswer).subscribe((eids: any) => {
                console.log("EIDS", eids)
                this.form.getReport(this.data.formData.transaction.alertID).subscribe(res => {
                  if (eids.length > 0 || !this.isSelectRec) {
                    console.log('billy question ', this.form.data.transaction);
                    this.sendNotification(this.data.formData.transaction.alertID, eids, this.form.data.transaction.followup.Question, this.form.data.transaction.isAD, this.form.data.transaction.recipients.ad).subscribe(notifData => {
                      let r = {
                        status: 'success',
                        message: 'success sending notification'
                      }
                      //UPADTE ET STATUS
                      if(this.ET_DATA.etStatus == 'Awaiting Response'){
                        console.log('Awaiting Response');
                      }else if(this.ET_DATA.etStatus == 'Awaiting Response to Follow-up Question'){
                        console.log('Awaiting Response to Follow-up Question');
                      }else if(this.ET_DATA.etStatus == 'Responded'){
                        if(!this.isSelectRec){ // all recipients
                          this.form.updateETstatus('Awaiting Response to Follow-up Question', this.ET_DATA.alertID).subscribe(ret => {
                            console.log('Awaiting Response to Follow-up Question')
                          });
                        }else{
                          if(eids.length > 0){
                            this.form.updateETstatus('Awaiting Response to Follow-up Question', this.ET_DATA.alertID).subscribe(ret => {
                              console.log('Awaiting Response to Follow-up Question')
                            });
                          }else{
                            console.log('Responded');
                          }
                        }
                      }

                      this.dialogRef.close(r);
                    }, error => {
                      let r = {
                        status: 'error',
                        message: 'error sending notification',
                        error: error
                      }
                      this.dialogRef.close(r);
                    })
                  } else {
                    let r = {
                      status: 'success',
                      message: 'updated',
                    }
                    this.dialogRef.close(r);
                  }
                })
              })
            })
            // })
          }
        }
      }
    }
  }

  public sendNotification(alertID, eids, question, isAD, group = '', type = 'FU') {
    return new Observable(observer => {
      let toBeSend = {}
      let isMultipleUsers = false;

      // for all recipients only
      if (!this.isSelectRec && !this.isUploadCSV) {
        if(isAD){
          toBeSend = {
            "alertID": alertID,
            "group": this.form.data.transaction.recipients.ad,
            "question": question,
            "type": type,
            "sendToRecipients": "True"
          }
        }else if(this.form.data.transaction.recipients.option == 'file'){
          isMultipleUsers = true;
          toBeSend = {
            "alertID": alertID,
            "group": "MultipleUsersCSV",
            "question": question,
            "type": type,
            "sendToRecipients": "True"
          }
        }else if(this.form.data.transaction.recipients.option == 'eids'){
          isMultipleUsers = true;
          toBeSend = {
            "alertID": alertID,
            "group": "MultipleUsersEID",
            "question": question,
            "type": type,
            "sendToRecipients": "True"
          }
        }else{
          toBeSend = {
            "alertID": alertID,
            "sendToRecipients": "True",
            "eid": this.form.data.transaction.recipients.option == 'eids' ? this.form.data.transaction.recipients.eid : this.form.data.transaction.recipients.fileValue.toLowerCase(),
            "type": type,
            "question": question
          }
        }
      } else if(this.isUploadCSV){ // for upload CSV

        // this.retValCSV                                //CSV - string - all recipients from CSV
        // this.form.data.transaction.existingRecipients //CSV - array  - all existing recipients from CSV
        // this.form.data.transaction.newRecipients      //CSV - array  - all new recipients from CSV
        // this.form.data.transaction.allRecipients      //FormService  - original recipients
        isMultipleUsers = true;
        this.separateExisting(this.retValCSV.eids, this.form.data.transaction.allRecipients)
        toBeSend = {
          "alertID": alertID,
          "group": "ExsistingUsersEID",
          "sendToRecipients": "True",
          "eid": this.retValCSV.eids,
          "newEid": this.form.data.transaction.newRecipients.join(';'),
          "type": type,
          "question": question,
          "isAD": isAD
        }
        isAD = false;
      } else { // for selected recipients
        eids = eids.filter((v, i, a) => a.indexOf(v) === i);
        toBeSend = {
          "alertID": alertID,
          "eid": eids,
          "sendToRecipients": "False",
          "type": type,
          "question": question
        }
        isAD = false;
      }

      // AD or not this is a followup so it should shend by eids... not by recipient or AD
      console.log("toBeSend: ", toBeSend)
      this.form.sendNotification(toBeSend, isAD, false, isMultipleUsers).subscribe(
        data => { observer.next(data) },
        error => { observer.error(error) }
      );
    })
  }

  cancel() {
    let r = {
      status: 'cancel'
    }
    this.dialogRef.close(r);
  }

  setQAData() {
    const entities = Entities.XmlEntities
    let data = this.form.data.transaction.followup
    let ctr = 1;
    let qaData = {}
    let newProp;
    for (let prop in data) {
      // console.log("PROP", prop)
      if (prop.includes('Answer')) {
        newProp = 'Answer' + ctr
        qaData[newProp] = data[prop]
        ctr++
      }
      if (prop.includes('Question')) {
        var question = data[prop].replace(/(<p>|<\/p>)/g, "")
        question = question
        console.log('ENTITIES QUESTION', question)
        qaData[prop] = question;
      }
    }
    console.log("QADATA", qaData)
    return qaData;
  }

  onFileChange(ev) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    this.buttonFileText = "Uploading in progress..."
    this.disableSend = true;

    if(file){
      if (file.type == "application/vnd.ms-excel" || file.type == "text/csv") {
        this.csvError = ''
        reader.onload = (event) => {
          console.log(new Date());

          let data = String(reader.result)

          let eids = '';
          data.split(/\r?\n/).forEach(eid => { if (eid != '' && eid != undefined) eids = eids == '' ? eid.replace(/[^ -~]+/g, "") : eids += "; " + eid.trim().replace(/[^ -~]+/g, "") })

          if (eids == '') {
            this.csvError = "No EIDs found.";
            this.buttonFileText = "Browse file";
            this.btnUpdate = false;
            this.isLoadingCSV = false;
            this.disableSend = false;
          } else {
            //Validate and exclude non-CDP users and eid with different format
            this.isLoadingCSV = true;
            eids = this.filterEIDs(eids);

            this.retValCSV = {
              status: 'done',
              fileName: file.name,
              eids: eids
            }
            this.btnUpdate = true;
            // Validate CDP users
            this.checkCDPUsers(eids);

          }
        }
        reader.readAsBinaryString(file);
      } else {
        this.csvError = 'Only csv file is allowed for upload. Try again.'
        this.buttonFileText = "Browse file"
        this.btnUpdate = false;
        this.isLoadingCSV = false;
        this.disableSend = false;
      }
    }else{
      this.buttonFileText = 'Browse file';
      this.btnUpdate = false;
      this.isLoadingCSV = false;
      this.disableSend = false;
    }

  }

  //Remove spacing of EID list from csv file
  filterEIDs(eids){
    eids = eids.toLowerCase().replace(/\s/g, "").split(';');
    let filtered = eids.filter(function (a) {
            if (!this.has(a)) {
                this.set(a, true);
                return true;
            }else{
              return false;
            }
        }, new Map);
    console.log(filtered);

    if (!filtered[filtered.length -1]) {
      filtered.splice(filtered.length -1, 1)
    }
    eids = filtered.join(';');
    return eids ;
  }

  // Check CDP User
  checkCDPUsers(eids) {
    let eidlist;
    eidlist = eids;
    if (eidlist) {

      let formatedArray = this.removeAccenture(eidlist);
      let formated = {
        eids: formatedArray.stringFormat
      }
      if(this.errorDomain.length > 0){
        let text = 'The following email addresses will not receive this notification because  <br>they do not have the Always Safe Mobile application installed <br>on their device or there is a spelling error.';
        this.continueCompare(eidlist, text, formatedArray, false)
      }
      else if(formatedArray.arrayFormat.length > 0){
        this.form.checkCDPUsers(formated).subscribe(data => {
          if (data) {
              let text = 'The following EID(s) will not receive this notification because they are not CDP users.'
              this.continueCompare(eidlist, text, formatedArray, true)
          }
        });
      } else {
        let text = 'The following email addresses will not receive this notification because  <br>they do not have the Always Safe Mobile application installed <br>on their device or there is a spelling error.';
        this.continueCompare(eidlist, text, formatedArray, true)
      }
    }
  }

  removeInvalidEid(eidList, formatted, isrecepient, invalid, isFile) {
    if (invalid.length > 0) {
      console.log('formatted', formatted)
      console.log('inValidRecipients', invalid)
      let recipientsEID

      if(isFile){
        recipientsEID = eidList.replace(/\s/g, '').split(';');

        invalid.forEach(eid => {
          let index = recipientsEID.indexOf(eid)
          if (index > -1) {
            recipientsEID.splice(index, 1);
          }
        });
      }

      if(isrecepient){
        this.retValCSV.eids = this.stringFormat(recipientsEID);
        console.log('formatted final', recipientsEID)
      }

      return recipientsEID.length;
    }
  }

  stringFormat(recipients) {
    let retVal = '';

    recipients.forEach(eid => {
      retVal = retVal == '' ? eid : retVal += '; ' + eid
    });

    return retVal;
  }

  removeAccenture(recipients) {
    this.errorDomain = []
    let rArray = recipients.split(';');

    let retVal = {
      stringFormat: '',
      arrayFormat: []
    };
    rArray.forEach(element => {
      if(element.indexOf('@') != -1){
        let value = element.split('@')[0].trim().toLowerCase();
        let domain = element.split('@')[1].trim().toLowerCase();
        if(element.split('@').length == 2){
          if(domain && domain == "accenture.com"){
            if (value) {
              retVal.stringFormat = retVal.stringFormat == '' ? retVal.stringFormat + value : retVal.stringFormat + ',' + value
              retVal.arrayFormat.push(value);
            } else {
              this.errorDomain.push(element)
            }
          } else {
            this.errorDomain.push(element)
          }
        }else{
          this.errorDomain.push(element)
        }
      } else {
        this.errorDomain.push(element)
      }
    });

    retVal.arrayFormat = Array.from(new Set(retVal.arrayFormat));
    this.form.data.temporary = this.errorDomain
    return retVal;
  }

  continueCompare(eidlist, text,formatedArray, isCDP) {

    if (this.form.data.temporary.length > 0) {
      this.form.data.compare = { text: text }
      let count = 0;
      count = this.removeInvalidEid(eidlist, formatedArray.arrayFormat, true, this.form.data.temporary, true);

      if (this.form.data.temporary.length > 0) {
        this.compareDialog = this.dialog.open(CompareComponent, {
          disableClose: true,
          data: {
            buttonText: {
              cancel: 'Cancel',
            },
          },
        });
        this.compareDialog.afterClosed().subscribe(closed => {
          console.log(closed);
          if (count == 0) {
            this.csvError = 'No EIDs found.'
            this.buttonFileText = "Browse file"
            this.btnUpdate = false;
            this.isLoadingCSV = false;
            this.disableSend = false;
          }else{
            this.buttonFileText = this.retValCSV.fileName;
            this.isLoadingCSV = false;
            this.disableSend = false;
          }

          if (!isCDP && count > 0) {
            this.buttonFileText = "Uploading in progress...";
            this.isLoadingCSV = true;
            this.disableSend = true;
            this.errorDomain = [];
            this.checkCDPUsers(this.retValCSV.eids)
          }

        })

      } else {
        // reset eid  and file value
        if (count == 0) {
          this.csvError = 'No EIDs found.'
          this.buttonFileText = "Browse file"
          this.btnUpdate = false;
          this.isLoadingCSV = false;
          this.disableSend = false;
        }else{
          this.buttonFileText = this.retValCSV.fileName;
          this.isLoadingCSV = false;
          this.disableSend = false;
        }
      }
    } else {
      this.buttonFileText = this.retValCSV.fileName;
      this.isLoadingCSV = false;
      this.disableSend = false;
    }
  }

  //When uploading CSV sepreate new to existing recipients
  public separateExisting(uploadedRecipient, allRecipients){
    let newUsers = []
    this.form.data.transaction.existingRecipients = []
    this.form.data.transaction.newRecipients = []
    this.form.data.transaction.newRecipients = uploadedRecipient.split(';')
    this.form.data.transaction.newRecipients.forEach(element => {
      if(allRecipients.includes(element))
        this.form.data.transaction.existingRecipients.push(element)
      else
        newUsers.push(element)
    });
    this.form.data.transaction.newRecipients = newUsers
  }

}
