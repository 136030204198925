<div class="container" [formGroup]="createTransaction" aria-modal="true" role="dialog" id="dialog1" aria-labelledby="dialog1_label">
    <div class="header">
        <div class="header-row">
            <div class="header-field">
                <div class="bold-font input-text" id="dialog1_label">
                    RECIPIENTS
                </div>
                <div class="input-field select">
                    <mat-form-field appearance="outline" aria-label="answer-list-box">
                        <mat-select formControlName="trecipient" #myElement placeholder="Choose Answer" [(ngModel)]="form.data.transaction.followup.trecipient"
                        (selectionChange)="onChangeRecipient($event.value)" aria-busy="true" required>

                            <mat-option *ngFor="let recipient of  listRecipient" [value]="recipient">
                                {{recipient.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="submitted && f['trecipient'].errors" class="invalid recipient">
                <div *ngIf="f['trecipient'].errors['required']">{{tRecipient}}</div>
            </div>
            <div *ngIf="isSelectRec || isUploadCSV" class="precondition-br">
                <hr class="">
            </div>
            <div *ngIf="isSelectRec" class="bold-font precondition-top">
                PRE-CONDITION
            </div>
            <div *ngIf="isSelectRec" class="header-field">
                <div class="bold-font input-text">
                    QUESTION
                </div>
                <!-- <div class="input-field">
                    <mat-form-field appearance="outline" MatFormFieldControl>
                        <input matInput placeholder="Question" (change)="onChangeQ($event.target.value)"
                            (onContentChanged)="onChangeQ($event.target.value)"
                            [(ngModel)]="form.data.transaction.followup.fquestion" [min]="1" required
                            [max]="maxQuestion" formControlName="fquestion" [readonly]="true">
                    </mat-form-field>
                </div> -->
                <div class="input-field select">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="fquestion" #myElement placeholder="Choose Question"
                            [(ngModel)]="form.data.transaction.followup.fquestion" (selectionChange)="onChangeQ($event.value)" aria-busy="true" required>

                            <mat-option *ngFor="let question of  listQuestion" [value]="question">
                                {{question.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="submitted && f['fquestion'].errors" class="invalid question">
                <div *ngIf="f['fquestion'].errors['required']">{{questionText}}</div>
            </div>
            <div *ngIf="isSelectRec" class="header-field">
                <div class="bold-font input-text">
                    ANSWER
                </div>
                <div class="input-field select">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="fanswer" #myElement placeholder="Choose Answer"
                            [(ngModel)]="form.data.transaction.followup.fans" (selectionChange)="onChangeA($event.value)" aria-busy="true" required>

                            <mat-option *ngFor="let response of  listAnswer" [value]="response">
                                {{response.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="invalid answer" *ngIf="isFollowUpLimit">
                <div class="ng-star-inserted">Maximum number of follow-up questions reached.</div>
            </div>
            <div *ngIf="submitted && f['fanswer'].errors" class="invalid answer">
                <div *ngIf="f['fanswer'].errors['required'] && isSelectRec">{{ansText}}</div>
            </div>
            <div *ngIf="isUploadCSV">
                <div class="bold-font upload-csv-top">
                    UPLOAD CSV
                </div>
                <div class="header-field">
                    <div class="bold-font upload-csv-input-text">
                        PLEASE CHOOSE THE FILE TO UPLOAD
                    </div>
                    <div class="file-button upload-csv-browse" tabindex="0">
                        <label for="fileUpload" class="fileUpload" [class.fileUpload-error]="csvError" >
                            <span *ngIf="!btnUpdate" class="material-icons" matPrefix>attach_file</span>
                            <span [class.btnText]="btnUpdate"> {{buttonFileText}} </span>
                            <mat-spinner [diameter]="20" *ngIf="isLoadingCSV"></mat-spinner>
                        </label>
                        <input #fileUpload id="fileUpload" accept=".csv" type="file" class="fileUpload-hidden" (change)="onFileChange($event)"/>
                    </div>
                </div>
                <div class="csv-error-field">
                    <div class="csv-error-whitespace"></div>
                    <div *ngIf="csvError != ''" class="csv-error-text"> {{csvError}} </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="form-data">
        <div class="form-data-field">
            <div class='text-desc'>
                <div class="form-label">
                    <div class=" bold-font">QUESTION #{{maxQuestion+1}}</div>
                    <span class="charCount">Character: {{ questionCounter }} / 200</span>
                </div>
                <div tabindex="0"></div>
                <div class="text-editor emergingDesc" #reference>
                    <quill-editor formControlName="question" class="qeditor" [modules]="question_detail"
                        MatFormFieldControl [(ngModel)]="form.data.transaction.followup.Question"
                        [styles]="questionStyle" (onContentChanged)="textChanged($event,'question')"
                        [ngClass]="{'error': f['question']['status']=='INVALID'}"
                        [class.errorChecker]="errorChecker"> </quill-editor>
                </div>
                <div *ngIf="submitted && f['question'].errors" class="invalid">
                    <div *ngIf="f['question'].errors['required'] && isSelectRec">Follow-up question is required</div>
                    <div *ngIf="f['question'].errors['required'] && !isSelectRec">Question is required</div>
                </div>

                <div class="quesiton-buttons">
                    <div class="question-title bold-font">RESPONSE OPTIONS</div>
                    <div class="responseContainer">
                        <div class="btnRows" formArrayName="btnRows">


                            <div class="question-input"
                                *ngFor="let itemrow of createTransaction.controls['btnRows']['controls'];let i= index;">

                                <mat-form-field appearance="outline" [formGroupName]="i" MatFormFieldControl
                                    style="width: 93%; padding-right: 5px">
                                    <input matInput [(ngModel)]="form.data.transaction.followup['Answer'+i]" required
                                        maxlength="75" formControlName="{{'Answer'+i}}" aria-label="response-option">
                                </mat-form-field>

                                <div class="question-btn">
                                    <button *ngIf="createTransaction.controls['btnRows']['controls'].length > 2 && createTransaction.controls['btnRows']['controls'].length == i + 1 "
                                        (click)="reappend(i)" mat-mini-fab color="accent" aria-label="Remove input"
                                        class="removeBtn">
                                        <mat-icon> remove </mat-icon>
                                    </button>
                                </div>
                                <div *ngIf="submitted && f['btnRows']['controls'][i]['controls']['Answer'+i].errors"
                                    class="invalid">
                                    <div *ngIf="f['btnRows']['controls'][i]['controls']['Answer'+i].errors['required'] && isSelectRec">
                                        Follow-up response option {{i+1}} is required
                                    </div>
                                    <div *ngIf="f['btnRows']['controls'][i]['controls']['Answer'+i].errors['required'] && !isSelectRec">
                                        Response option {{i+1}} is required
                                    </div>
                                </div>

                            </div>


                        </div>



                    </div>

                    <div class="btnAdd" *ngIf="addReponses">
                        <button (click)="append(currentId + 1)" mat-mini-fab color="primary" aria-label="Add input"
                            class="addBtn">
                            <mat-icon> add</mat-icon>
                    </button>
                    </div>


                </div>

            </div>

        </div>
    </div>
    <div class="action-button" *ngIf="true">
        <button mat-button class="send" [ngClass]="disableSendSR ? 'submit-disable' : 'send'" (click)="send()" [disabled]="disableSend">
            {{textSend}}
        </button>
        <button mat-button class="cancel" (click)="cancel()">
            CANCEL
        </button>

    </div>
</div>
