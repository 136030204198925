import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoadingComponent } from './loading/loading.component';
import { MaterialModule } from 'src/app/material.module';
import { ResolveComponent } from './resolve/resolve.component';
import { ExpirationComponent } from './expiration/expiration.component';
import { DeleteComponent } from './delete/delete.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule
    ],
    declarations: [LoadingComponent, ResolveComponent, ExpirationComponent, DeleteComponent],
    exports: [LoadingComponent, ResolveComponent, ExpirationComponent],
})
export class ComponentsModule { }
