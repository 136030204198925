<app-nav></app-nav>
<a class="skip-main" href="#main">Skip to main content</a>
<div class="page-height">
    <div class="term-wrapper">
        <div class="term-button">

            <button mat-raised-button class="bg-purple" (click)="backClicked()" >
                <mat-icon aria-hidden="false" aria-label="Example home icon">
                    keyboard_backspace
                </mat-icon>
               Go Back
            </button>

        </div>
        <div class="term-container"  id="main" tabindex="-1">
            <h2 class="semibold-font"><i> Terms of Use </i></h2>

            <p>
                Your access to and use of this site is subject to the following terms and conditions, Accenture’s Code of Business Ethics, Accenture Policies, and all applicable laws. By accessing and using this site, you accept the following terms and conditions, without limitation or qualification.  </p>
            <p>
                The information provided on this site is free of charge and for informational purposes and internal use only. Unless otherwise stated, the contents of this site including, but not limited to, the text and images contained herein and their arrangement are the property of Accenture. All trademarks used or referred to in this website are the property of their respective owners. </p>
            <p>
                Nothing contained in this site shall be construed as conferring by implication, estoppel, or otherwise, any license or right to any copyright, patent, trademark or other proprietary interest of Accenture or any third party.</p>
            <p>
                Links on this site may lead to services or sites not operated by Accenture. When you click on a link to a third party site you are subject to the Terms of Use of that site and not of this Accenture site.  Accordingly, Accenture takes no responsibility for other sites or related services.  </p>
            <p>
                This site and its contents are provided “as is” and Accenture makes no representation or warranty of any kind with respect to this site or any site or service accessible through this site. Accenture expressly disclaims all express and implied warranties including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. In no event will Accenture be liable to any party for any direct, indirect, incidental, special, exemplary, consequential, or other damages (including, but not limited to, lost profits, business interruption, loss of programs or data) without regard to the form of action and whether in contract, tort, negligence, strict liability, or otherwise, arising out of or in connection with this site, any content on or accessed through this site or any site service linked to, or any copying, displaying, or use thereof. </p>
            <p>
                This system is the property of Accenture and is to be used in accordance with applicable Accenture Policies.
                Unauthorized access or activity is a violation of Accenture Policies and may be a violation of law.
                Use of this system constitutes consent to monitoring for unauthorized use, in accordance with the Accenture Policies, local laws, and regulations.
                Unauthorized use may result in penalties including, but not limited to, reprimand, dismissal, financial penalties, and
                legal action. Policies with particular relevance include
                <a href="https://policies.accenture.com/policy/0057">Policy 57 – Acceptable Use of Information,
                Devices, and Technology </a>, and <a href="https://policies.accenture.com/policy/0069">Policy 69 – Confidentiality</a>. </p>
            <p>
                Accenture maintains this site in the United States.  You agree that these terms of use and any legal action or proceeding relating to this site shall be governed by the laws of the State of Illinois without reference to its choice of law rules. If you attempt to bring any legal proceedings against Accenture, you specifically acknowledge that Accenture is free to choose the jurisdiction of our preference as to where such action against us may be held. As you have agreed by using this site to choose the laws of the State of Illinois to govern any such proceedings, we will probably choose to defend any such action in Illinois and we can make this decision entirely as it suits us, without regard to where in the world you are located, or from where in the world you visited this site. </p>
            <p>
                You are responsible for complying with the laws of the jurisdiction from which you are accessing this site and you agree that you will not access or use the information on this site in violation of such laws. You represent that you have the lawful right to submit information and agree that you will not submit any information unless you are legally entitled to do so.  </p>

        </div>

    </div>
    <app-footer></app-footer>
</div>


