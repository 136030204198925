<table class="transaction-table" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <ng-container matColumnDef="title">
    <th tabindex="0" aria-label="Title" mat-header-cell *matHeaderCellDef> Title </th>
    <td tabindex="0" aria-labelledby="element.title" id=""  mat-cell *matCellDef="let element;" class="transaction-cell"> {{element.title}} </td>
  </ng-container>

  <ng-container matColumnDef="alertID">
    <th tabindex="0" aria-label="Transaction ID" class = "transaction-th" mat-header-cell *matHeaderCellDef> Transaction ID</th>
    <td tabindex="0" aria-labelledby="element.alertID" id="" mat-cell *matCellDef="let element" class ="pencil-img" (click)="select(element)">{{element.alertID}}</td>
  </ng-container>

  <ng-container matColumnDef="etStatus">
    <th tabindex="0" aria-label="Status" mat-header-cell *matHeaderCellDef>Status </th>
    <td tabindex="0" aria-labelledby="element.etStatus" id="alertStatus" mat-cell *matCellDef="let element">{{element.etStatus}}</td>
  </ng-container>

  <ng-container matColumnDef="publishedDate" >
    <th tabindex="0" aria-label="Published Date" mat-header-cell *matHeaderCellDef>
      Published Date
    </th>
    <td  tabindex="0" aria-labelledby="element.publishedDate" mat-cell *matCellDef="let element" class="dateCell">
      <div id=""> {{element.publishedDate ? (element.publishedDate |date:'M/d/yyyy') : '- - -'}} </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="respond/view">
    <th tabindex="0" aria-label="respond/view" mat-header-cell *matHeaderCellDef>Respond/View</th>
    <td tabindex="0" aria-labelledby="element.respond/view" id="" class="transaction-cell" mat-cell *matCellDef="let element">
      <button class="submit-btn bg-blue" *ngIf="element.etStatus != 'Responded' " (click)="select(element)">Respond</button>
      <button class="submit-btn bg-blue" *ngIf="element.etStatus == 'Responded' " (click)="select(element)">View</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"   ></tr>
</table>
