import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { QuillModule } from 'ngx-quill';

import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ToastrModule } from 'ngx-toastr';

import { HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppComponent } from './app.component';
import { LoginComponent } from './view/login/login.component';
import { CreateComponent } from './admin/create/create.component';
import { NavComponent } from './admin/nav/nav.component';
import { TransactionComponent } from './admin/transaction/transaction.component';
import { FooterComponent } from './admin/footer/footer.component';
import { PreviewComponent } from './admin/preview/preview.component';
import { DialogComponent } from './admin/dialog/dialog.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerifyComponent } from './admin/dialog/verify/verify.component';
import { PublishComponent } from './admin/dialog/publish/publish.component';
import { SuccessComponent } from './admin/dialog/success/success.component';
import { EmergingthreatComponent } from './admin/create/emergingthreat/emergingthreat.component';
import { PublishedTableComponent } from './admin/transaction/table/table.component';
import { ViewTransactionComponent } from './admin/view-transaction/view-transaction.component';
import { FileDialogComponent } from './admin/create/file-dialog/file-dialog.component';
import { DeliverydetailComponent } from './admin/view-transaction/deliverydetail/deliverydetail.component';
import { EmergingquestionsComponent } from './admin/view-transaction/emergingquestions/emergingquestions.component';
import { ComponentsModule } from './admin/sharedComponent/component.module';
import { FormfocusDirective } from './admin/sharedComponent/formfocus.directive';
import {FollowupQuestionComponent} from './admin/view-transaction/followup-question/followup-question.component';
import { CompareComponent } from './admin/create/compare/compare.component'
import { TermsandconditionComponent } from './admin/footer/termsandcondition/termsandcondition.component';
import { ReminderQuestionComponent } from './admin/view-transaction/reminder-question/reminder-question.component';
import { EtComComponent } from './admin/et-com/et-com.component';
import { EtTableComponent } from './admin/et-com/et-table/et-table.component';
import { UnauthorizedComponent } from './admin/unauthorized/unauthorized.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { EtQuestionsComponent } from './admin/et-questions/et-questions.component';
import { MatDividerModule } from '@angular/material/divider';
import {MatPaginatorModule} from '@angular/material/paginator';
import { ReminderFileDialogComponent } from './admin/view-transaction/reminder-question/reminder-file-dialog/reminder-file-dialog.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, ProtectedResourceScopes, MsalRedirectComponent } from '@azure/msal-angular';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.eso.Config.clientId,
      authority: environment.eso.Config.authorizationURL,
      redirectUri: environment.eso.Config.callback,
      postLogoutRedirectUri: environment.eso.Config.logoutUrl,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://alwayssafemobileapp-api.ciodev.accenture', [environment.eso.Config.scope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.eso.Config.scope]
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CreateComponent,
    NavComponent,
    TransactionComponent,
    FooterComponent,
    PreviewComponent,
    DialogComponent,
    VerifyComponent,
    PublishComponent,
    SuccessComponent,
    EmergingthreatComponent,
    PublishedTableComponent,
    ViewTransactionComponent,
    FileDialogComponent,
    EmergingquestionsComponent,
    DeliverydetailComponent,
    FormfocusDirective,
    FollowupQuestionComponent,
    CompareComponent,
    TermsandconditionComponent,
    ReminderQuestionComponent,
    EtComComponent,
    EtTableComponent,
    UnauthorizedComponent,
    ReportsComponent,
    EtQuestionsComponent,
    ReminderFileDialogComponent
  ],
  imports: [
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
    QuillModule.forRoot(),
    ToastrModule.forRoot(),
    MatDividerModule,
    MatPaginatorModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
