<div class="form-data " [formGroup]="createTransaction">

    <div class="form-data-field"  >
        <div class='text-desc'>
            <div class="form-label" style="padding-bottom: 20px;">
                <div class=" bold-font">EMERGING THREAT QUESTION</div>
            </div>
            <div class="form-label">
                <div class=" bold-font">QUESTION #1</div>
                <span class="charCount" >Character: {{ questionCounter }} / 200</span>
            </div>

            <div class="text-editor emergingDesc" #quillEditorAns>
                <quill-editor formControlName="question"
                [modules]="question_detail"
                [styles]="questionStyle"
                [(ngModel)] ="form.data.transaction.emerging.Question"
                (onContentChanged)="textChanged($event,'question')"
                [class.errorChecker]="errorChecker"
                > </quill-editor>
                <!-- remove required -->
            </div>

            <div *ngIf="submitted && f['question'].errors" class="invalid">
                <div *ngIf="f['question'].errors['required']">Question #1 is required</div>
            </div>
            <div class="quesiton-buttons">
                <div class="question-title bold-font">RESPONSE OPTIONS</div>
                <div class="responseContainer">
                    <div class="btnRows" formArrayName="btnRows">


                        <div class="question-input"  *ngFor="let itemrow of createTransaction.controls['btnRows']['controls'];let i= index;" >

                            <mat-form-field appearance="outline" [formGroupName]="i" MatFormFieldControl style="width: 95%;">
                                <label for="{{'tAns'+i}}" class="sr-only">Response Options</label>
                                <input matInput  [(ngModel)] ="form.data.transaction.emerging['Answer'+i]"  maxlength="75" formControlName="{{'emergingAnswer'+i}}" id="{{'tAns'+i}}">
                            </mat-form-field>

                            <div class="question-btn" >
                                <a  *ngIf="createTransaction.controls['btnRows']['controls'].length > 2 && createTransaction.controls['btnRows']['controls'].length == i + 1 " (click)="reappend(i)" mat-mini-fab color="accent" aria-label="Remove input" class ="removeBtn" >
                                    <mat-icon> remove </mat-icon>
                                </a>
                            </div>
                            <div *ngIf="submitted && f['btnRows']['controls'][i]['controls']['emergingAnswer'+i].errors" class="invalid" >
                                <div *ngIf="f['btnRows']['controls'][i]['controls']['emergingAnswer'+i].errors['required']">Response option {{i+1}} is required</div>
                            </div>

                        </div>


                    </div>



                </div>


                <a  (click)="append(currentId + 1)" *ngIf="addResponses" mat-mini-fab color="primary" aria-label="Add input" class="addBtn">
                    <mat-icon> add</mat-icon>
                </a>

            </div>

        </div>

    </div>
</div>
