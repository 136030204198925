import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { FormService } from 'src/app/core/form.service';
import { Router } from '@angular/router';
import { LoadingComponent } from '../sharedComponent/loading/loading.component';
import { Config } from 'src/app/core/form.config';
import { Observable, observable } from 'rxjs';


@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {


  hasQID = undefined
  allMobileUsers: string = "";
  tobeSave: any = {};
  isUnauthorized: boolean = false;

  constructor(public dialog: MatDialog,
    public form: FormService,
    public router: Router
  ) {


  }

  public dialogRef: any;

  ngOnInit(): void {
    console.log('form.data.transaction', this.form.data.transaction);
    this.isUnauthorized = true;
    if(this.form.data.user.isSuperAdmin == false && this.form.data.user.isStandardAdmin == false){
      if(this.form.data.isCDP){
        // this.router.navigateByUrl('/emergingthreatresponse');
      }else{
        //redirect to unauthorized page
        this.router.navigateByUrl('/unauthorized');
      }
    }else{
    this.isUnauthorized = false;
    if (this.form.data.transaction == undefined) {
      this.router.navigateByUrl('/create');
    }else{
       // console.log("edsel", this.form.data.transaction.emerging.QID)
      const loading = this.dialog.open(LoadingComponent, { panelClass: 'custom-dialog'});

      if (this.form.data.transaction.ragStatus == 'Emerging Threat') this.setQAData();


      if (this.form.data.transaction.alertID) {
        this.checkQID(this.form.data.transaction.alertID).subscribe(x => {
          if(this.form.data.transaction.recipients.option == 'ALL'){
            this.allMobileUsers = "";
            loading.close();
            this.processAllMobileUsers(0);
          }else{
            console.log("init has qid", this.hasQID, x)
            setTimeout(() => {
              loading.close();
            }, 1000)
          }
        })
      } else if(this.form.data.transaction.recipients.option == 'ALL'){
        this.allMobileUsers = "";
        loading.close();
        this.processAllMobileUsers(0);
      } else {
        setTimeout(() => {
          loading.close();
        }, 1000)
      }
    }
  }
  }

  public submitted = false;
  answers = [];

  // public get published() {
  //   let retVal;
  //   retVal = this.form.data.transaction.published ? this.form.data.transaction.published : false;
  //   return retVal;
  // }

  public get recipientsCount() {
    let retVal = 0
    if (this.form.data.transaction.recipients.option == 'eids') {
      retVal = this.form.data.transaction.recipients.eid.split(';').length;
    }else if(this.form.data.transaction.recipients.option == 'file') {
      retVal = this.form.data.transaction.recipients.fileValue.split(';').length;
    }else if(this.form.data.transaction.recipients.option == 'ALL'){
      retVal = this.allMobileUsers.split(';').length - 1;
      console.log(this.allMobileUsers, retVal)
    }else if(this.form.data.transaction.recipients.option == 'ad'){
      retVal = this.form.data.transaction.recipients.ad.split(';').length;;
    }
    // else if(this.form.data.transaction.recipients.option == 'eidsmishing'){
    //   retVal = this.form.data.transaction.recipients.eidsmishing.split(';').length;
    // }else if(this.form.data.transaction.recipients.option == 'fileNumbers'){
    //   retVal = this.form.data.transaction.recipients.fileValue.split(';').length;
    // }
    return retVal.toLocaleString('en',{useGrouping:true});
  }

  // public get reviewersCount() {
  //   let retVal = 0
  //   retVal = this.form.data.transaction.reviewers.split(',').length;
  //   return retVal;
  // }

  // public get actionButtonText(){
  //   let retVal = 'SAVE';
  //   if(this.form.data.transaction.alertID){
  //     retVal = 'UPDATE';
  //   }
  //   return retVal;
  // }

  processAllMobileUsers(startItem){
    const loading = this.dialog.open(LoadingComponent, { panelClass: 'custom-dialog'});
    this.form.getTransactionAllMobileUsers(startItem).subscribe((res: any) => {
      for(let i=0; i < res.users.length; i++){
        this.allMobileUsers += res.users[i] + ";";
      }
      if(res.returnCount == res.totalCount - res.startItem){
        //end API call
        setTimeout(() => {
          loading.close();
        }, 1000)
      }else{
        //retrigger API for remaining users
        this.processAllMobileUsers(res.startItem + res.maxItems);
        loading.close();
      }
    });
  }

  clickMyTransaction() {
    this.router.navigateByUrl('/transaction');
  }

  save() {
    if (!this.submitted) {
      this.submitted = true;
      this.form.user.loadUser().subscribe((user: any) => {
        console.log("USERSSS", user)

        if (this.form.data.transaction.alertID) {
          if(this.form.data.transaction.recipients.option == 'ad'){
            this.form.data.transaction.isAD = true;
          }
          this.updateTransaction(this.form.data.transaction.alertID, user);
        } else {
          this.form.data.origTransaction = {};
          this.saveTransaction(user);
        }
        //this.openDialog(tobeSave.alertID);
      })
    }
  }

  checkQID(alertID) {
    return new Observable((observable) => {
      this.form.checkQID(alertID).subscribe(qid => {
        this.hasQID = qid
        observable.next(qid)
        // if (qid == undefined) {
        //   this.hasQID = qid
        //   observable.next(undefined)
        // }
        // else {
        //   observable.next(qid)
        // }
      })
    })

  }


  updateTransaction(alertID, user) {
    console.log("UPDATINNNNNGGGGGGGG", this.hasQID)
    let numberId = ((~new Date()) / 100);

    numberId = numberId < 0 ? numberId * -1 : numberId;

    //let checkQID = this.checkQID(alertID)

    //console.log("checkQID from prev", checkQID)

    // let qId = this.form.data.transaction.emerging.QID == undefined ? alertID + "|" + new String(Math.floor(numberId)) : this.form.data.transaction.emerging.QID
    let qId = this.hasQID == undefined ? alertID + "|" + new String(Math.floor(numberId)) : this.hasQID
    this.tobeSave =
    {
      "alertType": this.form.data.transaction.alertType.type,
      "alertStatus": this.form.data.transaction.alertStatus,
      "category": this.form.data.transaction.categories.type ? this.form.data.transaction.categories.type : "",
      "ragStatus": this.form.data.transaction.ragStatus,
      "shortDescription": this.form.data.transaction.shortDescription,
      "bodyHtml": this.form.data.transaction.bodyHtml,
      // "smsMessage": this.form.data.transaction.smsMessage,
      "recipients": this.form.data.transaction.recipients.option == 'ALL' ? this.form.data.transaction.recipients.option : this.form.data.transaction.recipients.option == 'file' ? this.form.data.transaction.recipients.fileValue : this.form.data.transaction.recipients.option == 'ad' ? this.form.data.transaction.recipients.ad : this.form.data.transaction.recipients.eid,
      "recipientFileName": this.form.data.transaction.recipients.option == 'file' ? this.form.data.transaction.recipients.fileName : '',
      "expiration": this.form.data.transaction.expiration,
      "title": this.form.data.transaction.title,
      "reviewer": this.form.data.transaction.reviewers,
      "isAD": this.form.data.transaction.isAD,
      "publisher": user.eid,
      "modifiedBy": user.eid,
      "modifiedDate": new Date(),
      "QAData": {
        "Id": qId,
        "Details": JSON.stringify(JSON.stringify(this.setQAData()))
      },
      "etStatus": this.form.data.transaction.alertType.type == 'EmergingThreat' ? 'Awaiting Response' : '',
      "hasFollowup": this.form.data.transaction.alertType.type == 'EmergingThreat' ? 'false' : '',
      // "eidsmishing": this.form.data.transaction.recipients.eidsmishing ? this.form.data.transaction.recipients.eidsmishing : ''
    }

    if (this.form.data.transaction.alertType.type != 'EmergingThreat') {
      delete this.tobeSave.QAData
    }

    //const loading = this.dialog.open(LoadingComponent, {});
    console.log("data to update", this.tobeSave)
    this.form.updateTransaction(alertID, this.tobeSave).subscribe((data: any) => {
      //loading.close();
      if (data) {
        if (this.tobeSave.alertStatus == Config.AlertStatus.Verified) {
          this.openDialog(alertID, "FP");
        } else {
          this.openDialog(alertID);
        }
      }
    }, err => {
      //loading.close();
      this.openDialog('', '', true);
    })
  }

  saveTransaction(user) {
    console.log("saving", this.form.data.transaction)
  let numberId = ((~new Date()) / 100);

  let recipient = this.form.data.transaction.recipients.option == 'ALL' ? this.form.data.transaction.recipients.option
                // : this.form.data.transaction.recipients.option == "eidsmishing" ? this.form.data.transaction.recipients.eidsmishing
                // : this.form.data.transaction.recipients.option == "fileNumbers" ? this.form.data.transaction.recipients.fileValue
                : this.form.data.transaction.recipients.option == 'file' ? this.form.data.transaction.recipients.fileValue
                : this.form.data.transaction.recipients.option == 'ad' ? this.form.data.transaction.recipients.ad
                : this.form.data.transaction.recipients.eid
    numberId = numberId < 0 ? numberId * -1 : numberId;

    console.log("recipients: ", recipient)

    let alertID = new String(Math.floor(numberId))
    this.tobeSave =
    {
      "alertID": alertID,
      "alertType": this.form.data.transaction.alertType.type,
      "alertStatus": 'Draft',
      "category": this.form.data.transaction.categories.type ? this.form.data.transaction.categories.type : "",
      "ragStatus": this.form.data.transaction.ragStatus,
      "shortDescription": this.form.data.transaction.shortDescription,
      "bodyHtml": this.form.data.transaction.bodyHtml,
      // "smsMessage": this.form.data.transaction.smsMessage,
      "recipients": recipient,
      "recipientFileName": this.form.data.transaction.recipients.option == 'file' ? this.form.data.transaction.recipients.fileName :  '',
      // "fileNumbers": this.form.data.transaction.recipients.option == "fileNumbers" ? this.form.data.transaction.recipients.fileNumbers : '',
      "expiration": this.form.data.transaction.expiration,
      "title": this.form.data.transaction.title,
      "reviewer": this.form.data.transaction.reviewers,
      "isAD": this.form.data.transaction.isAD,
      "publisher": user.eid,
      "createdBy": user.eid,
      "createdDate": new Date(),
      "modifiedBy": user.eid,
      "recipientCount": recipient.split(";").length,
      "modifiedDate": new Date(),
      "QAData": {
        "Id": alertID + "|" + new String(Math.floor(numberId)),
        "Details": JSON.stringify(JSON.stringify(this.setQAData())),
        "No" : 1,
        "createdDate": new Date(),
      },
      "etStatus": this.form.data.transaction.alertType.type == 'EmergingThreat' ? 'Awaiting Response' : '',
      "hasFollowup": this.form.data.transaction.alertType.type == 'EmergingThreat' ? 'false' : '',
      // "eidsmishing": this.form.data.transaction.recipients.eidsmishing ? this.form.data.transaction.recipients.eidsmishing : ''
    }
    // let toBeSend = {
    //   "alertID": tobeSave.alertID,
    //   "eid": [this.form.data.transaction.reviewers.split(',')]
    // }
    if (this.form.data.transaction.alertType.type != 'EmergingThreat') {
      delete this.tobeSave.QAData
    }
    console.log("api DATA", this.tobeSave);

    //const loading = this.dialog.open(LoadingComponent, {});

    this.form.saveTransaction(this.tobeSave).subscribe((data: any) => {
      this.form.data.transaction.alertID = this.tobeSave.alertID;
      //loading.close();
      if (data) {
        this.openDialog(this.tobeSave.alertID);
      }
    }, err => {
      //loading.close();
      this.openDialog('', '', true);
    })
  }

  openDialog(alertID, start = "V", isError = false) {
    this.dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        start: start,
        isError: isError,
        alertID: alertID,
        buttonText: {
          sendVerification: 'Send Verification'
        },
      },
    });
  }

  back() {
    //if (this.form.data.transaction.alertStatus != Config.AlertStatus.Verified && this.form.data.transaction.alertStatus != Config.AlertStatus.Published) {
    this.router.navigateByUrl('/create');
    //}
  }

  setQAData() {
    if (this.form.data.transaction.alertType.type == 'EmergingThreat') {
      let data = this.form.data.transaction.emerging
      let ctr = 1;
      let qaData = {}
      let newProp;
      for (let prop in data) {
        console.log("PROP", prop)
        if (prop.includes('Answer')) {
          newProp = 'Answer' + ctr
          qaData[newProp] = data[prop]
          ctr++
        }
        if (prop.includes('Question')) {
          qaData[prop] = data[prop].replace(/(<p>|<\/p>)/g, "");
        }
      }
      console.log("QADATA", qaData)
      return qaData;
    }
    return "";
  }

  downloadFile() {
    this.form.download.downloadCsv(this.form.data.transaction.recipients.fileName, this.form.data.transaction.recipients.fileValue.toLowerCase());
  }

  downloadAllMobileUsersFile() {
    this.form.download.downloadCsv("AllMobileUsers.csv", this.allMobileUsers);
  }

}

