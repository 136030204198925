<app-nav></app-nav>
<div role="region" aria-label="Skip" tabindex="-1">
  <a class="skip-main" href="#main">Skip to main content</a>
</div>
<div class="page-height" id="main">

  <div class="transaction-container">

    <div class="ts-button">

      <a role="region" aria-label="New Transaction" class="as-btn-ts bg-purple" *ngIf="form.data.user.isSuperAdmin || form.data.user.isStandardAdmin" [routerLink]="'/transaction'" tabindex="0">
        <mat-icon aria-hidden="false" aria-label="Example home icon">
            home
        </mat-icon>
        My Transactions
      </a>
    </div>

    <div role="region" aria-label="EmergingThreat Questions" class='transaction-wrapper'>
      <h3 role="region" aria-label="EmergingThreat Questions" tabindex="0">EMERGING THREAT NOTIFICATIONS</h3><br>
    </div>
    <div role="region" aria-label="EmergingThreat Questions List" class="transaction-tab">
      <!-- <mat-tab-group [(selectedIndex)]="selectedIndex" animationDuration="0ms"
                        (selectedTabChange)="onTabClick($event)"> -->
      <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedIndex">
        <mat-tab>
          <ng-template mat-tab-label>
            <span aria-label="EmergingThreat Questions" tabindex="0">
              Emerging Threat Questions <br>
              <p aria-labelledby="ETCount" id="pendingCount" tabindex="0">{{etCount}}</p>
            </span>
          </ng-template>
          <app-et-table
            [displayedColumns] = "PendingColumns"
            [dataSource] = "PendingTransactionSource"
            [index] = "selectedIndex"
            (selected)="selectedRow($event)"
          ></app-et-table>
        </mat-tab>

      </mat-tab-group>
    </div>

  </div>


  <app-footer></app-footer>
</div>
