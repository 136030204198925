<div class="white-box" *ngIf="loading"></div>

<div *ngIf="form.data.extract.reports.length >0">
<h1 class="available-reports">Always Safe Reports</h1>
<mat-grid-list cols="3" rowHeight="354:220" gutterSize="100px" *ngFor="let row of getReports; let i = index">
  <mat-grid-tile *ngFor="let col of row">


    <div class="tile-container">
      <h3>{{col.title}}</h3>
      <p>{{col.description}}</p>
      <div class="download-button"  *ngIf="col.loading == false; else onLoad">
        <a class="as-btn-ts" (click)="generateReport(col.title, form.data.extract.rows.indexOf(row), row.indexOf(col), col.button)">
          <mat-icon *ngIf="col.button == 'Download'">cloud_download</mat-icon>
          <mat-icon *ngIf="col.button == 'Extract File'">table_chart</mat-icon>
          {{col.button}}
        </a>
      </div>
      <ng-template #onLoad>
        <div class="progress progress-striped active download-button"  *ngIf="col.loading == true">
          <div role="progressbar progress-striped" style="width: 100%; border-radius: 0.5vw" class="progress-bar">
            <a class="as-btn-ts">
              <mat-icon *ngIf="col.button == 'Download'">cloud_download</mat-icon>
              <mat-icon *ngIf="col.button == 'Extract File'">table_chart</mat-icon>
              {{col.button}}
            </a>
          </div>
        </div>
      </ng-template>
    </div>

  </mat-grid-tile>
</mat-grid-list>
</div>

<div *ngIf="isCyberSecurity()">
  <mat-divider class="divider"></mat-divider>
  <h1 class="available-reports">IS Virtual Reality Reports</h1>
  <mat-grid-list cols="3" rowHeight="354:220" gutterSize="100px" *ngFor="let row of getISReports; let j = index">
  <mat-grid-tile *ngFor="let col of row">


    <div class="tile-container">
      <h3>{{col.title}}</h3>
      <p>{{col.description}}</p>
      <div class="download-button"  *ngIf="col.loading == false; else onLoad">
        <a class="as-btn-ts" (click)="generateReport(col.title, form.data.extract.rows.indexOf(row), row.indexOf(col), col.button)">
          <mat-icon *ngIf="col.button == 'Download'">cloud_download</mat-icon>
          <mat-icon *ngIf="col.button == 'Extract File'">table_chart</mat-icon>
          {{col.button}}
        </a>
      </div>
      <ng-template #onLoad>
        <div class="progress progress-striped active download-button"  *ngIf="col.loading == true">
          <div role="progressbar progress-striped" style="width: 100%; border-radius: 0.5vw" class="progress-bar">
            <a class="as-btn-ts">
              <mat-icon *ngIf="col.button == 'Download'">cloud_download</mat-icon>
              <mat-icon *ngIf="col.button == 'Extract File'">table_chart</mat-icon>
              {{col.button}}
            </a>
          </div>
        </div>
      </ng-template>
    </div>

  </mat-grid-tile>
  </mat-grid-list> 
</div>

