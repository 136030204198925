import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormService } from 'src/app/core/form.service';
import * as XLSX from 'xlsx';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-file-dialog',
  templateUrl: './file-dialog.component.html',
  styleUrls: ['./file-dialog.component.scss']
})
export class FileDialogComponent implements OnInit {

  constructor(public form: FormService,
    private dialogRef: MatDialogRef<FileDialogComponent>) { }

  ngOnInit(): void {
  }
  public error;
  public buttonFileText = "Browse File"
  public retVal = undefined;
  public btnUpdate = false;

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    this.buttonFileText = "Uploading in progress..."
    
    //this.error = 'File type not supported';
    //if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      // reader.onload = (event) => {
      //   const data = reader.result;
      //   console.log(data);
      //   workBook = XLSX.read(data, { type: 'binary' });
      //   jsonData = workBook.SheetNames.reduce((initial, name) => {
      //     const sheet = workBook.Sheets[name];
      //     initial[name] = XLSX.utils.sheet_to_json(sheet);
      //     return initial;
      //   }, {});
      //   console.log(jsonData);
      //   //this.fileReaded = true;
      //   let eids = '';
      //   for (let prop in jsonData) {
      //     jsonData[prop].forEach(element => {
      //       eids = eids == '' ? element.EID : eids += "; " + element.EID;
      //     });
      //   }

      //   if (eids == '') {
      //     this.error = 'EID format not supported'
      //   } else {
      //     this.buttonFileText = file.name;
      //     this.btnUpdate = true;
      //     this.retVal = {
      //       status: 'done',
      //       fileName: file.name,
      //       eids: eids
      //     }
      //   }
      // }
      // reader.readAsBinaryString(file);
    //} 
    if (file.type == "application/vnd.ms-excel" || file.type == "text/csv") {
      this.error = ''
      reader.onload = (event) => {
        //let data : ArrayBuffer = reader.result;
        console.log(new Date());

        let data = String(reader.result)

        let eids = '';
        data.split(/\r?\n/).forEach(eid => { if (eid != '' && eid != undefined) eids = eids == '' ? eid.replace(/[^ -~]+/g, "") : eids += "; " + eid.trim().replace(/[^ -~]+/g, "") })

        if (eids == '') {
          this.error = 'No EIDs found.'
        } else {
          this.buttonFileText = file.name;
          this.btnUpdate = true;
          this.retVal = {
            status: 'done',
            fileName: file.name,
            eids: eids
          }
        }
      }
      reader.readAsBinaryString(file);
    } else {
      this.error = 'Only csv file is allowed for upload. Try again.'
      this.buttonFileText = "Browse File"
      this.btnUpdate = false;
    }
  }

  close() {
    this.retVal = {
      status: 'close',
      fileName: '',
      eids: ''
    }

    this.dialogRef.close(this.retVal)
  }

  upload(){
    if(this.retVal){

    this.dialogRef.close(this.retVal)
  }

  }
}
