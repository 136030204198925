import { Component, OnInit, Input } from '@angular/core';
import { FormService } from 'src/app/core/form.service';
export interface DeliveryDetails {
  dateSent: Date;
  timeSent: Date;
  recipients: number;
  alertread: number;
}

@Component({
  selector: 'app-deliverydetail',
  templateUrl: './deliverydetail.component.html',
  styleUrls: ['./deliverydetail.component.scss']
})
export class DeliverydetailComponent implements OnInit {
  displayedColumns: string[] = ['dateSent', 'timeSent', 'recipients', 'alertread', 'alertUnread','alertPending'];
  totalReads;
 // dataSource = ELEMENT_DATA;

  @Input() deliveryDetail;
  @Input() totalRecipients

  constructor(public form: FormService) { }

  ngOnInit(): void {
    //this.getReport(this.form.data.transaction.alertID)
    //this.dataSource = this.form.data.Report.delivery
    if(this.form.data.transaction.alertType.type=='AccentureCyberNews' || this.form.data.transaction.alertType.type== 'CyberNewsAroundtheWorld'){
      this.displayedColumns = ['dateSent', 'timeSent', 'recipients', 'alertread', 'alertUnread','alertPending'];
    } 
    // else if(this.form.data.transaction.alertType.type=='SmishingTest'){
    //   this.displayedColumns = ['dateSent', 'timeSent', 'alertPendingSMS'];
    // }
    else{
      this.displayedColumns = ['dateSent', 'timeSent', 'recipients', 'alertread', 'alertUnread'];
    }
    let eids;
    this.form.getTotalReads(this.form.data.transaction.alertID).subscribe(retEids => {
      eids = retEids
      this.form.data.newTotalReads = eids.length;
    })
  }

  // getReport(alertid) {
  //   this.form.getReport(alertid).subscribe(report => {
  //     console.log("fetch data from report", report)
  //     this.dataSource = this.form.data.Report.delivery
  //   })
  // }
  isTotalPending(){
    if(this.form.data.transaction.alertType.type=='AccentureCyberNews' || this.form.data.transaction.alertType.type== 'CyberNewsAroundtheWorld' ){
      return true;
    } else{
      return false;
    } 
  }
  getTotalReads(alertId){
    let eids;
    this.form.getTotalReads(alertId).subscribe(retEids => {
      eids = retEids;

      if(eids.length != 0){
        let row = [];

        eids.forEach(eid => {
          row.push([eid]);
        })

        console.log(row);
        this.form.downloadExcel(row, "ASA-DeliveryDetails-TotalReads").subscribe(result => {
          if (result) {
            console.log("excel downloaded")
          }
        })
      }

    })


  }

  getTotalPending(alertId){
    let eids;
    this.form.getTotalPending(alertId).subscribe(retEids => {
      eids = retEids;

      if(eids.length != 0){
        let row = [];

        eids.forEach(eid => {
          row.push([eid]);
        })

        console.log(row);
        this.form.downloadExcel(row, "ASA-DeliveryDetails-TotalReads").subscribe(result => {
          if (result) {
            console.log("excel downloaded")
          }
        })
      }

    })
  }

  // getTotalPendingSMS(alertId){
  //   let eids;
  //   this.form.getTotalPendingSMS(alertId).subscribe(retEids => {
  //     eids = retEids;

  //     if(eids.length != 0){
  //       let row = [];

  //       eids.forEach(eid => {
  //         row.push([eid]);
  //       })

  //       console.log(row);
  //       this.form.downloadExcel(row, "ASA-DeliveryDetails-TotalReads").subscribe(result => {
  //         if (result) {
  //           console.log("excel downloaded")
  //         }
  //       })
  //     }

  //   })
  // }
}
