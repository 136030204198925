import { Injectable } from '@angular/core';
import { EsoService } from './eso.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandlerService {

  constructor(
    public esoService: EsoService
  ) { }

  public configure(){
    this.esoService.configure();
  }

}
