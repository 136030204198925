<app-nav></app-nav>
<div role="region" aria-label="Skip" tabindex="-1">
  <a class="skip-main" href="#main">Skip to main content</a>
</div>
<div class="page-height" id="main">

  <div class="transactions-container">

    <div class="ts-button">

      <a role="region" aria-label="New Transaction" id="et-btn" class="as-btn-ts bg-bronze"  [routerLink]="'/emergingthreatresponse'" tabindex="0">
        Emerging Threat
      </a>
    </div>

    <div role="region" aria-label="EmergingThreat Questions" class='transactions-wrapper'>
      <h3 role="region" aria-label="EmergingThreat Questions" tabindex="0">EMERGING THREAT RESPONSE</h3>
      <a role="region" aria-label="refresh" class="as-btn-ts" (click)="refresh()" tabindex="0">
        <mat-icon aria-hidden="false" role="img" aria-label="refresh">
            refresh
        </mat-icon>
      </a>
    </div>
   <div class="file-container">
      <div class="ET-container">
        <div class="question-container">
          <h3 class="et-title" role="region" aria-label="EmergingThreat Questions Title">{{data.title}}</h3>
          
          <!-- <div *ngFor="let newData of newComDatas; index as j">
           
            <mat-card class="question-card">
              <img [src]="setChevronImage(newData.isCollapsed)" class="chevron-button" (click)="showAnswers(newData.isCollapsed, j)">

              <h3 role="region" aria-label="Title" id="title" tabindex="0">CDP Response Team</h3>

              <p [innerHTML]="newData.qaItems[0].question"></p>

              <div *ngFor="let qaItems of newData.qaItems; index as k"
                  [ngClass]="qaItems.isAnswered ?   'clientAccount-container-disabled' : 'clientAccount-container'"
                  [hidden]="newData.isCollapsed" >
               <div class="question-header">
                <div tabindex="0" class="question-title">{{qaItems.clientAccount}}</div>
                <span *ngIf="qaItems.isAnswered && !qaItems.isUpdate" class="response-date" tabindex="0">Responded on {{qaItems.responseDate}} </span>
                <span *ngIf="qaItems.isAnswered && qaItems.isUpdate" class="response-date" tabindex="0">Updated on {{qaItems.responseDate}} </span>
               </div>
                <h4 *ngIf="!qaItems.isAnswered">Choose One:</h4>
                <button class="update-btn" *ngIf="qaItems.isAnswered" (click)="update(qaItems)">Update</button>
                <h4 *ngIf="qaItems.isAnswered && qaItems.isSelf">Your Response: </h4>
                <h4 *ngIf="qaItems.isAnswered && !qaItems.isSelf">Responded by: {{qaItems.userEID}}</h4>
                
              <mat-radio-group aria-labelledby="response-radio-group-label"
                  [ngClass]="qaItems.isAnswered ? 'has-answered': 'response-group'"
                  [(ngModel)]="qaItems.answer">

                <mat-radio-button class="response-radio-button"
                value="{{choice}}"
                (change)="onClick($event.value,i,qaItems.clientAccount,qaItems.qid,j,k)"
                (focusout)="tempAnswer = []"
                *ngFor="let choice of qaItems.choices; index as i"
                [ngClass]="qaItems.isAnswered ? qaItems.answer === choice ? 'hasAnswered' : 'cursor-disable' : '' "
                [disabled]="data.alertStatus != 'Published' || qaItems.isAnswered"
                >
                {{choice}}</mat-radio-button>
                <button
                class="submit-btn"
                (mousedown)="submit()"
                *ngIf="!qaItems.isAnswered  "
                [ngClass]="qaItems.answer == 'null' ? 'submit-disable' : '' "
                [disabled]="qaItems.answer == 'null'"
                >
                Submit
                </button>
             </mat-radio-group>

            </div>

            </mat-card>
          </div> -->
          <div *ngFor="let question of withoutRefQID; index as j">
            <mat-expansion-panel aria-labelledby ="question-expansion-panel" [expanded] = "checkExpanded(question.qaItems[0].qid, question.qaItems)">
              <mat-expansion-panel-header>
                <p [innerHTML]="question.qaItems[0].question"></p>
              </mat-expansion-panel-header>
              <div *ngFor="let qaItems of question.qaItems; index as k"
                  [ngClass]="qaItems.isAnswered ?   'clientAccount-container-disabled' : 'clientAccount-container'"
                   >
               <div class="question-header">
                <div tabindex="0" class="question-title">{{qaItems.clientAccount}}</div>
                <span *ngIf="qaItems.isAnswered && !qaItems.isUpdate" class="response-date" tabindex="0">Responded on {{qaItems.responseDate}} </span>
                <span *ngIf="qaItems.isAnswered && qaItems.isUpdate" class="response-date" tabindex="0">Updated on {{qaItems.responseDate}} </span>
               </div>
                <h4 *ngIf="!qaItems.isAnswered">Choose One:</h4>
                <button class="update-btn" *ngIf="qaItems.isAnswered" (click)="update(qaItems)">Update</button>
                <h4 *ngIf="qaItems.isAnswered && qaItems.isSelf">Your Response: </h4>
                <h4 *ngIf="qaItems.isAnswered && !qaItems.isSelf">Responded by: {{qaItems.userEID}}</h4>
                <!-- <h4 *ngIf="!qaItems.isRequired && !qaItems.isAnswered">Awaiting response from: {{qaItems.answeredEID}}</h4> -->
              <mat-radio-group aria-labelledby="response-radio-group-label"
                  [ngClass]="qaItems.isAnswered ? 'has-answered': 'response-group'"
                  [(ngModel)]="qaItems.answer">

                <mat-radio-button class="response-radio-button"
                value="{{choice}}"
                (change)="onClick($event.value,i,qaItems.clientAccount,qaItems.qid,j,k)"
                (focusout)="tempAnswer = []"
                *ngFor="let choice of qaItems.choices; index as i"
                [ngClass]="qaItems.isAnswered ? qaItems.answer === choice ? 'hasAnswered' : 'cursor-disable' : '' "
                [disabled]="data.alertStatus != 'Published' || qaItems.isAnswered"
                >
                {{choice}}</mat-radio-button>
                <button
                class="submit-btn"
                (mousedown)="submit()"
                *ngIf="!qaItems.isAnswered"
                [ngClass]="qaItems.answer == 'null' ? 'submit-disable' : '' "
                [disabled]="qaItems.answer == 'null'"
                >
                Submit
                </button>
             </mat-radio-group>

             <!-- FOLLOWUP -->
             <div *ngFor="let FUQuestions of withRefQID; index as l">

              <div *ngIf="FUQuestions.refQID == question.ID">
                <div *ngFor="let FUqaItems of FUQuestions.qaItems; index as m">
                  <div *ngIf="FUqaItems.clientAccount == qaItems.clientAccount">
                   <mat-expansion-panel aria-labelledby = "follow-up-expansion-panel" class="follow-up-expansion" [expanded]="true">
                    <mat-expansion-panel-header>
                      <p [innerHTML]="FUQuestions.qaItems[0].question"></p>
                    </mat-expansion-panel-header>

                     <div [ngClass]="FUqaItems.isAnswered ?   'clientAccount-container-disabled' : 'clientAccount-container'">

                       <div class="question-header">
                         <div tabindex="1" class="question-title" *ngIf="FUqaItems.isAnswered && FUqaItems.isSelf">Your Response: </div>
                         <div tabindex="1" class="question-title" *ngIf="FUqaItems.isAnswered && !FUqaItems.isSelf">Responded by: {{FUqaItems.userEID}}</div>
                         <span *ngIf="FUqaItems.isAnswered && !FUqaItems.isUpdate" class="response-date" tabindex="1">Responded on {{FUqaItems.responseDate}} </span>
                         <span *ngIf="FUqaItems.isAnswered && FUqaItems.isUpdate" class="response-date" tabindex="1">Updated on {{FUqaItems.responseDate}} </span>
                       </div>
                       <h4 *ngIf="!FUqaItems.isAnswered">Choose One:</h4>
                       <button class="update-btn" *ngIf="FUqaItems.isAnswered" (click)="update(FUqaItems)">Update</button>
                       
                       <mat-radio-group aria-labelledby="response-radio-group-label"
                         [ngClass]="FUqaItems.isAnswered ? 'has-answered': 'response-group'"
                         [(ngModel)]="FUqaItems.answer">

                           <mat-radio-button class="response-radio-button"
                           value="{{choice}}"
                           (change)="onClick($event.value,i,FUqaItems.clientAccount,FUqaItems.qid,l,m,FUqaItems.refQID)"
                           (focusout)="tempAnswer = []"
                           *ngFor="let choice of FUqaItems.choices; index as i"
                           [ngClass]="FUqaItems.isAnswered ? FUqaItems.answer === choice ? 'hasAnswered' : 'cursor-disable' : '' "
                           [disabled]="data.alertStatus != 'Published' || FUqaItems.isAnswered"
                           >
                           {{choice}}</mat-radio-button>
                           <button
                           class="submit-btn"
                           (mousedown)="submit(FUqaItems.refQID)"
                           *ngIf="!FUqaItems.isAnswered"
                           [ngClass]="FUqaItems.answer == 'null' ? 'submit-disable' : '' "
                           [disabled]="FUqaItems.answer == 'null'"
                           >
                           Submit
                           </button>
                       </mat-radio-group>

                       <!-- second layer of follow-up (e.g follow-up of follow-up) -->
                       <div *ngFor="let nestedFUQuestions of withRefQID; index as n">
                         <div *ngIf="nestedFUQuestions.refQID == FUQuestions.ID">
                           <div *ngFor="let nestedFUqaItems of nestedFUQuestions.qaItems; index as o">
                             <div *ngIf="nestedFUqaItems.clientAccount == FUqaItems.clientAccount">
                               <mat-expansion-panel aria-labelledby = "nested-follow-up-expansion-panel" class="follow-up-expansion" [expanded]="true">
                                <mat-expansion-panel-header>
                                  <p [innerHTML]="nestedFUQuestions.qaItems[0].question"></p>

                                </mat-expansion-panel-header>

                                 <div [ngClass]="nestedFUqaItems.isAnswered ?   'clientAccount-container-disabled' : 'clientAccount-container'">
                                   <div class="question-header">
                                    <div tabindex="1" class="question-title" *ngIf="nestedFUqaItems.isAnswered && nestedFUqaItems.isSelf">Your Response: </div>
                                    <div tabindex="1" class="question-title" *ngIf="nestedFUqaItems.isAnswered && !nestedFUqaItems.isSelf">Responded by: {{nestedFUqaItems.userEID}}</div>
                                    <span *ngIf="nestedFUqaItems.isAnswered && !nestedFUqaItems.isUpdate" class="response-date" tabindex="1">Responded on {{nestedFUqaItems.responseDate}} </span>
                                     <span *ngIf="nestedFUqaItems.isAnswered && nestedFUqaItems.isUpdate" class="response-date" tabindex="1">Updated on {{nestedFUqaItems.responseDate}} </span>
                                   </div>

                                   <h4 *ngIf="!nestedFUqaItems.isAnswered">Choose One:</h4>
                                   <button class="update-btn" *ngIf="nestedFUqaItems.isAnswered" (click)="update(nestedFUqaItems)">Update</button>
                                   <mat-radio-group aria-labelledby="response-radio-group-label"
                                     [ngClass]="nestedFUqaItems.isAnswered ? 'has-answered': 'response-group'"
                                     [(ngModel)]="nestedFUqaItems.answer">

                                       <mat-radio-button class="response-radio-button"
                                       value="{{choice}}"
                                       (change)="onClick($event.value,i,nestedFUqaItems.clientAccount,nestedFUqaItems.qid,n,o,nestedFUqaItems.refQID)"
                                       (focusout)="tempAnswer = []"
                                       *ngFor="let choice of nestedFUqaItems.choices; index as i"
                                       [ngClass]="nestedFUqaItems.isAnswered ? nestedFUqaItems.answer === choice ? 'hasAnswered' : 'cursor-disable' : '' "
                                       [disabled]="data.alertStatus != 'Published' || nestedFUqaItems.isAnswered"
                                       >
                                       {{choice}}</mat-radio-button>
                                       <button
                                       class="submit-btn"
                                       (mousedown)="submit(nestedFUqaItems.refQID)"
                                       *ngIf="!nestedFUqaItems.isAnswered  "
                                       [ngClass]="nestedFUqaItems.answer == 'null' ? 'submit-disable' : '' "
                                       [disabled]="nestedFUqaItems.answer == 'null'"
                                       >
                                       Submit
                                       </button>
                                   </mat-radio-group>
                                 </div>

                               </mat-expansion-panel>
                             </div>
                           </div>
                         </div>
                       </div>


                     </div>

                   </mat-expansion-panel>
                   </div>
                 </div>
               </div>
             </div>
             </div>
            </mat-expansion-panel>
        </div>
        </div>
      </div>
  </div>
      
  </div>
  <app-footer></app-footer>
</div>
