import { Injectable } from '@angular/core';
import { EsoService } from './eso.service';
import { Observable, observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private eso: EsoService) {
  }
  public loadUser() {
    return new Observable((resolve) => {
      // this is where eso lives
      //console.log(this.eso.decode.decode(token.id_token));

      // remove null characters.
      const sjon = require('secure-json-parse')
      this.eso.getIdToken().subscribe((token: string) => {
        let decoded: any = sjon.parse(this.eso.decode.decode(token).replace(/\0/g, ''));
        let userRoles = decoded.roles.length > 1 ? 'ASA-75556-SuperAdminUser' : decoded.roles[0]
        let emergingThreatUser = false;
        let i;
        console.log("user ROLES", userRoles)

        //if (userRoles != 'ASA-75556-SuperAdminUser') {
        for (i = 0; i < decoded.roles.length; i += 1) {
          if (decoded.roles[i] === 'ASA-75556-EmergingThreatUsers') {
            emergingThreatUser = true;
            break;
          }
        }
        //}
        //console.log("ESO DECODED", decoded)
        let user: any = {
          firstName: decoded.name.split(',')[1],
          middleName: "",
          lastName: decoded.name.split(',')[0],
          gender: "Male",
          eid: decoded.preferred_username.split('@')[0],
          title: "Application Development Specialist",
          email: decoded.preferred_username,
          personalPhoto: '/assets/icon/account_circle.svg',
          isSuperAdmin: userRoles == 'ASA-75556-SuperAdminUser' ? true : false,
          isStandardAdmin: userRoles == 'ASA-75556-StandardAdminUsers' ? true : false,
          isEmergingThreat: emergingThreatUser,
          sub: decoded.sub
        }
        resolve.next(user);
      })
    })
  }

  public loadClaims() {
    return new Observable((resolve) => {
      this.eso.getIdentity().subscribe((result: any) => {
        console.log("claims", result)
        resolve.next(result)
      })
    })

  }
}
