import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { Router } from '@angular/router';
import { Config } from 'src/app/core/form.config';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  isError: boolean = false;
  alertID: string = ""
  //forPublish = false;
  public submitted = false;

  showVerify: boolean = true;
  showPublish: boolean = false;
  showSuccess: boolean = false;

  buttonStatus: boolean = true;

  batchStatus:boolean;

  confirmMessage: string;
  verifyButtonText = "";
  disableEdit = false;
  // this is used in usernotification..
  type = "FVR";

  // private formService

  constructor(
    public form: FormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    public dialogRef: MatDialogRef<DialogComponent>) {
    //this.formService = form
  }

  ngOnInit(): void {
    if (this.data) {
      console.log("this data", this.data);
      console.log("this form", this.form);
      this.alertID = this.data.alertID || this.alertID;
      this.isError = this.data.isError;

      console.log("billy data", this.data);
      if (this.data.start == "V") {
        this.verifyButtonText = this.data.buttonText.sendVerification;
        this.confirmMessage = "This transaction has been saved";
      } else if (this.data.start == "FP") {
        this.verifyButtonText = "Publish Transaction"
        // this.confirmMessage = "This verification was already sent"
        this.confirmMessage = "This transaction has been saved";
        this.disableEdit = false;
        this.type = "PN";
        this.buttonStatus = this.form.data.user.isSuperAdmin ? true : false
      }

      // Additional logic for Resending Verification upon Edit Transaction
      // if(this.form.data.origTransaction != {}){
        // Check if there are values for allMobileUsers and expiration
        if(this.form.data.transaction.allMobileUsers){
          delete this.form.data.transaction.allMobileUsers;
        }
        if(typeof(this.form.data.transaction.expiration) == 'undefined' ){
          delete this.form.data.transaction.expiration;
        }

        let strTransaction = JSON.stringify(this.form.data.transaction);
        let strOrigTransaction = JSON.stringify(this.form.data.origTransaction);

        if(strTransaction != strOrigTransaction){
          this.disableEdit = false;
          this.buttonStatus = true;
          this.verifyButtonText = this.data.buttonText.sendVerification;
          this.type = "FVR";
        }
    // }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  public get showError() {
    let retVal = false;
    retVal = this.data.isError;
    return retVal
  }

  verifyClick(event) {
    if (event.eventName == "FVR") {
      console.log('FVR')
      this.sendVerification();
      //this.verifyButtonText = "Publish Notification"
    } else if (event.eventName == "PN") {
      console.log(this.type)
      this.batchStatus = event.batchStatus;
      //this.publish();
      // hide verify show publish
      this.showVerify = false;
      this.showPublish = true;
    } else if (event.eventName == "gotoTransaction") {
      this.router.navigateByUrl('/transaction')
      this.dialogRef.close(true);
    } else if (event.eventName == "gotoCreate") {
      this.router.navigateByUrl('/create')
      this.dialogRef.close(true);
    }
  }

  sendVerification() {
    console.log("user check", this.form)
    let toBeSend = {
      "alertID": this.alertID,
      "eid": this.form.data.transaction.reviewers.split(';'),
      "sendToRecipients": "False",
      "type": this.type
    }
    console.log(toBeSend);
    // for single click only.
    if (!this.submitted) {
      this.submitted = true;
      this.form.sendNotification(toBeSend, false, false, false).subscribe(data => {
        // update alert to verified
        let updateStatus = {
          alertStatus: Config.AlertStatus.Verified,
          modifiedDate: new Date()
        }

        this.form.updateTransaction(this.alertID, updateStatus).subscribe(data => {
          this.submitted = false;
          //this.forPublish = true;
          this.form.data.transaction.alertStatus = Config.AlertStatus.Verified;
          this.verifyButtonText = "Publish Transaction"
          this.confirmMessage = "This verification was sent"
          this.disableEdit = false;

          this.type = "PN";
          this.buttonStatus = this.form.data.user.isSuperAdmin ? true : false
          console.log('sending notification');
          console.log(data);

          // Additional logic/assignment for Resending Verification upon Edit Transaction at line 64
          this.form.data.origTransaction = {};
          this.form.data.origTransaction = Object.assign({},this.form.data.transaction);
          console.log('Object' + Object.assign({},this.form.data.transaction));
        })
      }, err => {
        this.submitted = false;
      })
    }
  }

  publish(event) {
    if (event == 'publish') {
      if(this.form.data.transaction.alertType.type == "SmishingTest"){
        console.log("to publish transaction", this.form.data.transaction.reviewers, this.form.data.transaction)
        let recipient = this.form.data.transaction.recipients.option == 'ALL' ? this.form.data.transaction.recipients.option
                      : this.form.data.transaction.recipients.option == "eidsmishing" ? this.form.data.transaction.recipients.eidsmishing
                      : this.form.data.transaction.recipients.option == "fileNumbers" ? this.form.data.transaction.recipients.fileValue
                      : this.form.data.transaction.recipients.option == 'file' ? this.form.data.transaction.recipients.fileValue
                      : this.form.data.transaction.recipients.option == 'ad' ? this.form.data.transaction.recipients.ad
                      : this.form.data.transaction.recipients.eid

        let tobeSave =
        {
          "alertID": this.form.data.transaction.alertID,
          "alertType": "SmishingTest",
          "alertStatus": 'Published',
          "category": 'SmishingTest',
          "ragStatus": this.form.data.transaction.ragStatus,
          "shortDescription": this.form.data.transaction.shortDescription,
          "bodyHtml": this.form.data.transaction.bodyHtml,
          "smsMessage": this.form.data.transaction.smsMessage,
          "recipients": recipient,
          "recipientFileName": this.form.data.transaction.recipients.option == 'file' ? this.form.data.transaction.recipients.fileName :  '',
          "fileNumbers": this.form.data.transaction.recipients.option == "fileNumbers" ? this.form.data.transaction.recipients.fileNumbers : '',
          "expiration": this.form.data.transaction.expiration,
          "title": this.form.data.transaction.title,
          "reviewer": this.form.data.transaction.reviewers,
          "isAD": this.form.data.transaction.isAD,
          "publisher": this.form.data.user.eid,
          "createdBy": this.form.data.user.eid,
          "createdDate": new Date(),
          "modifiedBy": this.form.data.user.eid,
          "recipientCount": recipient.split(";").length,
          "modifiedDate": new Date(),
          "etStatus": this.form.data.transaction.alertType.type == 'EmergingThreat' ? 'Awaiting Response' : '',
          "numbers": this.form.data.transaction.recipients.numbers ? this.form.data.transaction.recipients.numbers : '',
          "hasFollowup": this.form.data.transaction.alertType.type == 'EmergingThreat' ? 'false' : '',
          "publishedDate": new Date(),
        }


        // tobeSave.recipientCount =  this.form.data.transaction.recipients.numbers.split(",").length
        // tobeSave.alertType = "SmishingTest"
        // tobeSave.recipients = ""
        // tobeSave.reviewer = this.form.data.transaction.reviewers
        // tobeSave.alertStatus = 'Published'
        // tobeSave.category = ''
        // tobeSave.publisher = this.form.data.user.eid
        // tobeSave.modifiedBy = this.form.data.user.eid

        // tobeSave.reviewers = ''
        // tobeSave.categories = ''
        // tobeSave.emerging = ''

        console.log("to publish alert", tobeSave)
        console.log("to publish transaction", this.form.data.transaction.reviewers, this.form.data.transaction)

        this.form.saveTransaction(tobeSave).subscribe((data: any) => {
          //loading.close();
          if (data) {
            console.log("success save publish", data)
            this.showPublish = false;
            this.showSuccess = true;
          }
        }, err => {
          //loading.close();
            console.log("errr save publish", err)
        })
      }
        this.submitted = true;

        let toBeSend = {};

        if(this.form.data.transaction.isAD || this.form.data.transaction.recipients.option == 'ad'){
          toBeSend = {
            "alertID": this.alertID,
            "group": this.form.data.transaction.recipients.ad
          }
        }else{
          toBeSend = {
            "alertID": this.alertID,
            "sendToRecipients": "True",
            "type": "all"
          }
        }

        console.log(toBeSend);

        let isAD = false;
        if(this.form.data.transaction.recipients.option == 'ad'){
          isAD = true;
        }

        let isAllMobileUsers = false;
        if(this.form.data.transaction.recipients.option == 'ALL'){
          isAllMobileUsers = true;
          toBeSend = {
            "alertID": this.alertID,
            "group": "AllMobileUsers"
          }
        }

        //Handle csv users
        let isMultipleUsers = false;
        if(this.form.data.transaction.recipients.option == 'file'){
          isMultipleUsers = true;
          toBeSend = {
            "alertID": this.alertID,
            "group": "MultipleUsersCSV"
          }
        }

        //Handle eid users
        if(this.form.data.transaction.recipients.option == 'eids'){
          isMultipleUsers = true;
          toBeSend = {
            "alertID": this.alertID,
            "group": "MultipleUsersEID"
          }
        }

        let isSMS = false;
        if (this.form.data.transaction.recipients.option == 'eidsmishing'){
          isSMS = true;
          toBeSend = {
            "recipients": this.form.data.transaction.recipients.eidsmishing.split(';'),
            "message": this.form.data.transaction.smsMessage,
            "alertID": this.form.data.transaction.alertID,
            "pinpointSMS":"true"
          }
        }

        if (this.form.data.transaction.recipients.option == 'fileNumbers'){
          isSMS = true;
          toBeSend = {
            "recipients": this.form.data.transaction.recipients.fileValue.split(';'),
            "message": this.form.data.transaction.smsMessage,
          }
        }

        //this.form.sendNotification(toBeSend, isAD, isAllMobileUsers).subscribe(data => {
          let updateStatus = {
            alertStatus: Config.AlertStatus.Published,
            modifiedDate: new Date(),
            publishedDate: new Date()
          }
          this.form.updateTransaction(this.alertID, updateStatus).subscribe(data => {
            console.log('sending notification');
            console.log(data);

          let reviewersEID = this.form.data.transaction.reviewers.split(';')
          let alertID = this.alertID

          let userNotifIds = []
          for (let j = 0; j < reviewersEID.length; j++) {
            let rowEid: string = (reviewersEID[j].split('@')[0]).trim()

            let userNotifId = rowEid.trim().toLowerCase() + "|" + alertID + "|FVR";
            userNotifIds.push(userNotifId);
          }

          // marking alert r for reviewers.
          let update = {
            param: { eid: this.form.data.user.eid , alertID: alertID, type:"S"},
            userNotifIds: userNotifIds,
            status: "R"
          }

          if (userNotifIds.length > 0) {
            this.form.markAlert(update).subscribe(markAlertData => {
              this.form.data.transaction.alertStatus = Config.AlertStatus.Published;
              this.showPublish = false;
              this.showSuccess = true;
            }, error => {
              console.error('Error here => ', error)
            })
          } else {
            this.form.data.transaction.alertStatus = Config.AlertStatus.Published;
            this.showPublish = false;
            this.showSuccess = true;
          }

          this.form.sendNotification(toBeSend, isAD, isAllMobileUsers, isMultipleUsers).subscribe(data => {}, err => {
            this.submitted = false;
          })

        })


    //})
  } else {
      this.dialogRef.close(true);
      this.router.navigateByUrl('/transaction')
    }
  }

  success(event) {
    this.router.navigateByUrl('/transaction')
    this.dialogRef.close(true);
  }
}
