import { Component, OnInit, ViewEncapsulation, Inject} from '@angular/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { Config } from 'src/app/core/form.config';

@Component({
  selector: 'app-resolve',
  templateUrl: './resolve.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./resolve.component.scss']
})
export class ResolveComponent implements OnInit {

  constructor(
    public form: FormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ResolveComponent>) {
  }

  icon = '../../../../assets/icons/resolve-expiration-date.png'
  isResolve: boolean;

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log(`${type}: ${event.value}`);
  }

  ngOnInit(): void {

    if (this.data) {
       console.log("this data", this.data);
    }
    this.isResolve = true;
  }

  resolve(data){
    console.log(data);
    if(data){
      this.isResolve = true;
    }else {
      this.isResolve = false
    }
    console.log("resolve?  " + this.isResolve)
  }

  changeColor(newColor) {
    var elem = document.getElementById('myButton');
    elem.style.color = newColor;
  }

  yesClick(){
    if(this.isResolve){
      let tobeSave = {
        ragStatus: Config.AlertStatus.Resolved,
        modifiedDate: new Date()
      }
      this.form.updateTransaction(this.data.alertID, tobeSave).subscribe(updateData =>{
         this.dialogRef.close("updated");
      })
    }else{
      this.dialogRef.close("updated");
    }
  }

  cancelClick(){
    this.dialogRef.close("canceled");
  }

}
