<div class="white-box" *ngIf="isUnauthorized"></div>
<app-nav></app-nav>
<a class="skip-main" href="#main">Skip to main content</a>
<div class="page-status" *ngIf="status">
  <p>There's a transaction being processed. Publishing transactions are disabled for the meantime </p>
</div>
<div class="page-height">
    <div class="view-admin-container" *ngIf="form.data.transaction">
        <div class="admin-button">

            <a mat-raised-button class="bg-purple" (click)="clickMyTransaction()">
                <mat-icon aria-hidden="false" aria-label="Example home icon">
                    home
                </mat-icon>
                My Transactions
            </a>

        </div>
        <div class="view-transaction-container"  id="main" tabindex="-1">

            <div class="view-wrapper" >
              <div class="view-header-wrapper">
                <h3 class="form-title medium-font">VIEW TRANSACTION <span class="color-sky">#{{form.data.transaction.alertID}} </span></h3>
                <button class="as-btn-ts" (click)="reload()"  aria-label="refresh-button">
                  <mat-icon>
                      refresh
                  </mat-icon>
                </button>
              </div>

                <div class="view-title-wrapper">
                    <span class="bold-font">MESSAGE DETAILS</span>
                </div>
                <div class="view-container" >

                    <div class="view-details">

                        <div class="view-detail">
                            <div class="view-label">
                                <span class="semibold-font">
                                    NOTIFICATION TYPE:
                                </span>
                            </div>
                            <div class="view-data">
                                <span>{{form.data.transaction.alertType.description}}</span>
                            </div>
                        </div>
                        <div class="view-detail">
                            <div class="view-label">
                                <span class="semibold-font">
                                    CATEGORY:
                                </span>
                            </div>
                            <div class="view-data">
                                <span>{{form.data.transaction.categories.description}}</span>
                            </div>
                        </div>
                        <div class="view-detail">
                            <div class="view-label">
                                <span class="semibold-font">
                                    RECIPIENTS:
                                </span>
                            </div>
                            <div class="view-data">
                                <!-- <span>{{ form.data.transaction.recipients.option == 'ALL' ? form.config.recipients.standard[0].description : form.data.transaction.recipients.eid }}</span> -->
                                <span *ngIf="form.data.transaction.recipients.option == 'ALL'">
                                    All Mobile Users
                                </span>
                                <div *ngIf="form.data.transaction.recipients.option == 'file'" >
                                    <a (click)="downloadFile()">
                                     FILE: <span class="blue semibold-font">{{form.data.transaction.recipients.fileName}} </span>
                                    </a>
                                </div>
                                <div *ngIf="form.data.transaction.recipients.option == 'fileNumbers'" >
                                    <a (click)="downloadFile()">
                                     FILE: <span class="blue semibold-font">{{form.data.transaction.recipients.fileNumbers}} </span>
                                    </a>
                                </div>
                                <div *ngIf="form.data.transaction.recipients.option == 'ad'">
                                    <span>
                                        {{form.data.transaction.recipients.ad}}
                                    </span>
                                </div>
                                <div *ngIf="form.data.transaction.recipients.option == 'eidsmishing'">
                                    <span *ngFor="let recipient of form.data.transaction.recipients.eidsmishing.split(',')">
                                        {{recipient}}
                                    </span>
                                </div>
                                <div *ngIf="form.data.transaction.recipients.option == 'eids'">
                                    <span *ngFor="let recipient of form.data.transaction.recipients.eid.split(',')">
                                        {{recipient}}
                                    </span>
                                </div>
                                <div *ngIf="form.data.transaction.ragStatus == 'Asset Controls'">
                                    <span>
                                        {{totalRecipients}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="view-detail">
                            <div class="view-label">
                                <span class="semibold-font">
                                    TOTAL RECIPIENTS:
                                </span>
                            </div>
                            <div class="view-data">
                                <a (click)="downloadFileTotalRecipients()">
                                    <span class="blue semibold-font">{{form.data.transaction.recipientCount}}</span>
                                </a>
                            </div>
                        </div>
                        <div class="view-detail">
                            <div class="view-label">
                                <span class="semibold-font">
                                    TITLE:
                                </span>
                            </div>
                            <div class="view-data">
                                <span>{{form.data.transaction.title}}</span>
                            </div>
                        </div>
                        <div class="view-detail" *ngIf="form.data.transaction.smsMessage">
                            <div class="view-label">
                                <span class="semibold-font">
                                    SMS MESSAGE:
                                </span>
                            </div>
                            <div class="view-data" [innerHTML]="form.data.transaction.smsMessage">

                            </div>
                        </div>
                        <div class="view-detail" *ngIf="form.data.transaction.shortDescription">
                            <div class="view-label">
                                <span class="semibold-font">
                                    SHORT DESCRIPTION:
                                </span>
                            </div>
                            <div class="view-data" [innerHTML]="form.data.transaction.shortDescription">

                            </div>
                        </div>
                        <div class="view-detail" *ngIf="form.data.transaction.bodyHtml">
                            <div class="view-label">
                                <span class="semibold-font">
                                    DETAILED DESCRIPTION:
                                </span>
                            </div>
                            <div class="view-data" [innerHTML]="form.data.transaction.bodyHtml"></div>
                        </div>
                        <div class="view-detail">
                            <div class="view-label">
                                <span class="semibold-font">
                                    VERIFICATION:
                                </span>
                            </div>
                            <div class="view-data">
                                <span>{{form.data.transaction.reviewers}}</span>
                            </div>
                        </div>

                    </div>
                    <div class="view-publishers">
                        <div class="view-publisher">
                            <div class="publisher-label">
                                <span class="semibold-font">
                                    PUBLISHER:
                                </span>
                            </div>
                            <div class="publisher-data">
                                <span>{{form.data.transaction.publisher}}</span>
                            </div>
                        </div>
                        <div class="view-publisher"  *ngIf="form.data.transaction.ragStatus">
                            <div class="publisher-label">
                                <span class="semibold-font">
                                    RAG STATUS:
                                </span>
                            </div>
                            <div class="publisher-data">
                                <!-- <span *ngIf="form.data.transaction.ragStatus == null || form.data.transaction.ragStatus == '' "> - - - </span> -->
                                <span class="publisher-status">{{form.data.transaction.ragStatus}}</span>
                                <div class="publisher-icon">
                                    <img *ngIf="form.data.transaction.ragStatus === 'Resolved'" class="png-icon" src={{green}} alt="Resolved Icon(Green)">
                                    <img *ngIf="form.data.transaction.ragStatus === 'Awareness'" class="png-icon" src={{green}} alt="Resolved Icon(Green)">
                                    <img *ngIf="form.data.transaction.ragStatus === 'Warning'" class="png-icon" src={{orange}} alt="Warning Icon(Orange)">
                                    <img *ngIf="form.data.transaction.ragStatus === 'Emerging Threat'" class="png-icon" src={{red}} alt="Emerging Threat Icon(Red)">
                                    <img *ngIf="form.data.transaction.ragStatus === 'Urgent'" class="png-icon" src={{red}} alt="Urgent Icon(Red)">
                                    <img *ngIf="form.data.transaction.ragStatus === 'CDP Red Accounts'" class="png-icon" src={{red}} alt="CDP Red Icon(Red)">
                                    <img *ngIf="form.data.transaction.ragStatus === 'Asset Controls'" class="png-icon" src={{red}} alt="Asset Controls Icon(Red)">
                                </div>
                                  <!-- <mat-radio-group aria-label="Select an option" labelPosition="before" >
                                    <mat-radio-button *ngIf="form.data.transaction.ragStatus === 'Warning'" class="Warning" value="Warning">{{form.data.transaction.ragStatus}}</mat-radio-button>&nbsp;
                                    <mat-radio-button *ngIf="form.data.transaction.ragStatus === 'Emerging Threat'" class="Emerging" value="Emergin Threat"> {{form.data.transaction.ragStatus}}</mat-radio-button>
                                    <mat-radio-button *ngIf="form.data.transaction.ragStatus === 'Urgent'" class="Urgent" value="Urge"> {{form.data.transaction.ragStatus}}</mat-radio-button>
                                </mat-radio-group>   -->

                            </div>
                        </div>
                        <div class="view-publisher" *ngIf="form.data.transaction.alertType.type != 'SmishingTest'">
                            <div class="publisher-label">
                                <span class="semibold-font">
                                    EXPIRATION DATE:
                                </span>
                            </div>
                            <div class="publisher-data">
                                <span *ngIf="form.data.transaction.expiration == null || form.data.transaction.expiration == '' "> - - - </span>
                                <span *ngIf="form.data.transaction.expiration != null">{{form.data.transaction.expiration | date: 'M/d/yyyy'}}</span>
                            </div>
                        </div>
                        <div class="view-publisher">
                            <div class="publisher-label">
                                <span class="semibold-font">
                                    PUBLISHED DATE:
                                </span>
                            </div>
                            <div class="publisher-data">
                                <span>{{form.data.transaction.publishedDate ? (form.data.transaction.publishedDate | date:'M/d/yyyy' ) : ''}}</span>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
            <div class="view-wrapper" >

                <div class="delivery-detail">
                    <span class="bold-font">DELIVERY DETAILS</span>
                </div>
                <div class="delivery-container" >

                    <app-deliverydetail [deliveryDetail]="form.data.transaction.deliveryDetail" [totalRecipients]="form.data.transaction.recipientCount">
                    </app-deliverydetail>

                </div>


            </div>
            <div *ngIf="form.data.transaction.alertType.type=='EmergingThreat'" class="view-wrapper" >
                <div class="response-detail">
                    <span class="bold-font">RESPONSE DETAILS</span>
                </div>
                <div class="response-container" >

                    <app-emergingquestions>

                    </app-emergingquestions>

                </div>


            </div>
        </div>
        <div class="followup-button" *ngIf="form.data.transaction.alertType.type=='EmergingThreat' && form.data.transaction.ragStatus === 'Emerging Threat'">
            <a mat-button onclick="this.blur()" (click) = "createFollowupQuestion()" [ngClass]="status? 'disabled' : 'active' ">
                <mat-icon aria-hidden="false" aria-label="Example home icon">
                    add
                </mat-icon>
               SEND FOLLOW-UP QUESTION
            </a>

        </div>
        <div class="reminder-button" *ngIf="form.data.transaction.alertType.type=='EmergingThreat' && form.data.transaction.ragStatus === 'Emerging Threat'">
            <a mat-button #reminder onclick="this.blur()" (click) = "sendReminder()" [ngClass]="status? 'disabled' : 'active' ">
                <mat-icon aria-hidden="false" aria-label="Example home icon">
                    add_alarm
                </mat-icon>
               SEND A REMINDER
            </a>

        </div>
    </div>

    <app-footer></app-footer>
</div>

