import { Component, OnInit, ViewEncapsulation, Inject} from '@angular/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { Config } from 'src/app/core/form.config';

@Component({
  selector: 'app-resolve',
  templateUrl: './expiration.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./expiration.component.scss']
})
export class ExpirationComponent implements OnInit {


  constructor(
    public form: FormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ExpirationComponent>) {
    //this.formService = form
  }

  submitted = false;
  datepickerClicked = false;
  expValue: Date;


  minDate = new Date();

  icon = '../../../../assets/icons/resolve-expiration-date.png'

  selectedDate: Date;

  addEvent(event: MatDatepickerInputEvent<Date>) {
    console.log(` ${event.value}`);

    this.selectedDate = event.value;
    this.datepickerClicked = true;
  }


  ngOnInit(): void {

    if (this.data) {
       console.log("this data", this.data);
       this.expValue = this.data.expiration;
    }
  }

  changeColor(newColor) {
    var elem = document.getElementById('myButton');
    elem.style.color = newColor;
  }


  yesClick(){

    if(!this.submitted){


      let tobeSave = {

        expiration: this.selectedDate? this.selectedDate: '',
        modifiedDate: new Date()
      }
      this.form.updateTransaction(this.data.alertID, tobeSave).subscribe(updateData=>{
        this.dialogRef.close("updated")
      })
    }
  }

  cancelClick(){
    this.dialogRef.close("canceled");
  }

}
