import { Component, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { Router } from '@angular/router';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { DownloadService } from 'src/app/core/download.service';
import { FormService } from 'src/app/core/form.service';
import { resourceLimits } from 'worker_threads';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor(
    public form:FormService,
    public router: Router,
    public download: DownloadService,
    private oauthStorage: OAuthStorage,
  ) { }

  loading = true;
  somewidth = 0;
  ASAreports = [];

 ngOnInit(): void {

    
    this.form.data.extract = {
      rows : [],
      columns : [],
      reports : [],
      ISreports:[],
      title : "Extract File",
    }
    let eid = this.form.data.user.eid;
    // eid = 'charis.t.nunez'
    this.loading = true;
    this.form.setUserAccess(eid).subscribe(userAccessData => {
      if(!this.form.data.userAccess.canDownload){
        // this.loading = false;
        this.router.navigateByUrl('/unauthorized');
      }
      else{
        this.form.setReportsAccess(eid).subscribe(reportsAccessData =>{
          this.form.getReportsPage().subscribe(reports => {
          this.form.data.extract.reports = reports.reports;
          let hidden = this.gethiddenTiles();
          hidden.forEach(tile => {
            this.form.data.extract.reports = this.form.data.extract.reports.filter(element => element.title != tile)
          });
          this.form.data.extract.ISreports = reports.ISreports;
          if (this.form.data.reportsAccess.CyberSecurityDetails != true){
          this.ASAreports.push(this.form.data.extract.reports);
          } else{
          this.ASAreports.push(this.form.data.extract.reports,this.form.data.extract.ISreports);
          }
          this.form.data.extract.rows=[];
          this.ASAreports.forEach(reportCateg => {
            let index = 0;
            for(let i=0;i<reportCateg.length; i = i+3){
              for(let j=0;j<3;j++){
                if(reportCateg[index] == undefined){
                  break;
                }
                this.form.data.extract.columns.push(reportCateg[index]);
                index = index+1;
              }
              this.form.data.extract.rows.push(this.form.data.extract.columns);
              this.form.data.extract.columns = [];
            }
          })
          console.log(this.form.data.extract.rows);
          this.loading = false;
        })
      })
      }
    })

    window.onbeforeunload = () => {
      this.oauthStorage.setItem('url', '');
    }
  }

  public gethiddenTiles(){
    let tiles =[];
    if (this.form.data.reportsAccess.UserDetail != true){
      tiles.push("Always Safe Users");
    }
    if (this.form.data.reportsAccess.UsageMetrics != true){
      tiles.push("Usage Metrics");
    }
    if (this.form.data.reportsAccess.TotalRewards != true){
      tiles.push("Rewards Details");
    }
    if (this.form.data.reportsAccess.RewardedPoints != true){
      tiles.push("Rewards Summary");
    }
    if (this.form.data.reportsAccess.ISMSUsers != true){
      tiles.push("ISMS Access Control Reviews");
    }
    if (this.form.data.reportsAccess.CyberSecurityDetails != true){
      tiles.push("Cyber Security Adventure Scores");
    }
    if (this.form.data.reportsAccess.ISMSActions != true){
      tiles.push("ISMS Actions");
    }
    return tiles;
  }

  generateReport(title, row, col, type){
    if(type == "Extract File"){
      this.form.data.extract.rows[row][col].loading = true;
      if(title == "Error Dump"){
        this.form.data.extract.rows[row][col].body = {"extractData": "true","TableName": "75556_Always_Safe_ErrorDump","ColumnHeaders": ["errorID","eid","errorDate","errorDescription","lambdaName"]}
      }
      console.log(row,col)

       this.form.generateReport(this.form.data.extract.rows[row][col].body.TableName, "none", row, col).subscribe((result: any) => {
        if (result) {
          console.log("genrating report.. ", title)
        }
      })
    } else {
       console.log("generate", row,col)
      let fileName = title;
        var a = document.createElement('a');
        var blob = new Blob([this.form.data.extract.rows[row][col].concatenatedString], {type: 'text/csv' }),
        url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
  }

  get getReports(){
    if (this.form.data.extract.rows[2] !=undefined){
    return [this.form.data.extract.rows[0], this.form.data.extract.rows[1]]
    } else if(this.form.data.extract.rows[1] !=undefined && this.form.data.reportsAccess.CyberSecurityDetails != true){
      return [this.form.data.extract.rows[0], this.form.data.extract.rows[1]]
    } else if (this.form.data.extract.rows[1] !=undefined && this.form.data.reportsAccess.CyberSecurityDetails == true){
      return [this.form.data.extract.rows[0]]
    } else{
      return [this.form.data.extract.rows[0]]
    }
  }

  // get getReports(){
  //   return [this.form.data.extract.rows[0],this.form.data.extract.rows[1]]
  // }

  get getISReports(){
    if (this.form.data.reportsAccess.CyberSecurityDetails != true){
      return [];
    }
    else{
      if (this.form.data.extract.rows[2] !=undefined && this.form.data.extract.rows[1] !=undefined){
        return [this.form.data.extract.rows[2]]
      }else if (this.form.data.extract.rows[1] !=undefined){
        return [this.form.data.extract.rows[1]]
      }else{
        return [this.form.data.extract.rows[0]]
      }
    }
  }

  // get getISReports(){
  //   return [this.form.data.extract.rows[2]]
  // }

  isCyberSecurity(){
    if(this.form.data.reportsAccess){
      if(this.form.data.reportsAccess.CyberSecurityDetails == true){
          return true;
        } else{
          return false;
        }
    } else{
      return false;
    }
  }

}
