<div class="resolve">
  <div class="dialog-title">
    <img src={{icon}}>

    <div class="medium-font"> Are you sure you want to change the </div>

    <div class="dialog-desc medium-font">
      <p>RAG status of this transaction? </p>
      <fieldset ng-form='form1'>
        <legend class="bold-font">RAG STATUS</legend>
        <div>
          <a *ngIf="!isResolve" class="myButton white" (click)="resolve(true)"></a>
          <a *ngIf="isResolve" class="myButton green" (click)="resolve(false)"></a>
          <a>Resolved</a></div>
      </fieldset>
    </div>

  </div>
  <p>If you click Yes, your change will be applied immediately.</p>
  <br />
  <div class="dialog-buttons">
    <br>
    <div class="dialog-button">
      <span>
        <a mat-raised-button class="green-button" (click)="yesClick()">
          YES
        </a>
      </span>
      <span>
        <a mat-raised-button class="red-button" (click)="cancelClick()">
          CANCEL
        </a>
      </span>
    </div>
  </div>
</div>
