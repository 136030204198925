<div class="dialog-title">
    <span class="material-icons">
        check_circle_outline
    </span>

    <div class="dialog-desc">{{confirmMessage}} </div>
</div>
<div class="dialog-buttons">
    <div class="dialog-button" *ngIf="!disableEdit">
        <a class="as-btn bg-blue" (click)="transaction('gotoCreate')">
            <mat-icon aria-hidden="false" aria-label="Example home icon">
                create
            </mat-icon>
             <span>Edit Transaction</span>

        </a>
    </div>
    <div class="dialog-button">
        <a  class="as-btn bg-purple" (click)="transaction('gotoTransaction')">
            <mat-icon aria-hidden="false" aria-label="Example home icon">
                home
            </mat-icon>
            <span>My Transactions</span>
        </a>
    </div>
    <div *ngIf="hideButton && disableEdit" class="dialog-button" (click) = "clickSave()">
        <a  class="as-btn bg-yellowgold" >
           <span>{{buttonText}}</span>
        </a>
    </div>


</div>

<div *ngIf="hideButton && !disableEdit" class="dialog-button-full" (click) = "clickSave()">
    <a  class="as-btn bg-yellowgold" >
        <span>{{buttonText}} </span>
    </a>
</div>
