<app-nav></app-nav>
<div role="region" aria-label="Skip" >
    <a class="skip-main" href="#main">Skip to main content</a>
</div>
<body>
    
<div class="accessdenied">
    <div id="container"> 
        <img role="img" aria-label="Lock logo" class="center" src="assets/lock.png"/>
        <h2>ACCESS DENIED</h2>
        <h3>Please contact our system administrator for more information.</h3>
    </div>
    
</div>
</body>
<app-footer></app-footer>