import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormService } from 'src/app/core/form.service';
import * as XLSX from 'xlsx';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ReminderQuestionComponent } from '../reminder-question.component';
import { CompareComponent } from '../../../create/compare/compare.component';

@Component({
  selector: 'app-reminder-file-dialog',
  templateUrl: './reminder-file-dialog.component.html',
  styleUrls: ['./reminder-file-dialog.component.scss']
})
export class ReminderFileDialogComponent implements OnInit {

  disableSend: boolean = true
  textSend = "SEND"
  ET_DATA;
  currentId = 0;
  maxQuestion; maxAnswer;
  disableAnswer = false
  submitted: boolean = false
  fAnswer = ''
  errorChecker = false
  testboolean = true
  addReponses: boolean = true
  isSelectRec: boolean = false

  isUploadCSV: boolean = false;
  public csvError;
  public buttonFileText = "Browse file"
  public retValCSV = undefined;
  public btnUpdate = false;
  public isLoadingCSV = false;
  public errorDomain = [];
  public dialogRefCompare: any;
  public compareDialog: any;
  public error;
  public retVal = undefined;
  public showSuccess = false;
  public showUpload = true;


  constructor(public form: FormService,
    private dialogRef: MatDialogRef<ReminderFileDialogComponent>,
    public dialog: MatDialog){ }

  ngOnInit(): void {
  }

  onFileChange(ev) {
    const reader = new FileReader();
    const file = ev.target.files[0];
    this.buttonFileText = "Uploading in progress..."

    if(file){
    if (file.type == "application/vnd.ms-excel" || file.type == "text/csv") {
      this.csvError = ''
      reader.onload = (event) => {
        //let data : ArrayBuffer = reader.result;
        console.log(new Date());

        let data = String(reader.result)

        let eids = '';
        data.split(/\r?\n/).forEach(eid => { if (eid != '' && eid != undefined) eids = eids == '' ? eid.replace(/[^ -~]+/g, "") : eids += "; " + eid.trim().replace(/[^ -~]+/g, "") })

        if (eids == '') {
          this.csvError = 'No EIDs found.'
          this.disableSend = true;
          this.buttonFileText = "Browse file";
          this.btnUpdate = false;
          this.isLoadingCSV = false;
        } else {
          this.isLoadingCSV = true;
          eids = this.filterEIDs(eids);
          this.buttonFileText = file.name;
          this.btnUpdate = true;
          this.retValCSV = {
            status: 'done',
            fileName: file.name,
            eids: eids
          }
          this.checkCDPUsers(eids);
        }
      }
      reader.readAsBinaryString(file);
    } else {
      this.csvError = 'Only csv file is allowed for upload. Try again.'
      this.buttonFileText = "Browse File"
      this.btnUpdate = false;
      this.isLoadingCSV = false;
      this.disableSend = true;
    }
  }else{
      this.buttonFileText = 'Browse file';
      this.btnUpdate = false;
      this.isLoadingCSV = false;
      this.disableSend = true;
  }
  }

  close() {
    this.retVal = {
      status: 'close',
      fileName: '',
      eids: ''
    }

    this.dialogRef.close(this.retValCSV)
    this.dialog.closeAll();
  }

  cancel(){
    this.dialogRef.close();
  }

  upload(){
    if(this.retValCSV){

    this.dialogRef.close(this.retValCSV)
  }

  }

  send(){
    if(!this.disableSend){
    let eidListformatted = this.retValCSV.eids.split(';');
    let param = {
      alertId: this.form.data.transaction.alertID,
      title: this.form.data.transaction.title,
      isCustom: "true",
      recipients: eidListformatted
    }
    console.log("passed data", param);
    this.form.sendReminder(param).subscribe(result => {
      this.showUpload = false;
      this.showSuccess = true;
    })
  }else{
    console.log("clicked")
  }
  }


  checkCDPUsers(eids) {
    let eidlist;
    eidlist = eids;
    if (eidlist) {

      let formatedArray = this.removeAccenture(eidlist);
      let formated = {
        eids: formatedArray.stringFormat
      }
      if(this.errorDomain.length > 0){
        let text = 'The following email addresses will not receive this notification because  <br>they do not have the Always Safe Mobile application installed <br>on their device or there is a spelling error.';
        this.continueCompare(eidlist, text, formatedArray, false)
      }
      else if(formatedArray.arrayFormat.length > 0){
        this.form.checkCDPUsers(formated).subscribe(data => {
          if (data) {
              let text = 'The following EID(s) will not receive this notification because they are not CDP users.'
              this.continueCompare(eidlist, text, formatedArray, true)
          }
        });
      } else {
        let text = 'The following email addresses will not receive this notification because  <br>they do not have the Always Safe Mobile application installed <br>on their device or there is a spelling error.';
        this.continueCompare(eidlist, text, formatedArray, true)
      }
    }
  }
  removeAccenture(recipients) {
    this.errorDomain = []
    let rArray = recipients.split(';');

    let retVal = {
      stringFormat: '',
      arrayFormat: []
    };
    rArray.forEach(element => {
      if(element.indexOf('@') != -1){
        let value = element.split('@')[0].trim().toLowerCase();
        let domain = element.split('@')[1].trim().toLowerCase();
        if(element.split('@').length == 2){
          if(domain && domain == "accenture.com"){
            if (value) {
              retVal.stringFormat = retVal.stringFormat == '' ? retVal.stringFormat + value : retVal.stringFormat + ',' + value
              retVal.arrayFormat.push(value);
            } else {
              this.errorDomain.push(element)
            }
          } else {
            this.errorDomain.push(element)
          }
        }else{
          this.errorDomain.push(element)
        }
      } else {
        this.errorDomain.push(element)
      }
    });

    retVal.arrayFormat = Array.from(new Set(retVal.arrayFormat));
    this.form.data.temporary = this.errorDomain
    return retVal;
  }

  continueCompare(eidlist, text,formatedArray, isCDP) {

    if (this.form.data.temporary.length > 0) {
      this.form.data.compare = { text: text }
      let count = 0;
      count = this.removeInvalidEid(eidlist, formatedArray.arrayFormat, true, this.form.data.temporary, true);

      if (this.form.data.temporary.length > 0) {
        this.compareDialog = this.dialog.open(CompareComponent, {
          disableClose: true,
          data: {
            buttonText: {
              cancel: 'Cancel',
            },
          },
        });
        this.compareDialog.afterClosed().subscribe(closed => {
          if (count == 0) {
            this.csvError = 'No EIDs found.'
            this.buttonFileText = "Browse file"
            this.btnUpdate = false;
            this.isLoadingCSV = false;
            this.disableSend = true;
          }else{
            this.buttonFileText = this.retValCSV.fileName;
            this.isLoadingCSV = false;
            this.disableSend = false;
          }

          if (!isCDP && count > 0) {
            this.buttonFileText = "Uploading in progress...";
            this.isLoadingCSV = true;
            this.disableSend = true;
            this.errorDomain = [];
            this.checkCDPUsers(this.retValCSV.eids)
          }

        })

      } else {
        // reset eid  and file value
        if (count == 0) {
          this.csvError = 'No EIDs found.'
          this.buttonFileText = "Browse file"
          this.btnUpdate = false;
          this.isLoadingCSV = false;
          this.disableSend = true;
        }else{
          this.buttonFileText = this.retValCSV.fileName;
          this.isLoadingCSV = false;
          this.disableSend = false;
        }
      }
    } else {
      this.buttonFileText = this.retValCSV.fileName;
      this.isLoadingCSV = false;
      this.disableSend = false;
    }

  }

  removeInvalidEid(eidList, formatted, isrecepient, invalid, isFile) {
    if (invalid.length > 0) {
      console.log('formatted', formatted)
      console.log('inValidRecipients', invalid)
      let recipientsEID

      if(isFile){
        recipientsEID = eidList.split(';')

        invalid.forEach(eid => {
          let index = recipientsEID.indexOf(eid)
          if (index > -1) {
            recipientsEID.splice(index, 1);
          }
        });
      }

      if(isrecepient){
        this.retValCSV.eids = this.stringFormat(recipientsEID);
        console.log('formatted', recipientsEID)
      }

      return recipientsEID.length;
    }
  }

  filterEIDs(eids){
    eids = eids.toLowerCase().replace(/\s/g, "").split(';');
    let filtered = eids.filter(function (a) {
            if (!this.has(a)) {
                this.set(a, true);
                return true;
            } else{
              return false;
            }
        }, new Map);
    console.log(filtered);

    if (!filtered[filtered.length -1]) {
      filtered.splice(filtered.length -1, 1)
    }
    eids = filtered.join(';');
    return eids ;

  }


  stringFormat(recipients) {
    let retVal = '';

    recipients.forEach(eid => {
      retVal = retVal == '' ? eid : retVal += ';' + eid
    });

    return retVal;
  }
}
