import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  public show;

  @Output()
  public clicked: EventEmitter<any> = new EventEmitter();


  clickSave(event) {
    this.clicked.next(event);
  }
}
