import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { EsoService } from './eso.service';
import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { ReportsComponent } from '../admin/reports/reports.component';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanDeactivate<ReportsComponent> {

    constructor(
      public eso: EsoService,
      private oauthStorage: OAuthStorage
      ) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      this.setStorage(state.url)
      return true;
    }

    async canDeactivate(){
      this.oauthStorage.setItem('url', '');
      return true;
    }

    private setStorage(result){
      console.log(result);
      console.log('set value')

      this.oauthStorage.setItem('url', result);

    }
}
