<div class="publish">
    <div class="dialog-title">
        <span class="material-icons">
            <img src="assets/icons/publish-icon.svg" />
        </span>

        <!-- Appears only when there is no running transaction in the background -->
        <div *ngIf="!status">
          <div class="dialog-desc"> You are about to publish <br> this transaction</div>
          <div class="dialog-disclaimer">
              If you click Publish Transaction, your transaction will be sent to corresponding <br>
              recipients immediately.
          </div>
        </div>

        <div *ngIf="status">
          <div class="dialog-desc"> There's an ongoing <br>transaction being processed</div>
          <div class="dialog-disclaimer">
              Transactions can only be published one at a time. Please wait for<br>
              the ongoing transaction to finish before publishing another.
          </div>
        </div>


    </div>
    <div class="dialog-buttons">
        <div class="dialog-button" (click)="clickSave('publish')" [ngStyle]="{'pointer-events': status? 'none' : 'auto'}">
          <a [ngClass]="status? 'as-btn bg-gray' : 'as-btn bg-yellowgold' ">
          <!-- <a  class="as-btn bg-yellowgold" [ngClass]> -->
             <span>Publish Transaction</span>
            </a>
        </div>
        <div class="dialog-button" (click)="clickSave('cancel')">
            <a class="as-btn bg-bronze" routerLink="/preview" style="text-decoration: none">
                <span> Cancel </span>
            </a>
        </div>
    </div>


</div>
