<table class="transaction-table" mat-table [dataSource]="index == 0 ? dataSourcePending : dataSourcePublish" class="mat-elevation-z8">

  <ng-container matColumnDef="select">
    <th tabindex="0" aria-label="Select All" mat-header-cell *matHeaderCellDef>
      <mat-checkbox *ngIf="index===0"
                    class="header-checkbox"
                    (change)="$event ? masterToggle(0) : null"
                    [checked]="selection0.hasValue() && masterToggle0">
                    Select All
      </mat-checkbox>
      <mat-checkbox *ngIf="index===1"
              class="header-checkbox"
              (change)="$event ? masterToggle(1) : null"
              [checked]="selection1.hasValue() && masterToggle1">
              Select All
      </mat-checkbox>
    </th>
    <td tabindex="0" aria-labelledby="selection" mat-cell *matCellDef="let row" class="check-cell">
      <!-- <label for="selectTransaction" class="sr-only">Standard</label> -->
      <mat-checkbox id="" *ngIf="index===0"
                    (click)="$event.stopPropagation()"
                    (change)="deleteData($event, row, 0)"
                    (change)="$event ? selection0.toggle(row) : null"
                    [checked]="selection0.isSelected(row)">
    </mat-checkbox>
    <mat-checkbox id="" *ngIf="index===1"
                    (click)="$event.stopPropagation()"
                    (change)="deleteData($event, row, 1)"
                    (change)="$event ? selection1.toggle(row) : null"
                    [checked]="selection1.isSelected(row)">
                    </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="title">
    <th tabindex="0" aria-label="Title" mat-header-cell *matHeaderCellDef> Title </th>
    <td tabindex="0" aria-labelledby="element.title" id=""  mat-cell *matCellDef="let element;" class="transaction-cell"> {{element.title}} </td>
  </ng-container>

  <!--
  <ng-container matColumnDef="alertStatus">
    <th mat-header-cell *matHeaderCellDef> Verification Status </th>
    <td mat-cell *matCellDef="let element"> {{element.alertStatus}} </td>
  </ng-container>
-->
  <ng-container matColumnDef="alertID">
    <th tabindex="0" aria-label="Transaction ID" class = "transaction-th" mat-header-cell *matHeaderCellDef> Transaction ID</th>
    <td tabindex="0" aria-labelledby="element.alertID" id="" mat-cell *matCellDef="let element" class ="pencil-img" (click)="selectedCell(element)" >{{element.alertID}}</td>
  </ng-container>

  <ng-container matColumnDef="publisher">
    <th tabindex="0" aria-label="Publisher" mat-header-cell *matHeaderCellDef> Publisher </th>
    <td tabindex="0" aria-labelledby="element.publisher" id="" mat-cell *matCellDef="let element;"> {{element.publisher}} </td>
  </ng-container>

  <ng-container matColumnDef="ragStatus" >
    <th tabindex="0" aria-label="Rag Status" mat-header-cell *matHeaderCellDef>RAG Status </th>
    <td aria-labelledby="element.ragStatus" mat-cell *matCellDef="let element" class="dateCell">
      <div class = "grid-div" *ngIf="element.ragStatus; else noStatus">
        <div tabindex="0" class="ragStatusIcon" id="">
          <img role="img" aria-label="Resolved" *ngIf="element.ragStatus === 'Resolved'" class="png-icon" src={{green}} >
          <img role="img" aria-label="Awareness" *ngIf="element.ragStatus === 'Awareness'" class="png-icon" src={{green}} >
          <img role="img" aria-label="Warning" *ngIf="element.ragStatus === 'Warning'" class="png-icon" src={{orange}} >
          <img role="img" aria-label="Emerging Threat" *ngIf="element.ragStatus === 'Emerging Threat'" class="png-icon" src={{red}} >
          <img role="img" aria-label="Urgent" *ngIf="element.ragStatus === 'Urgent'" class="png-icon" src={{red}}>
          <img role="img" aria-label="CDP Red Accounts" *ngIf="element.ragStatus === 'CDP Red Accounts'" class="png-icon" src={{red}}>
          <img role="img" aria-label="Asset Controls" *ngIf="element.ragStatus === 'Asset Controls'" class="png-icon" src={{red}}>
        </div>
        <div tabindex="0" aria-labelledby="element.ragStatus" id="" class = "content-status">{{element.ragStatus | uppercase }}</div>
        <div class="pencilIcon" tabindex="0" aria-label="Edit Status">
          <img role="img" aria-label="Edit Status" class="pencil-img" (click)="openDialog(element)"  *ngIf="element.ragStatus != 'Resolved'" src={{pencil}}></div>
      </div>
    </td>
    <ng-template #noStatus>
      <div > - - - </div>
    </ng-template>
  </ng-container>

  <ng-container matColumnDef="expiration">
    <th tabindex="0" aria-label="Expiration Date" mat-header-cell *matHeaderCellDef> Expiration Date</th>
    <td tabindex="0" aria-labelledby="element.expiration" mat-cell *matCellDef="let element" class="dateCell">
      <div class = "grid-div">
        <div class="exp-date">
          <div class="undefinedDate" id="">{{element.expiration ? (element.expiration | date:'M/d/yyyy') : '- - -'}}</div>

        </div>
        <div class="pencilIcon-exp" tabindex="0" aria-label="Edit Expiration Date">
          <img role="img" aria-label="Edit icon"  *ngIf="element.ragStatus != 'Resolved' && element.alertType != 'EmergingThreat' " class="pencil-img" (click)="openDialogDate(element)" src={{pencil}}>
        </div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="modifiedDate">
    <th  tabindex="0" aria-label="Last Modified" mat-header-cell *matHeaderCellDef>
      <div *ngIf="index===0">Last Modified </div>
    </th>
    <td   tabindex="0" aria-labelledby="element.modifiedDate" mat-cell *matCellDef="let element">
      <div *ngIf="index===0" id="modifiedDate"> {{element.modifiedDate |date:'M/d/yyyy h:mm a'}} </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="publishedDate" >
    <th tabindex="0" aria-label="Published Date" mat-header-cell *matHeaderCellDef>
      Published Date
    </th>
    <td  tabindex="0" aria-labelledby="element.publishedDate" mat-cell *matCellDef="let element" class="dateCell">
      <div id=""> {{element.publishedDate ? (element.publishedDate |date:'M/d/yyyy') : '- - -'}} </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="followUpPublishedDate" >
    <th tabindex="0" aria-label="Follow-up Published Date" mat-header-cell *matHeaderCellDef>
      Follow-up <br> Published Date
    </th>
    <td  tabindex="0" aria-labelledby="element.modifiedDate" mat-cell *matCellDef="let element" class="dateCell">
      <div id=""> {{ element.hasFollowup === 'true' ? (element.modifiedDate |date:'M/d/yyyy, hh:mm a') : '- - -'}} </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="createdBy">
    <th tabindex="0" aria-label="Author" mat-header-cell *matHeaderCellDef> Author </th>
    <td tabindex="0" aria-labelledby="element.createdBy" id="createdBy" mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
  </ng-container>

  <ng-container matColumnDef="alertStatus">
    <th tabindex="0" aria-label="Status" mat-header-cell *matHeaderCellDef>Status </th>
    <td tabindex="0" aria-labelledby="element.alertStatus" id="alertStatus" mat-cell *matCellDef="let element">{{element.alertStatus}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"   ></tr>
</table>
