<div class="dialog-container">
    <div class="reminder" *ngIf="showReminder">
        <div class="dialog-title">
            <span class="material-icons">
                <img src="assets/icons/reminder_icon.png" style="height: 100px;"/>
            </span>
        
            <div class="dialog-desc">You are about to send a reminder notification for this transaction</div>
            <div class="dialog-disclaimer">If you click Send, a reminder notification will be sent to user(s) that have not responded.</div><br>
            <div class="dialog-disclaimer">If you click Upload CSV, a reminder notification will be sent to user(s) included in the file.</div>
        </div>
        <div class="dialog-buttons">
            <div class="dialog-button">
              <a class="as-btn bg-green" (click)="send()">
              <!-- <a  class="as-btn bg-yellowgold" [ngClass]> -->
                 <span>Send</span>
                </a>
            </div>
            <div class="dialog-button">
                <a class="as-btn bg-yellowgold" (click)="openReminderDialog()">
                   <span>Upload CSV</span>
                  </a>
              </div>
            <div class="dialog-button">
                <a class="as-btn bg-bronze" (click)="cancel()" style="text-decoration: none">
                    <span> Cancel </span>
                </a>
            </div>
        </div>
    </div>

    <div class="success" *ngIf="showSuccess">
        <div class="dialog-title">
            <span class="material-icons">
                check_circle_outline
            </span>
    
            <div class="dialog-desc"> Reminder notification has </div>
            <div class="dialog-desc"> been sent </div>
        </div>
        <div class="dialog-buttons">
    
            <div class="dialog-button">
                <a  class="as-btn success" (click)="cancel()">
                   <span> OK </span>
                </a>
            </div>
        </div>
    </div>
</div>


