import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import { AppConfigService } from './app/core/services/app-config.service';

if (environment.production) {
  enableProdMode();
}

function bootstrapApp() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
}

document.addEventListener('DOMContentLoaded', () => {
  // We bootstraping app from browser, we need fetch config.json.
  bootstrapApp();

  // fetch(AppConfigService.configPath)
  //   .then((response) => {
  //     return response.json();
  //   }).then((jsonData) => {
  //     sessionStorage[AppConfigService.configPath] = JSON.stringify(jsonData);
  //     bootstrapApp();
  //   }).catch((err) => {
  //     console.warn('caught when bootstrapping app');
  //     console.error(err);
  //   });
});

// document.addEventListener('DOMContentLoaded', function(){
//   var url = ''
//   var nfStyle = ''
//   if (!environment.production){
//     url = 'https://notificationsadmin.ciostage.accenture.com/widget/'
//     nfStyle = '<div id="acn-alert" data-widgetid="12202" data-appid="9521"></div>' +
//     '<link rel="stylesheet" href="https://notificationsadmin.ciostage.accenture.com/widget/acnalerts.css">' +
//     '<div id="acn-toast" data-widgetid="12368" data-appid="9521"></div>' +
//     '<link rel="stylesheet" href="https://notificationsadmin.ciostage.accenture.com/widget/acntoasts.css">' +
//     '<div id="acn-modal" data-widgetid="12369" data-appid="9521"></div>' +
//     '<link rel="stylesheet" href="https://notificationsadmin.ciostage.accenture.com/widget/acnmodal.css">'

//     document.body.insertAdjacentHTML('beforeend', nfStyle);
//   } else {
//     url = 'https://notificationsadmin.accenture.com/widget/'

//     nfStyle = '<div id="acn-alert" data-widgetid="2278" data-appid="607"></div>' +
//     '<link rel="stylesheet" href="https://notificationsadmin.accenture.com/widget/acnalerts.css">' +
//     '<div id="acn-toast" data-widgetid="2279" data-appid="607"></div>' +
//     '<link rel="stylesheet" href="https://notificationsadmin.accenture.com/widget/acntoasts.css">' +
//     '<div id="acn-modal" data-widgetid="2280" data-appid="607"></div>' +
//     '<link rel="stylesheet" href="https://notificationsadmin.accenture.com/widget/acnmodal.css">'

//     document.body.insertAdjacentHTML('beforeend', nfStyle);
//   }
//   let jsFiles = ["acnalerts.js", "acntoasts.js", "acnmodal.js"]

//   for (let index = 0; index < 3; ++index) {
//     var script = document.createElement('script');
//     script.src = url + jsFiles[index];
//     script.type='text/javascript';
//     document.body.appendChild(script);
//   }

// }, false);
