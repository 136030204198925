import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';

import { trigger, transition, state, animate, style, AnimationEvent } from '@angular/animations';
import { FormService } from 'src/app/core/form.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// import * as Quill from 'quill';
import * as XLSX from "xlsx";
import { MatDialog } from '@angular/material/dialog';
import { FileDialogComponent } from './file-dialog/file-dialog.component';
import { LoadingComponent } from '../sharedComponent/loading/loading.component';
import { Config } from 'src/app/core/form.config';
import { EmergingthreatComponent } from './emergingthreat/emergingthreat.component';
import { CompareComponent } from './compare/compare.component';
import { Overlay } from '@angular/cdk/overlay';
// import {phone} from 'phone';
// import { PhoneService } from 'src/app/core/phone.service';
import { NumberSymbol } from '@angular/common';




@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateComponent implements OnInit {
  isUnauthorized: boolean = false;
  public alerts = [];
  public errorDomain = [];
  public staticValues = {};
  public createTransaction: FormGroup;
  public submitted = false;
  public adNotDisplay = true;
  public adDisplay = false;
  public minDate = new Date();
  isCDPRed: boolean;
  cdpChecked: boolean;
  get f() { return this.createTransaction.controls; }
  htmlValue: any;
  public errorChecker = false;
  public isStandard: boolean = false;
  public detailedDescription: string = '<p>Your projected month-end ORI for one or more CDP accounts is currently red.</p>' +
  '<p><br></p>' +
  '<p><b>Action Required:</b> Please review your CDP account(s) and close out pending items.<p>'

  public tempShortDescription;
  public tempDetailedDescription;


  //public fileReaded = false;
  @ViewChild(EmergingthreatComponent) etComponent: EmergingthreatComponent;
  @ViewChild("quillEditor", {static: false}) quillEditor: ElementRef;
  @ViewChild("ragStatusGroup", {static: false}) ragStatusGroup: ElementRef;
  @ViewChild("ragStatusGroupET", {static: false}) ragStatusGroupET: ElementRef;

  public dialogRef: any;
  public compareDialog: any;

//_______________** custom charcter counters **______________
  public get counterTitle() {
    let retVal = 0;
    if (this.form.data.transaction.title) {
      let replaced = this.form.data.transaction.title;
      retVal = replaced.length;

    }

    return retVal;
  }

  public get counterDescription() {
    let retVal = 0;
    if (this.form.data.transaction.shortDescription) {
      let replaced = this.form.data.transaction.shortDescription.replace(/<[^>]+>/g, '');
      replaced = replaced.replace("&nbsp;", ' ');
      retVal = replaced.length + (this.form.data.transaction.shortDescription.split('</p><p>').length - 1);
      // retVal = replaced.length;
      if (retVal >= 200) {
        retVal = 200;
      } else {
        retVal = retVal;
      }
    }

    return retVal;
  }

  public get new() {
    let retVal = true;
    if (this.form.data.transaction.alertID) {
      retVal = false;
    }
    return retVal;
  }

  public get counterDetailedDescription() {
    let retVal = 0;
    if (this.form.data.transaction.bodyHtml) {
      let replaced = this.form.data.transaction.bodyHtml.replace(/<[^>]+>/g, '');
      replaced = replaced.replace("&nbsp;", ' ');
      retVal = replaced.length + (this.form.data.transaction.bodyHtml.split('</p><p>').length - 1);
      // retVal = replaced.length;
      if (retVal >= 1000) {
        retVal = 1000;
      } else {
        retVal = retVal;
      }

    }

    return retVal;
  }

  // public get CounterSmsMessage() {
  //   let retVal = 0;
  //   if (this.form.data.transaction.smsMessage) {
  //     let replaced = this.form.data.transaction.smsMessage.replace(/<[^>]+>/g, '');
  //     replaced = replaced.replace("&nbsp;", ' ');
  //     retVal = replaced.length + (this.form.data.transaction.smsMessage.split('</p><p>').length - 1);
  //     if (retVal >= 140) {
  //       retVal = 140;
  //     } else {
  //       retVal = retVal;
  //     }

  //   }

  //   return retVal;
  // }
  // public get questionCounter() {
  //   let retVal = 0;
  //   if (this.form.data.transaction.question) {
  //     let replaced = this.form.data.transaction.question;
  //     retVal = replaced.length;
  //     if (retVal >= 100) {
  //       retVal = 100;
  //     } else {
  //       retVal = retVal;
  //     }

  //   }

  //   return retVal;
  // }
  constructor(
    public form: FormService,
    private router: Router,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public overlay: Overlay,
    // public phone: PhoneService
  ) {


//_______________** initialization of form **______________
    this.createTransaction = this.formBuilder.group({
      alertType: ['', Validators.required],
      ragStatusEmergingThreat: ['', Validators.required],
      ragStatusStandard: ['', Validators.required],
      category: ['', Validators.required],
      recipient: [{ value: '', disabled: true }, [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@accenture.com(; ?\w+([-+.']\w+)*@accenture.com)*$/)]],
      recipientAll: [{ value: 'All Mobile Users', disabled: true }, [Validators.required]],
      // recipientAllsmishing: [{ value: 'All Smishing recipients', disabled: true }, [Validators.required]],
      recipientEmergingThreat: [{ value: '', disabled: true }, [Validators.required]],
      recipientFileHolder: [{ value: '', disabled: true }, [Validators.required]],
      recipientFileName: [{ value: '', disabled: true }, [Validators.required]],
      // fileNumbers: [{ value: '', disabled: true }, [Validators.required]],
      recipientSelect: ['', Validators.required],
      recipientSelectEmergingThreat: ['', Validators.required],
      recipientAD: ['', Validators.required],
      recipientsmishing: [{ value: '', disabled: true }, Validators.required],
      emergingQuestion: ['',],
      title: ['', Validators.required],
      shortDescription: ['', Validators.required],
      body: ['', Validators.required],
      // sms: [{ value: '', disabled: true }, Validators.required],
      expiration: ['',],
      reviewers: ['', [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@accenture.com(; ?\w+([-+.']\w+)*@accenture.com)*$/)]],
    });

    // this is for recipientSelect validator
    this.createTransaction.get('recipientSelect').valueChanges.subscribe(data => {
      console.log('recipientSelect', data)
      if (data) {
        this.form.data.transaction.isAD = false;
        if (data == 'ALL') {
          this.form.data.transaction.recipients.fileReaded = false;
          console.log(this.form.data.transaction.recipients.option)
          this.createTransaction.get('recipient').clearValidators();
          this.createTransaction.get('recipientFileName').clearValidators();
          this.createTransaction.get('recipientFileHolder').clearValidators();
          this.createTransaction.get('recipient').setValue('');
          this.createTransaction.get('recipientAD').clearValidators();
          this.resetFile();}

        // else if (data == 'allsmishing') {
        //     this.form.data.transaction.recipients.fileReaded = false;
        //     this.createTransaction.get('recipient').clearValidators();
        //     this.createTransaction.get('recipientFileName').clearValidators();
        //     this.createTransaction.get('recipientFileHolder').clearValidators();
        //     this.createTransaction.get('recipient').setValue('');
        //     this.createTransaction.get('recipientAD').clearValidators();
        //     this.resetFile();

        // }
        else if (data == 'eids') {
          this.form.data.transaction.recipients.fileReaded = false;
          this.createTransaction.get('recipientAll').clearValidators();
          // this.createTransaction.get('recipientAllsmishing').clearValidators();
          this.createTransaction.get('recipientFileName').clearValidators();
          this.createTransaction.get('recipientFileHolder').clearValidators();
          // this.createTransaction.get('recipientsmishing').updateValueAndValidity();
          // this.createTransaction.get('fileNumbers').disable();
          this.createTransaction.get('recipient').enable();
          this.createTransaction.get('recipient').setValidators([Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@accenture.com(; ?\w+([-+.']\w+)*@accenture.com)*$/)])
          this.createTransaction.get('recipientAD').clearValidators();
          //this.form.data.transaction.recipients.eid = '';
          this.resetFile();

        } else if (data == 'file') {
          this.createTransaction.get('recipientAll').clearValidators();
          // this.createTransaction.get('recipientAllsmishing').clearValidators();
          this.createTransaction.get('recipient').clearValidators();
          this.createTransaction.get('recipientFileName').disable();
          this.createTransaction.get('recipientFileName').setValidators([Validators.required])
          this.createTransaction.get('recipientFileHolder').setValidators([Validators.required])
          this.createTransaction.get('recipientAD').clearValidators();
        } else if(data == 'ad'){
          this.adNotDisplay =true;
          this.adDisplay = true;
          this.createTransaction.get('recipientAll').clearValidators();
          this.createTransaction.get('recipient').clearValidators();
          this.createTransaction.get('recipientFileName').disable();
          this.createTransaction.get('recipientFileName').clearValidators();
          this.createTransaction.get('recipientFileHolder').clearValidators();
          this.createTransaction.get('recipientAD').setValidators([Validators.required, Validators.pattern(/^\w+([-+.']\w+)*(; ?\w+([-+.']\w+)*)*$/)])
        }
        // else if(data == 'eidsmishing'){
        //   this.form.data.transaction.recipients.fileReaded = false;
        //   this.createTransaction.get('recipientAll').clearValidators();
        //   this.createTransaction.get('recipientFileName').clearValidators();
        //   this.createTransaction.get('recipientFileHolder').clearValidators();
        //   this.createTransaction.get('recipient').clearValidators();
        //   // this.createTransaction.get('recipientsmishing').enable();
        //   // this.createTransaction.get('recipientsmishing').setValidators([Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@accenture.com(; ?\w+([-+.']\w+)*@accenture.com)*$/)])
        //   this.createTransaction.get('recipientAD').clearValidators();
        //   //this.form.data.transaction.recipients.eid = '';
        //   this.resetFile();
        // }
        // else if (data == 'fileNumbers'){
        //   this.createTransaction.get('recipientsmishing').enable();
        //   this.clearValidators(['recipientAll', 'recipient', 'recipientsmishing', 'recipientAD'])
        //   this.createTransaction.get('recipientsmishing').updateValueAndValidity();
        //   this.createTransaction.get('fileNumbers').disable();
        //   this.createTransaction.get('fileNumbers').setValidators([Validators.required])
        //   this.createTransaction.get('recipientFileHolder').setValidators([Validators.required])
        // }
      }
      this.createTransaction.get('recipientAD').updateValueAndValidity();
      this.createTransaction.get('recipient').updateValueAndValidity();
      this.createTransaction.get('recipientFileName').updateValueAndValidity();
      this.createTransaction.get('recipientFileHolder').updateValueAndValidity();
      this.createTransaction.get('recipientAll').updateValueAndValidity();
      this.createTransaction.updateValueAndValidity();
      console.log(' value', this.form.data.transaction)

    })

    // for emerging threat alert validators
    this.createTransaction.get('recipientSelectEmergingThreat').valueChanges.subscribe(data => {
      console.log('recipientSelectEmergingThreat ', data)
      if (data) {

        this.form.data.transaction.isAD = false;
        if (data == 'eids') {
          this.createTransaction.get('recipientAll').clearValidators();
          this.createTransaction.get('recipientFileName').clearValidators();
          this.createTransaction.get('recipientFileHolder').clearValidators();
          this.createTransaction.get('recipient').enable();
          this.createTransaction.get('recipient').setValidators([Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@accenture.com(; ?\w+([-+.']\w+)*@accenture.com)*$/)])
          this.createTransaction.get('recipientAD').clearValidators();
          //this.form.data.transaction.recipients.eid = '';
          this.resetFile();
        } else if (data == 'file') {
          this.createTransaction.get('recipientAll').clearValidators();
          this.createTransaction.get('recipient').clearValidators();
          this.createTransaction.get('recipientFileName').disable();
          this.createTransaction.get('recipientFileName').setValidators([Validators.required])
          this.createTransaction.get('recipientFileHolder').setValidators([Validators.required])
          this.createTransaction.get('recipientAD').clearValidators();
        } else if (data == 'ad'){
          this.adNotDisplay =true;
          this.adDisplay = true;
          this.createTransaction.get('recipientAll').clearValidators();
          this.createTransaction.get('recipient').clearValidators();
          this.createTransaction.get('recipientFileName').disable();
          this.createTransaction.get('recipientFileName').clearValidators();
          this.createTransaction.get('recipientFileHolder').clearValidators();
          this.createTransaction.get('recipientAD').setValidators([Validators.required, Validators.pattern(/^\w+([-+.']\w+)*(; ?\w+([-+.']\w+)*)*$/)])
          this.form.data.transaction.isAD = true;
        }
      }
      this.createTransaction.get('recipientAD').updateValueAndValidity();
      this.createTransaction.get('recipient').updateValueAndValidity();
      this.createTransaction.get('recipientFileName').updateValueAndValidity();
      this.createTransaction.get('recipientFileHolder').updateValueAndValidity();
      this.createTransaction.get('recipientAll').updateValueAndValidity();
      this.createTransaction.get('recipientEmergingThreat').updateValueAndValidity();
      this.createTransaction.updateValueAndValidity();
      console.log(' value', this.form.data.transaction)

    })

    // for alert validators
    this.createTransaction.get('alertType').valueChanges.subscribe(data => {
      console.log('alertType', data)
      console.log('option value', this.form.data.transaction.recipients.option)

      if (data.type == "EmergingThreat") {
        this.clearValidators(['ragStatusStandard', 'ragStatusEmergingThreat',])

        this.createTransaction.get('recipientSelectEmergingThreat').setValue(this.form.data.transaction.recipients.option);
        this.createTransaction.get('recipientSelectEmergingThreat').setValidators([Validators.required])
        if (this.form.data.transaction.recipients.option == 'ALL') {
          console.log(this.form.data.transaction.recipients.option)
          this.form.data.transaction.recipients.option = '';
        }

        //this.setQAData();
        this.createTransaction.get('recipientSelect').clearValidators();
        this.isload = true


      } else if ((data.type == undefined && data != "EmergingThreat") || (data.type != undefined && data != "EmergingThreat")) {

        // if(data.type == 'SmishingTest'){
        //   this.form.data.transaction.categories = {
        //     type: "SmishingTest",
        //     description: "SMISHING Test (SMS)"
        //   }
        //   this.createTransaction.get('recipient').disable();
        //   this.clearValidators(['recipientFileName','recipientAD','ragStatusEmergingThreat','ragStatusStandard','category','title','shortDescription','body', 'recipientSelectEmergingThreat'])
        //   this.createTransaction.get('recipientSelect').setValidators([Validators.required])
        //   this.createTransaction.get('recipientSelect').setValue(this.form.data.transaction.recipients.option);
        //   this.createTransaction.get('sms').enable();
        //   this.createTransaction.get('sms').setValidators([Validators.required])

        //   this.createTransaction.get('reviewers').setValidators ([Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@accenture.com(; ?\w+([-+.']\w+)*@accenture.com)*$/)])
        // }
        // else{
          this.clearValidators(['ragStatusEmergingThreat'])
          this.createTransaction.get('ragStatusStandard').setValidators([Validators.required])
          this.createTransaction.get('recipientSelect').setValidators([Validators.required])
          this.createTransaction.get('recipientSelect').setValue(this.form.data.transaction.recipients.option);
          this.createTransaction.get('recipientSelectEmergingThreat').clearValidators();


        if (this.form.data.transaction.ragStatus == 'Emerging Threat') {
          this.form.data.transaction.ragStatus = 'Urgent';
        }
        this.isload = false
      }

      this.createTransaction.get('recipientSelectEmergingThreat').updateValueAndValidity();
      this.createTransaction.get('recipientSelect').updateValueAndValidity();
      this.createTransaction.get('ragStatusStandard').updateValueAndValidity();
      this.createTransaction.get('ragStatusEmergingThreat').updateValueAndValidity();
      this.createTransaction.get('category').updateValueAndValidity();
      this.createTransaction.get('title').updateValueAndValidity();
      this.createTransaction.get('shortDescription').updateValueAndValidity();
      this.createTransaction.get('body').updateValueAndValidity();
      // this.createTransaction.get('sms').updateValueAndValidity();

      this.createTransaction.updateValueAndValidity();
    })
  }

  private resetFile() {
    this.form.data.transaction.recipients.fileValue = '';
    this.form.data.transaction.recipients.fileName = '';
  }

  private clearValidators(object) {
    for (let j = 0; j < object.length; j++) {
      const element = object[j];
      this.createTransaction.get(element).clearValidators();
    }
  }
  isload: boolean = false
  ngOnInit(): void {
    this.isUnauthorized = true;
    console.log("TEST isSuperAdmin:", this.form.data.user.isSuperAdmin);
    console.log("TEST isStandardAdmin:", this.form.data.user.isStandardAdmin);
    console.log("TEST isCDP", this.form.data.isCDP);
    if(this.form.data.user.isSuperAdmin == false && this.form.data.user.isStandardAdmin == false){
      if(this.form.data.isCDP){
        // this.router.navigateByUrl('/emergingthreatresponse');
      }else{
        //redirect to unauthorized page
        this.router.navigateByUrl('/unauthorized');
      }
    }else{
      this.isUnauthorized = false;
    // this.form.data.user.isEmergingThreat = true;
    if (!this.form.data.user.isEmergingThreat) {
      this.form.config.alerts = this.form.config.alerts.filter(item => item.type !== "EmergingThreat")
    }
    const loading = this.dialog.open(LoadingComponent, { panelClass: 'custom-dialog' });

    //this.form.getUserDetails().subscribe((user) => { console.log("form user service", user) })
    if (this.form.data.transaction == undefined) {
      this.form.data.transaction = {};
      this.form.data.transaction.alertType = this.form.config.alerts[0];
      this.form.data.transaction.ragStatus = this.form.data.user.isEmergingThreat ? "Emerging Threat" : "Urgent"
      this.form.data.transaction.recipients = {}
      this.isload = true
    } else {
      console.log("supply data in the form" , this.form.data.transaction )
      if (this.form.data.transaction.alertType.type == 'EmergingThreat') this.setQAData();
      if (this.form.data.transaction.recipients.fileName) {
        this.form.data.transaction.recipients.fileReaded = true;
        this.createTransaction.get('recipientFileName').setValue(this.form.data.transaction.recipients.fileName)
      }
      // if (this.form.data.transaction.recipients.fileNumbers) {
      //   this.form.data.transaction.recipients.fileReaded = true;
      //   this.createTransaction.get('fileNumbers').setValue(this.form.data.transaction.recipients.fileNumbers)
      // }
      if (this.form.data.transaction.alertStatus == Config.AlertStatus.Verified) {
        this.createTransaction.get('reviewers').disable();
      }

    }

    //test
    //this.form.data.user.isSuperAdmin = false;
    // this.form.data.user.isEmergingThreat = true;
    //if (!this.form.data.user.isSuperAdmin) {



    // nilagay ko to para chill tignan hahahaha.
    // call api by alertID.

    setTimeout(() => {
       this.setAccessibility();
      //}
      loading.close();
    }, 2000)

  }}

  editor_modules = {
    toolbar: false

  }


  public setAccessibility(){
    if(this.quillEditor){
      const queryQL =   this.quillEditor.nativeElement.getElementsByClassName('ql-toolbar');
      if (queryQL.length !== 1) {
        return;
      }

      const qltoolBar = queryQL[0];

      // apply aria labels to base buttons
      const buttons = qltoolBar.getElementsByTagName('button');
      for (let i = 0; i < buttons.length; i++) {
        const button = buttons[i];
        const className = button.getAttribute('class').toLowerCase();

        if (className.indexOf('bold') >= 0) {
          button.setAttribute('aria-label', 'Toggle bold text');
        } else if (className.indexOf('italic') >= 0) {
          button.setAttribute('aria-label', 'Toggle italic text');
        } else if (className.indexOf('underline') >= 0) {
          button.setAttribute('aria-label', 'Toggle underline text');
        } else if (className.indexOf('clean') >= 0) {
          button.setAttribute('aria-label', 'Toggle clean text');
        } else if (className.indexOf('link')>= 0) {
          button.setAttribute('aria-label', 'Toggle link text');
        }
      }

      qltoolBar.removeAttribute(qltoolBar.getElementsByClassName('ql-preview', 'href'))
      if(this.form.data.transaction.alertType.type != 'SmishingTest'){
        const isET = this.form.data.transaction.alertType.type == 'EmergingThreat'
        const ragStatusRd = isET ? this.ragStatusGroupET.nativeElement.getElementsByTagName('input') : this.ragStatusGroup.nativeElement.getElementsByTagName('input');
        let newlabel;
        let ctr = 0
        for (let j = 0; j < ragStatusRd.length; j++) {
          newlabel = document.createElement("Label");
          ctr = j + 2
          newlabel.setAttribute('for', 'mat-radio-' + ctr + '-input');
          newlabel.setAttribute('class', 'sr-only');
          newlabel.innerHTML = j == 0 ? 'Urgent' : j == 1 ? 'Warning' : 'CDP Red Accounts'
          ragStatusRd[j].parentElement.insertBefore(newlabel, ragStatusRd[j].parentElement.children[2]);
        }
        newlabel = null
      }
    }
  }

  public toolbarOptions = [['bold', 'italic', 'underline'], ['clean'], ['link']];

  public quillLinkRemove = {
    'link': function (value: string) {
      const that: any = this;

      const tooltip = that.quill.theme.tooltip;
      const input = tooltip.root.querySelector("input[data-link]");
      input.dataset.link = "https://";
      input.placeholder = "https://";
      input.dataset.lpignore = true;


      if (value) {
        const range = that.quill.getSelection();
        if (range == null || range.length === 0) { return; }
        let preview = that.quill.getText(range);
        if (
          /^\S+@\S+\.\S+$/.test(preview) &&
          preview.indexOf("mailto:") !== 0
        ) {
          preview = `mailto:${preview}`;

        }
        const { tooltip } = that.quill.theme;
        tooltip.edit("link", preview);
        input.value = "https://" + preview;


      } else {
        that.quill.format("link", false);
      }
    },
  }
  editor_detail = {
    toolbar: {
      container: this.toolbarOptions,  // Selector for toolbar container
      handlers: this.quillLinkRemove
    }

  }

  textChanged($event, data) {

    if (data === "short") {
      if ($event.editor.getLength() > 200) {
        $event.editor.deleteText(200, $event.editor.getLength());
        if($event.source == 'api'){
          this.form.data.transaction.shortDescription = $event.html;
        }
        console.log($event);
      }
    }
    //  else if(data == 'sms'){
    //   if ($event.editor.getLength() > 1000) {
    //     $event.editor.deleteText(1000, $event.editor.getLength());
    //     if($event.source == 'api'){
    //       this.form.data.transaction.smsMessage = $event.text;
    //     }
    //   }
    // }
    else {
      console.log($event);
      if ($event.editor.getLength() > 1000) {
        $event.editor.deleteText(1000, $event.editor.getLength());
        if($event.source == 'api'){
          this.form.data.transaction.bodyHtml = $event.html;
        }
      }

    }
  }

  focusout(e) {
    if(e == 'reviewers'){
      if (this.form.data.transaction.reviewers) {
        this.form.data.transaction.reviewers = this.form.data.transaction.reviewers.toLowerCase();
        this.form.data.transaction.reviewers = this.filterEIDs(this.form.data.transaction.reviewers);
        let text = 'The following email addresses will not receive this for review notification because <br>they do not have the Always Safe Mobile application installed <br>on their device or there is a spelling error.'
        this.compare(this.form.data.transaction.reviewers, false,false, text);
      }
    }else {
      if (this.form.data.transaction.recipients.eid && this.form.data.transaction.recipients.option == 'eids') {
        this.form.data.transaction.recipients.eid = this.form.data.transaction.recipients.eid.toLowerCase();
        this.form.data.transaction.recipients.eid = this.filterEIDs(this.form.data.transaction.recipients.eid);
        let text = 'The following email addresses will not receive this notification because  <br>they do not have the Always Safe Mobile application installed <br>on their device or there is a spelling error.'
        this.compare(this.form.data.transaction.recipients.eid, false,true, text);
      } else if (this.form.data.transaction.recipients.option == 'ad' && this.form.data.transaction.recipients.ad) {
        this.form.data.transaction.recipients.ad = this.filterEIDs(this.form.data.transaction.recipients.ad);
      }
      // else if (this.form.data.transaction.recipients.eidsmishing && this.form.data.transaction.recipients.option == 'eidsmishing'){
      //   this.form.data.transaction.recipients.eidsmishing = this.form.data.transaction.recipients.eidsmishing.toLowerCase();
      //   this.form.data.transaction.recipients.eidsmishing = this.filterEIDs(this.form.data.transaction.recipients.eidsmishing);
      //   let text = 'The following email addresses will not receive this notification because  <br>they do not have the Always Safe Mobile application installed <br>or there is a spelling error.'
      //   this.compare(this.form.data.transaction.recipients.eidsmishing, false,true, text);

      //   // this.form.data.transaction.recipients.eidsmishing = this.phone.removeDuplicates(this.form.data.transaction.recipients.eidsmishing)
      //   // let x = this.phone.filterInvalidNumbers(this.form.data.transaction.recipients.eidsmishing);
      //   // if (x.length > 0){
      //   //  this.form.data.temporary = x;
      //   //  this.form.data.compare = { text: 'The following mobile numbers are invalid!!'};
      //   //  let temp = this.form.data.transaction.recipients.eidsmishing.split(';')
      //   //  x.forEach(invalidnumbers=>{
      //   //   temp = temp.filter(number=> number!= invalidnumbers)
      //   //  })
      //   //   this.compareDialog = this.dialog.open(CompareComponent, {
      //   //     disableClose: true,
      //   //     data: {
      //   //       buttonText: {
      //   //         cancel: 'Cancel',
      //   //       },
      //   //     },
      //   //   });
      //   //   this.compareDialog.afterClosed().subscribe(closed => {
      //   //     this.form.data.transaction.recipients.eidsmishing = temp.join(';');
      //   //     this.form.data.temporary = []
      //   //     this.openafterClose("numbers")
      //   //   })
      //   // } else {
      //   //   this.openafterClose("numbers")
      //   // }
      // } else if (this.form.data.transaction.recipients.eid && this.form.data.transaction.recipients.option == 'eids' && this.form.data.transaction.alertType.type === "SmishingTest"){
      // const loading = this.dialog.open(LoadingComponent, { panelClass: 'custom-dialog', disableClose: true });
      //   this.form.data.transaction.recipients.eid = this.form.data.transaction.recipients.eid.toLowerCase();
      //   this.form.data.transaction.recipients.eid = this.filterEIDs(this.form.data.transaction.recipients.eid);
      //   let text = "The following email addresses will not receive this notification because  <br>they do not have the Always Safe Mobile application installed <br>on their device or there is a spelling error."
      //   this.form.data.compare = {text: text}
      //   let format = this.removeAccenture(this.form.data.transaction.recipients.eid).stringFormat

      //   this.compare(this.form.data.transaction.recipients.eid, false, true, text)
      //   loading.close()
      // }
    }
  }
  // openafterClose(type){
  //   console.log("recipient: ", this.form.data.transaction)
  //   if(type == "numbers"){
  //     const loading = this.dialog.open(LoadingComponent, { panelClass: 'custom-dialog', disableClose: true });
  //     let text = 'The following mobile numbers will not receive this notification because  <br>they are not enrolled in Smish Me Program <br>in their Application or there is a spelling error.'
  //     this.form.data.compare = {text: text}

  //     let tempNumbers
  //     let format

  //     if(this.form.data.transaction.recipients.option == "fileNumbers"){
  //       this.form.data.transaction.recipients.fileValue = this.filterEIDs(this.form.data.transaction.recipients.fileValue);
  //       tempNumbers = this.form.data.transaction.recipients.fileValue.split(';')
  //       format = tempNumbers.join(',');
  //     }
  //     // if(this.form.data.transaction.recipients.option == "eidsmishing"){
  //     //   this.form.data.transaction.recipients.numbers = this.filterEIDs(this.form.data.transaction.recipients.eidsmishing);
  //     //   tempNumbers = this.form.data.transaction.recipients.eidsmishing.split(';')
  //     //   format = tempNumbers.join(',');
  //     // }
  //     let tobesent = {
  //       type: 'numbers',
  //       recipient: format
  //     }
  //     let validString
  //     this.form.checkUserPhoneNumbers(tobesent).subscribe((data:any)=>{
  //       loading.close();
  //       validString = data.validString
  //       if(this.form.data.temporary.length > 0){
  //         let newcompareDialogNum = this.dialog.open(CompareComponent, {
  //           disableClose: true,
  //           data: {
  //             buttonText: {
  //               cancel: 'Cancel',
  //             },
  //           },
  //         });
  //         newcompareDialogNum.afterClosed().subscribe(closed => {
  //           this.form.data.temporary = []
  //           this.form.data.transaction.recipients.numbers = validString
  //           //do not remove
  //           if(this.form.data.transaction.recipients.option == "fileNumbers"){
  //             this.form.data.transaction.recipients.fileValue = validString
  //             if(validString == ''){
  //                 this.form.data.transaction.recipients.fileName = '',
  //                 this.form.data.transaction.recipients.fileReaded = false
  //             }
  //           }
  //           // this.form.data.transaction.recipients.fileValue = validString
  //         })
  //       }
  //     });
  //   } else if(type == "eids") {
  //     const loading = this.dialog.open(LoadingComponent, { panelClass: 'custom-dialog', disableClose: true });
  //     let text = 'The following email addresses will not receive this notification because  <br>they do not have saved mobile number <br>in their Application or there is a spelling error.'
  //     this.form.data.compare = {text: text}
  //     let tempNumbers
  //     let format
  //     if(this.form.data.transaction.recipients.option == "eids"){
  //       this.form.data.transaction.recipients.eid = this.filterEIDs(this.form.data.transaction.recipients.eid);
  //       tempNumbers = this.form.data.transaction.recipients.eid.split(';')
  //       format = this.removeAccenture(this.form.data.transaction.recipients.eid).stringFormat
  //     }
  //     if(this.form.data.transaction.recipients.option == "file"){
  //       this.form.data.transaction.recipients.fileValue = this.filterEIDs(this.form.data.transaction.recipients.fileValue);
  //       tempNumbers = this.form.data.transaction.recipients.fileValue.split(';')
  //       format = this.removeAccenture(this.form.data.transaction.recipients.fileValue).stringFormat
  //     }
  //     let tobesent = {
  //       type: 'eids',
  //       recipient: format
  //     }
  //     let validString
  //     this.form.checkUserPhoneNumbers(tobesent).subscribe((data:any)=>{
  //       validString = data.validString
  //       loading.close();
  //       if(this.form.data.temporary.length > 0){
  //         let newcompareDialog = this.dialog.open(CompareComponent, {
  //           disableClose: true,
  //           data: {
  //             buttonText: {
  //               cancel: 'Cancel',
  //             },
  //           },
  //         });
  //         newcompareDialog.afterClosed().subscribe(closed => {
  //           this.form.data.temporary = []
  //           if(this.form.data.transaction.recipients.option == "eids")
  //             this.form.data.transaction.recipients.eid = validString
  //           if(this.form.data.transaction.recipients.option == "file"){
  //             this.form.data.transaction.recipients.fileValue = validString
  //             if(validString == ''){
  //                 this.form.data.transaction.recipients.fileName = '',
  //                 this.form.data.transaction.recipients.fileReaded = false
  //             }
  //           }
  //         })
  //       }
  //     });
  //   }
  // }

  isAD(){
    this.form.data.transaction.isAD = true;
    this.adNotDisplay =true;
    this.adDisplay = true;
  }

  // private smscompare(){
  //   this.form.checkSMSRecipients(this.form.data.transaction.recipients.eidsmishing, this.form.data.user.eid).subscribe(data => {
  //     console.log("DATAAAAAA",data)
  //     this.form.data.compare = { text: 'The following email addresses will not receive this notification because  <br>they are not enrolled in Smish Me Program <br>or there is a spelling error.'};
  //     this.form.data.temporary = data.errorEids;
  //     if (this.form.data.temporary.length > 0) {
  //       this.compareDialog = this.dialog.open(CompareComponent, {
  //         disableClose: true,
  //         data: {
  //           buttonText: {
  //             cancel: 'Cancel',
  //           },
  //         },
  //       });
  //     }

  //     this.form.data.transaction.recipients.eidsmishing = this.SMSstringFormat(data.successEids)

  //   })
  // }

  private compare(eidlist, isFile, isrecipient, text) {
    if(this.form.data.transaction.alertType.type == 'EmergingThreat'){
      this.checkCDPUsers(isFile, isrecipient)
    } else {
    this.cdpChecked = false
    console.log (eidlist);
    if (eidlist) {
      const loading = this.dialog.open(LoadingComponent, { panelClass: 'custom-dialog', disableClose: true });

      let formatedArray = this.removeAccenture(eidlist);
      let formated = {
        eids: formatedArray.stringFormat
      }
      if(formatedArray.arrayFormat.length > 0){
        // if (this.form.data.transaction.recipients.option == "eidsmishing"){
        //   this.form.checkRecipients(this.form.data.user.eid, formated).subscribe(data => {
        //     if (data) {

        //       this.form.data.temporary = this.newCompare(this.form.data.temporary, formated.eids);
        //       this.form.data.temporary = this.form.data.temporary.concat(this.errorDomain);
        //       console.log("NIX", this.form.data.temporary)
        //       loading.close();
        //       this.continueCompare(eidlist, isFile, isrecipient, text,formatedArray, false)
        //       console.log("RECIPIENTS", this.form.data.transaction)

        //       if(this.form.data.temporary.length == 0 ){
        //         this.smscompare();
        //       }
        //     }
        //   })
        // }
        // else{
          this.form.checkRecipients(this.form.data.user.eid, formated).subscribe(data => {
            if (data) {
              this.form.data.temporary = this.newCompare(this.form.data.temporary, formated.eids);
              this.form.data.temporary = this.form.data.temporary.concat(this.errorDomain);
              loading.close();
              this.continueCompare(eidlist, isFile, isrecipient, text,formatedArray, false)
            }
          })


      } else {
        loading.close();
        let text = 'The following email addresses will not receive this notification because  <br>they do not have the Always Safe Mobile application installed <br>on their device or there is a spelling error.'
        this.continueCompare(eidlist, isFile, isrecipient, text,formatedArray, true)
      }
    }
  }
}

  private newCompare(returnData, formattedEIDs){
    let eidList = formattedEIDs.split(',');
    let eids = []
    for (let j = 0; j < eidList.length; j++) {
        //trim
        let eid = eidList[j].trim();
        eid = eid.toLowerCase() + "@accenture.com";
        if (eid) {
            eids.push(eid);
        }
    }

    // for testing (IP)
    let uniqueEID = Array.from(new Set(eids));
    console.log('uniqueeid count', uniqueEID.length);

    let retVal = uniqueEID.filter(function (obj) {
      if(returnData){
        return returnData.indexOf(obj) == -1;
      } else {
        return returnData.indexOf(obj) == 1
      }
    });

    let tempArray = []
    retVal.forEach(element => {
      if(!element.includes("@accenture.com")){
        tempArray.push(element + "@accenture.com")
      }else{
        tempArray.push(element)
      }
    });

    return tempArray;
  }

  continueCompare(eidlist, isFile, isrecipient, text,formatedArray, isCDP) {

    if (this.form.data.temporary.length > 0) {

      this.form.data.compare = { text: text }

      let count = 0;
      count = this.removeInvalidEid(formatedArray.arrayFormat, isrecipient, this.form.data.temporary, isFile);


      if (this.form.data.temporary.length > 0) {
        this.compareDialog = this.dialog.open(CompareComponent, {
          disableClose: true,
          data: {
            buttonText: {
              cancel: 'Cancel',
            },
          },
        });
        this.compareDialog.afterClosed().subscribe(closed => {
            // this.smscompare();
          //check if eid has number
          // if((this.form.data.transaction.recipients.option == "eids" || this.form.data.transaction.recipients.option == "file") && this.form.data.transaction.alertType.type == "SmishingTest")
          //   this.openafterClose("eids")

          if (count == 0) {
            if(isrecipient){
              if (isFile) {
                this.form.data.transaction.recipients.fileReaded = false;
                this.createTransaction.get('recipientFileName').setValue('');
                this.createTransaction.get('recipientFileName').setValidators([Validators.required]);
                this.createTransaction.get('recipientFileName').enable();
              } else {
                // if (this.form.data.transaction.recipients.eidsmishing && this.form.data.transaction.recipients.option == 'eidsmishing'){
                //   this.createTransaction.get('recipientsmishing').enable();
                //   this.createTransaction.get('recipientsmishing').setValue('');
                //   this.createTransaction.get('recipientsmishing').setValidators([Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@accenture.com(; ?\w+([-+.']\w+)*@accenture.com)*$/)])
                //   //this.form.data.transaction.recipients.eid = '';
                //   this.resetFile();
                // }
                // else{
                  this.createTransaction.get('recipient').enable();
                  this.createTransaction.get('recipient').setValue('');
                  this.createTransaction.get('recipient').setValidators([Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@accenture.com(; ?\w+([-+.']\w+)*@accenture.com)*$/)])
                  //this.form.data.transaction.recipients.eid = '';
                  this.resetFile();

              }
            }else {
              this.createTransaction.get('recipient').enable();
              this.createTransaction.get('reviewers').setValue('');
              this.createTransaction.get('reviewers').setValidators([Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@accenture.com(; ?\w+([-+.']\w+)*@accenture.com)*$/)]);
            }

          }

          if(isrecipient && (this.form.data.transaction.ragStatus == 'Emerging Threat' || this.isCDPRed)) {
            if (!isCDP) {
              this.checkCDPUsers(isFile, isrecipient)
            }
          }

        })

      } else {
        // reset eid  and file value
        if (count == 0) {
          if(isrecipient){
            if (isFile) {
              this.form.data.transaction.recipients.fileReaded = false;
              this.createTransaction.get('recipientFileName').setValue('');
              this.createTransaction.get('recipientFileName').setValidators([Validators.required]);
              this.createTransaction.get('recipientFileName').enable();
            } else {
              this.createTransaction.get('recipient').enable();
              this.createTransaction.get('recipient').setValue('');
              this.createTransaction.get('recipient').setValidators([Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@accenture.com(; ?\w+([-+.']\w+)*@accenture.com)*$/)])
              //this.form.data.transaction.recipients.eid = '';
              this.resetFile();
            }
          }else {
            this.createTransaction.get('recipient').enable();
            this.createTransaction.get('reviewers').setValue('');
            this.createTransaction.get('reviewers').setValidators([Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@accenture.com(; ?\w+([-+.']\w+)*@accenture.com)*$/)]);
          }
        }
      }
    } else {
      //check if eid has number
      // if((this.form.data.transaction.recipients.option == "eids" || this.form.data.transaction.recipients.option == "file") && this.form.data.transaction.alertType.type == "SmishingTest" && this.form.data.transaction.recipients.eid != "")
      //     this.openafterClose("eids")
      if(isrecipient && (this.form.data.transaction.ragStatus == 'Emerging Threat' || this.isCDPRed)) {
        if (!this.cdpChecked) {
          this.checkCDPUsers(isFile, isrecipient)
        }
      }

    }
  }

  checkCDPUsers(isFile, isrecipient=true) {
    let eidlist;
    // let reviewText = ' ';
    if (!isFile && isrecipient) {
      eidlist = this.form.data.transaction.recipients.eid
    } else if (isFile && isrecipient) {
      eidlist = this.form.data.transaction.recipients.fileValue
    } //else if (!isFile && !isrecipient) {
    //   reviewText = ' review '
    //   eidlist = this.form.data.transaction.reviewers
    // }
    if (eidlist) {
      const loading = this.dialog.open(LoadingComponent, { panelClass: 'custom-dialog', disableClose: true });

      let formatedArray = this.removeAccenture(eidlist);
        let formated = {
          eids: formatedArray.stringFormat
        }
        if(formatedArray.arrayFormat.length > 0){
          this.form.checkCDPUsers(formated).subscribe(data => {
            if (data) {
                loading.close();
                this.cdpChecked = true
                //let text = 'The following EID(s) will not receive this' + reviewText + 'notification because they are not CDP users.'
                let text = 'The following EID(s) will not receive this notification because they are not CDP users.'
                this.continueCompare(eidlist, isFile, isrecipient, text,formatedArray, true)
                console.log(this.form.data.temporary);
            }
          });
        } else {
          let text = 'The following EID(s) will not receive this notification because they are not CDP users.'
          this.continueCompare(eidlist, isFile, isrecipient, text,formatedArray, true)
          loading.close();
        }
    }
  }

  removeInvalidEid(formatted, isrecepient, invalid, isFile) {
    if (invalid.length > 0) {
      console.log('formatted1', formatted, isrecepient, invalid, isFile)
      console.log('inValidRecipients', invalid)
      let recipientsEID

      if(isFile){
         recipientsEID =  this.form.data.transaction.recipients.fileValue.split(';')

        invalid.forEach(eid => {
          let index = recipientsEID.indexOf(eid)
          if (index > -1) {
            recipientsEID.splice(index, 1);
          }
        });
      } else {
        recipientsEID = this.form.data.transaction.recipients.eid ? this.form.data.transaction.recipients.eid.split(';') : this.form.data.transaction.recipients.eidsmishing.split(';')

        invalid.forEach(eid => {
          let index = recipientsEID.indexOf(eid)
          if (index > -1) {
            recipientsEID.splice(index, 1);
          }
        });
      }

      // invalid.forEach(eid => {
      //   let index = formatted.indexOf(eid)
      //   console.log("in the loop1: ", eid, index, formatted)
      //   if (index > -1) {
      //     formatted.splice(index, 1);
      //   }
      // });



      if(isrecepient){
        if (!isFile) {
          // if(this.form.data.transaction.recipients.option == "eidsmishing"){
          //   this.form.data.transaction.recipients.eidsmishing = this.stringFormat(recipientsEID);
          //   console.log("recipientsEID", this.form.data.transaction.recipients.eidsmishing)
          // }else{
            this.form.data.transaction.recipients.eid = this.stringFormat(recipientsEID);
            console.log("recipientsEID", this.form.data.transaction.recipients.eid)
        // }
        } else {
          this.form.data.transaction.recipients.fileValue = this.stringFormat(recipientsEID);
        }
        console.log('formatted2', recipientsEID)
      }else{
        recipientsEID = this.form.data.transaction.reviewers.split(';')
        invalid.forEach(eid => {
          let index = recipientsEID.indexOf(eid)
          if (index > -1) {
            recipientsEID.splice(index, 1);
          }
        });
        this.form.data.transaction.reviewers = this.stringFormat(recipientsEID);
      }


      return recipientsEID.length;
    }
  }

  // removeInvalidEidNumbers(formatted, type, invalid, isFile) {
  //   if (invalid.length > 0) {
  //     console.log('formatted3', formatted)
  //     console.log('inValidRecipients', invalid)
  //     let recipientsEID

  //     if(isFile){
  //       recipientsEID = this.form.data.transaction.recipients.fileValue.split(';')

  //       invalid.forEach(eid => {
  //         let index = recipientsEID.indexOf(eid)
  //         if (index > -1) {
  //           recipientsEID.splice(index, 1);
  //         }
  //       });
  //       console.log("remove invalid", recipientsEID)
  //     }

  //     // invalid.forEach(eid => {
  //     //   let index = formatted.indexOf(eid)
  //     //   console.log("in the loop1: ", eid, index, formatted)
  //     //   if (index > -1) {
  //     //     formatted.splice(index, 1);
  //     //   }
  //     // });

  //     return recipientsEID.length;
  //   }
  // }

  stringFormat(recipients) {
    let retVal = '';
    // recipients.forEach(eid => {
    //   retVal = retVal == '' ? eid + "@accenture.com" : retVal += '; ' + eid + "@accenture.com"
    // });

    recipients.forEach(eid => {
      retVal = retVal == '' ? eid : retVal += '; ' + eid
    });

    return retVal;
  }

  // SMSstringFormat(recipients) {
  //   let retVal = '';

  //   recipients.forEach(successeid => {
  //     retVal = retVal == '' ? successeid.eid + "@accenture.com" : retVal += '; ' + successeid.eid + "@accenture.com"
  //   });

  //   // recipients.forEach(successeid => {
  //   //   retVal = retVal == '' ? successeid.eid : retVal += '; ' + successeid.eid
  //   // });

  //   return retVal;
  // }



  removeAccenture(recipients) {
    this.errorDomain = []
    let rArray = recipients.split(';');

    let retVal = {
      stringFormat: '',
      arrayFormat: []
    };
    rArray.forEach(element => {
      if(element.indexOf('@') != -1){
        let value = element.split('@')[0].trim().toLowerCase();
        let domain = element.split('@')[1].trim().toLowerCase();
        if(element.split('@').length == 2){
          if(domain && domain == "accenture.com"){
            if (value) {
              retVal.stringFormat = retVal.stringFormat == '' ? retVal.stringFormat + value : retVal.stringFormat + ',' + value
              retVal.arrayFormat.push(value);
            } else {
              this.errorDomain.push(element)
            }
          } else {
            this.errorDomain.push(element)
          }
        }else{
          this.errorDomain.push(element)
        }
      } else {
        this.errorDomain.push(element)
      }
    });

    retVal.arrayFormat = Array.from(new Set(retVal.arrayFormat));
    this.form.data.temporary = this.errorDomain

    return retVal;
  }

  shortStyle = {
    height: '100px',
    background: '#fff'
  }
  detailStyle = {
    height: '200px',
    background: '#fff'
  }

  typeChange(event) {
    console.log(event);
    this.form.data.transaction.title = ''
    this.form.data.transaction.shortDescription = ''
    this.form.data.transaction.bodyHtml = ''
    // this.form.data.transaction.smsMessage = ''
    this.form.data.transaction.expiration = ''
    this.form.data.transaction.etStatus = ''

    if (event.type == 'EmergingThreat'){
      this.form.data.transaction.ragStatus = 'Emerging Threat'
      console.log("this EEEETT", this.createTransaction.controls)
      if (this.isCDPRed) {
        this.form.data.transaction.title = ''
        this.form.data.transaction.shortDescription = ''
        this.form.data.transaction.bodyHtml = ''
        // this.form.data.transaction.smsMessage = ''
      }
    }
    // else if (event.type == 'SmishingTest'){
    //   if(this.etComponent){
    //     this.etComponent.createTransaction.markAllAsTouched();
    //   }
    //   this.form.data.transaction.title = 'Smishing Test'
    //   this.form.data.transaction.ragStatus = ''
    //   this.form.data.transaction.categories = {
    //     type: "SmishingTest",
    //     description: "SMISHING Test (SMS)"
    //   }
    // }
    else {
      if(this.etComponent){
        this.etComponent.createTransaction.markAllAsTouched();
      }
    }

  }

  next() {
    console.log("create: ", this.createTransaction);

    //this will console invalid form
    const invalid = [];
    const controls = this.createTransaction.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }

    console.log("invalid: ", invalid)
    //this.sampleGetClaims();
    this.submitted = true;
    this.adNotDisplay = false;
    let validET = this.etComponent ? this.etComponent.createTransaction.invalid : false;
    if (this.createTransaction.invalid) {
      // this.createTransaction.markAllAsTouched();
      if(this.form.data.transaction.alertType.type != 'EmergingThreat'){
        this.createTransaction.markAllAsTouched();
        console.log("this.etcomponent", this.etComponent)
        //this.etComponent.createTransaction.markAllAsTouched();
      }else{

        this.etComponent.createTransaction.markAllAsTouched();
        console.log("this.etcomponent", this.etComponent.createTransaction.invalid)
      }

      if (this.form.data.transaction.body == null) {
        this.errorChecker = true;
      }
      if (this.form.data.transaction.shortDescription == null) {
        this.errorChecker = true;
      }

      // console.log(this.f['expiration'].errors)
      console.log(this.createTransaction.invalid)
      return;

    } else if (validET) {
      // console.log(this.f['expiration'].errors)
      console.log(this.createTransaction.invalid)
      // this.etComponent.createTransaction.markAllAsTouched();
      return;
    }
    else {
      console.log(this.form.data.transaction)
      console.log("redirect");
      if (this.form.data.transaction.ragStatus == 'Emerging Threat') {

        this.setQAData();


      }
      this.router.navigateByUrl('/preview');
      this.errorChecker = false;
    }
    if (this.adDisplay == true){
      this.adDisplay =false;
    }
    if (this.adDisplay == false){
      this.adDisplay =true;
    }
  }

  // open file dialog
  openDialog() {
    this.dialogRef = this.dialog.open(FileDialogComponent, {
      disableClose: true,
      data: {
        buttonText: {
          cancel: 'Cancel',
        },
      },
    });


    this.dialogRef.afterClosed().subscribe(result => {
      if (result.status == 'done' && result.eids != "") {
        // if (this.form.data.transaction.recipients.option == 'fileNumbers'){
        //   let x = this.phone.filterInvalidNumbers(result.eids)
        //   console.log('X: ', x)
        //   if (x.length > 0){
        //     console.log("!", x)
        //    this.form.data.temporary = x;
        //    this.form.data.compare = { text: 'The following will not receive the message because they are not a valid number.'};
        //    let temp = result.eids.split(';')
        //    x.forEach(invalidnumbers=>{
        //     temp = temp.filter(number=> number!= invalidnumbers)
        //    })

        //     // let csveids = x.join(';');
        //     // csveids = csveids.toLowerCase();
        //     // csveids = this.filterEIDs(csveids);
        //     // let text = 'The following email addresses will not receive this notification because  <br>they do not have the Always Safe Mobile application installed <br>on their device or there is a spelling error.'
        //     // this.compare(csveids, true, true, text)
        //     // result.eids = temp.concat(csveids);
        //     result.eids = temp.join(';');
        //     let newDialogFile = this.dialog.open(CompareComponent, {
        //       disableClose: true,
        //       data: {
        //         buttonText: {
        //           cancel: 'Cancel',
        //         },
        //       },
        //     });
        //     newDialogFile.afterClosed().subscribe(closed => {
        //       this.form.data.transaction.recipients.numbers = temp.join(';');
        //       if(this.form.data.transaction.recipients.fileValue == "")
        //         this.form.data.transaction.recipients.fileReaded = false
        //       if(this.form.data.transaction.recipients.fileValue != "")
        //         this.openafterClose("numbers")
        //     })
        //   }
        //   // else {
        //   //   console.log("return file:", this.form.data.transaction.recipients)
        //   //   this.openafterClose("numbers")
        //   // }
        //   console.log('returned',result)
        //   if (result.eids !=''){
        //     this.form.data.transaction.recipients.fileNumbers = result.fileName;
        //     this.form.data.transaction.recipients.fileReaded = true;
        //     this.form.data.transaction.recipients.fileValue = result.eids;
        //     this.createTransaction.get('fileNumbers').setValue(this.form.data.transaction.recipients.fileNumbers);
        //     this.createTransaction.get('fileNumbers').disable();
        //   } else {
        //     this.form.data.transaction.recipients.fileReaded = false;
        //     this.createTransaction.get('fileNumbers').setValue('');
        //     this.createTransaction.get('fileNumbers').setValidators([Validators.required]);
        //     this.createTransaction.get('fileNumbers').enable();
        //   }
        //   this.form.data.transaction.recipients.fileNumbers = result.fileName;
        //   console.log('Y:', this.form.data.transaction.recipients.fileNumbers)
        //   console.log('M:', this.form.data.transaction.recipients.fileValue)
        //   console.log('G:', this.form.data.transaction.recipients.fileReaded)

        //   this.form.data.transaction.recipients.fileReaded = true;
        //   this.form.data.transaction.recipients.fileValue = result.eids;

        //   this.createTransaction.get('recipientFileName').setValue(this.form.data.transaction.recipients.fileNumbers);
        //   this.createTransaction.get('recipientFileName').disable();
        //   console.log("return file1:",x.length, this.form.data.transaction.recipients.fileValue)
        //   if(x.length == 0 && this.form.data.transaction.recipients.fileValue != "")
        //   this.openafterClose("numbers")
        // } else {
        this.form.data.transaction.recipients.fileReaded = true;
        this.form.data.transaction.recipients.fileValue = result.eids.toLowerCase();
        this.form.data.transaction.recipients.fileValue = this.filterEIDs(this.form.data.transaction.recipients.fileValue);
        console.log('J:', this.form.data.transaction.recipients.fileValue)
        this.form.data.transaction.recipients.fileName = result.fileName;

        this.createTransaction.get('recipientFileName').setValue(this.form.data.transaction.recipients.fileName);
        this.createTransaction.get('recipientFileName').disable();
        let text = 'The following email addresses will not receive this notification because  <br>they do not have the Always Safe Mobile application installed <br>on their device or there is a spelling error.'
        this.compare(this.form.data.transaction.recipients.fileValue, true, true, text);
        // }
      } else {
        console.log("result not done")

        this.form.data.transaction.recipients.fileReaded = false;
        this.createTransaction.get('recipientFileName').setValue('');

        this.createTransaction.get('recipientFileName').setValidators([Validators.required]);
        this.createTransaction.get('recipientFileName').enable();
      }

      this.createTransaction.get('recipientFileName').updateValueAndValidity();


      console.log(this.createTransaction.get('recipientFileName'))
    })
  }

  clickMyTransactions() {

    this.router.navigateByUrl('/transaction')
  }

  setQAData() {

    //api
    if (this.form.data.transaction.alertID != undefined && this.form.data.transaction.emerging == undefined) {
      console.log("emergin UNDEFINED", this.form.data.transaction)
      this.form.getQA(this.form.data.transaction.alertID).subscribe(result => {
        let answers = []
        let ctr = 0;
        console.log("1st emerging data", this.form.data.transaction.emerging, result)
        for (let prop in this.form.data.transaction.emerging) {
          if (prop.includes('Answer')) {
            let propname = 'Answer' + ctr
            console.log("counter", ctr, prop)
            answers.push(this.form.data.transaction.emerging[prop])
            this.form.data.transaction.emerging[propname] = this.form.data.transaction.emerging[prop]
            ctr++
            delete this.form.data.transaction.emerging[prop]
          }

          console.log("setting ANSWERS")
        }
        this.form.data.transaction.emerging.AllAns = answers
        console.log("2nd emerging data", this.form.data.transaction.emerging)
        this.isload = true
      })

    } else {
      let answers = []
      console.log("emerging data", this.form.data.transaction.emerging)
      console.log("emergin not undefined", this.form.data.transaction)
      if (this.form.data.transaction.emerging != undefined) {
        for (let prop in this.form.data.transaction.emerging) {
          if (prop.includes('Answer')) {
            answers.push(this.form.data.transaction.emerging[prop])
          }
        }
        this.form.data.transaction.emerging.AllAns = answers
        this.isload = true
      }

    }


  }


  sampleGetClaims() {
    this.form.user.loadClaims().subscribe((result) => {
      console.log("claims from create", result)
    })
  }


  ragStatusChange($event) {
    if ($event.value == 'CDP Red Accounts') {
      this.form.data.transaction.title = 'CDP Metrics'
      this.form.data.transaction.shortDescription = '<p>One or more of your CDP accounts needs your attention.</p>'
      this.form.data.transaction.bodyHtml = this.detailedDescription
      this.isCDPRed = true;
    } else  {
      this.form.data.transaction.title = ''
      this.form.data.transaction.shortDescription = ''
      this.form.data.transaction.bodyHtml = ''
      this.form.data.transaction.smsMessage = ''
      this.isCDPRed = false;
    }
  }

  // for testing (IP)

  filterEIDs(eids){
    eids = eids.replace(/\s/g, "").split(';');
    let filtered = eids.filter(function (a) {
            if (!this.has(a)) {
                this.set(a, true);
                return true;
            } else {
              return true
            }
        }, new Map);
    console.log(filtered);

    if (!filtered[filtered.length -1]) {
      filtered.splice(filtered.length -1, 1)
    }
    eids = filtered.join(';');
    return eids ;
  }

}

