import { Component, OnInit } from '@angular/core';
import { FormService } from 'src/app/core/form.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.scss']
})
export class CompareComponent implements OnInit {

  constructor(
    public form: FormService,
    private dialogRef: MatDialogRef<CompareComponent>
  ) { }


  iconwarning = '../../../../assets/icons/Outline-WARNING.svg';

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }

  download() {
    this.form.download.downloadCsvArray('Not-Registered.csv', this.form.data.temporary);

    setTimeout(() => {
      this.dialogRef.close();
    }, 1000);

  }
}
