<div class="file-container" role="region" aria-label="Not CDP Users">
    <div class="file-image" tabindex="0">
        <img [src]="iconwarning" role="img" aria-label="warning icon">
    </div>
    <div tabindex="0" aria-label="form.data.compare.text" class="file-desc semibold-font"  [innerHTML]= "form.data.compare.text">
    </div>
   
    <div class="file-emails" tabindex="0">
       <span *ngFor="let eid of form.data.temporary">
        {{eid}};
       </span> 
    </div>
    <div class="file-label" tabindex="0">
        <div class="charCount">Total count: <span class="medium-font" >{{form.data.temporary.length}}</span></div>
    </div>
    <div class="file-buttons"> 
        <a mat-raised-button  (click)="download()" class="green-button" tabindex="0">
            DOWNLOAD
        </a>
 
        <a mat-raised-button (click)="close()" class="red-button" tabindex="0">CANCEL</a>
        
    </div>

</div>