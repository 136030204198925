<div class="file-container">
    <div class="upload" *ngIf="showUpload">
    <div class="file-title" role="region" aria-labelledby="uploadTitle">
        <span >
           <img role="img" aria-label="upload icon" src="assets/icons/upload.svg" tabindex="0">
        </span>

        <div class="file-desc" id="uploadTitle" tabindex="0"> Please choose the file to upload <br>
          <!-- <span> NOTE: There is a 12,000 maximum limit </span> -->
        </div>
        <div *ngIf="error != ''" class="dialog-desc-err"> {{error }} </div>

    </div>

    <div class="file-buttons" role="region" aria-label="upload buttons">
        <div class="file-button" tabindex="0">
            <label for="fileUpload" class="fileUpload" [class.fileUpload-error]="csvError">
                <span *ngIf="!btnUpdate" class="material-icons" matPrefix>attach_file</span>
                <span  [class.btnText]="btnUpdate"> {{buttonFileText}} </span>
                <mat-spinner [diameter]="20" *ngIf="isLoadingCSV"></mat-spinner>
            </label>
            <input id="fileUpload" accept=".csv" type="file" (change)="onFileChange($event)"  style="display: none"/>
        </div>
        <div class="csv-error-field">
            <div class="csv-error-whitespace"></div>
            <div *ngIf="csvError != ''" class="csv-error-text"> {{csvError}} </div>
        </div>
        <div class="dialog-button" >
            <a mat-raised-button  (click)="send()"  class="uploadBtn" [class.btnUpdate]="btnUpdate" [disabled]="disableSend" tabindex="0">
                <span>  SEND </span>
            </a>
            <a mat-raised-button  (click)="cancel()" class="cancelBtn" tabindex="0">
                <span > CANCEL </span>
            </a>
        </div>
    </div>
   </div> 
    <div class="success" *ngIf="showSuccess">
        <div class="dialog-title">
            <span class="material-icons">
                check_circle_outline
            </span>
    
            <div class="dialog-desc"> Reminder notification has </div>
            <div class="dialog-desc"> been sent </div>
        </div>
        <div class="dialog-buttons">
    
            <div class="dialog-button">
                <a  class="as-btn success" (click)="close()">
                   <span> OK </span>
                </a>
            </div>
        </div>
    </div>
</div>
