import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { LoginComponent } from './view/login/login.component';
import { PreviewComponent } from './admin/preview/preview.component';
import { CreateComponent } from './admin/create/create.component';
import { TransactionComponent } from './admin/transaction/transaction.component';
import { ViewTransactionComponent } from './admin/view-transaction/view-transaction.component';
import { TermsandconditionComponent } from './admin/footer/termsandcondition/termsandcondition.component';
import { EtComComponent } from './admin/et-com/et-com.component';
import { UnauthorizedComponent } from './admin/unauthorized/unauthorized.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { EtQuestionsComponent } from './admin/et-questions/et-questions.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { AuthGuard } from './core/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'code', pathMatch: 'full' },
  { path: 'code', component: LoginComponent },
  { path: 'create', component: CreateComponent, canActivate: [MsalGuard, AuthGuard]},
  { path: 'preview', component: PreviewComponent, canActivate: [MsalGuard, AuthGuard]},
  { path: 'transaction', component: TransactionComponent, canActivate: [MsalGuard, AuthGuard]},
  { path: 'view-transaction', component: ViewTransactionComponent, canActivate: [MsalGuard, AuthGuard]},
  { path: 'termsandcondition', component: TermsandconditionComponent, canActivate: [MsalGuard, AuthGuard]},
  { path: 'access_token', redirectTo: 'transaction' },
  { path: 'emergingthreatresponse', component: EtComComponent, canActivate: [MsalGuard, AuthGuard]},
  { path: 'unauthorized', component: UnauthorizedComponent, canActivate: [MsalGuard, AuthGuard]},
  { path: 'reports', component: ReportsComponent, canActivate: [MsalGuard, AuthGuard], canDeactivate: [AuthGuard]},
  { path: 'et-questions', component: EtQuestionsComponent, canActivate: [MsalGuard, AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ preloadingStrategy: NoPreloading,
    scrollPositionRestoration: 'enabled',
    useHash: true,
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledBlocking' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
