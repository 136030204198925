import { AfterContentInit, Directive, HostListener, ElementRef } from '@angular/core';


@Directive({
  selector: '[appFormfocus]'
})
export class FormfocusDirective {
  constructor(public el: ElementRef) { }

  @HostListener('submit')
  onFormSubmit() {
    const invalidControl = this.el.nativeElement.querySelector('.invalid');
  
    if (invalidControl) {
      // this.scrollToError();
      this.scrollTo(invalidControl);
      invalidControl.focus();
    }
  }
  scrollTo(el: Element): void {
    if (el) {
       el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  }
}
