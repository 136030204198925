import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import * as Entities from 'html-entities'
import { ReminderFileDialogComponent } from './reminder-file-dialog/reminder-file-dialog.component';


@Component({
  selector: 'app-rmeinder-question',
  templateUrl: './reminder-question.component.html',
  styleUrls: ['./reminder-question.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class ReminderQuestionComponent implements OnInit {

  showReminder: boolean;
  showSuccess: boolean;
  public reminderdialogRef: any;
  

  constructor(
    public form: FormService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dialogRef: MatDialogRef<ReminderQuestionComponent>,
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
  ) {}
  
  ngOnInit(): void {
    this.showReminder = true;
    this.showSuccess = false;
  }

  cancel() {
    let r = {
      status: 'cancel'
    }
    this.dialogRef.close(r);
    
  }

  openReminderDialog() {
    // this.showReminder = false;
    this.reminderdialogRef = this.dialog.open(ReminderFileDialogComponent, {
      disableClose: true,
      data: {
        buttonText: {
          cancel: 'Cancel',
        },
      },
    });
  }

  send() {
    let param = {
      alertId: this.form.data.transaction.alertID,
      title: this.form.data.transaction.title,
      isCustom: "false",
      recipients: []
    }

    this.form.sendReminder(param).subscribe(result => {
      this.showReminder = false;
      this.showSuccess = true;
    })
    
  }

}
