<app-nav></app-nav>
<div role="region" aria-label="Skip" tabindex="-1">
    <a class="skip-main" href="#main">Skip to main content</a>
</div>
<div role="region" aria-label="Publishing" class="page-status" *ngIf="status" tabindex="0">
  <p>There's a transaction being processed. Publishing transactions are disabled for the meantime </p>
</div>
<div class="page-height" id="main">
    <div class="transaction-container">
        <div class="ts-button">

            <a role="region" aria-label="New Transaction" class="as-btn-ts bg-purple" (click)="createTransaction()" tabindex="0">
              <mat-icon aria-hidden="false" aria-label="Example home icon">
                  add_circle
              </mat-icon>
              New Transaction
            </a>

            <a role="region" aria-label="Emerging Threat" class="as-btn-ts bg-bronze" *ngIf="form.data.isCDP" [routerLink]="'/emergingthreatresponse'" tabindex="0">
              <mat-icon aria-hidden="false" aria-label="Example home icon">
                  new_releases
              </mat-icon>
              Respond to Emerging Threat
            </a>

            <a role="region" aria-label="Give Access"  mat-flat-button href="https://directory.accenture.com/directory/" target="_blank" style="background:transparent"
                class="access" *ngIf="form.data.user.isSuperAdmin === true" tabindex="0">
                <mat-icon aria-hidden="false" aria-label="Example home icon" class="lock-icon">
                    lock
                </mat-icon>
                Give Access
            </a>
        </div>

        <div role="region" aria-label="My Transactions" class='transaction-wrapper'>
          <h3 role="region" aria-label="My Transactions" tabindex="0">MY TRANSACTIONS</h3>
          <a role="region" aria-label="refresh" class="as-btn-ts" (click)="loadData()" tabindex="0">
            <mat-icon aria-hidden="false" role="img" aria-label="refresh">
                refresh
            </mat-icon>
          </a>

            <form class="transaction-form" tabindex="0">
                <!--mat-form-field appearance="outline"> <mat-label>
                    <mat-icon aria-hidden="false">
                    search
                    </mat-icon>
                    Search ...
                </mat-label>
                <input matInput>
            </mat-form-field -->
                <!-- <div class="search-field">
                <input type="text" placeholder="Search...">
                <mat-icon aria-hidden="false" class="search-icon">
                    search
                </mat-icon>

            </div> -->
                <mat-form-field appearance="outline" class="search-field" *ngIf="hideRelease2andUp" aria-label="Search" tabindex="0">
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput type="search" placeholder="Search ...." class="search-input">
                </mat-form-field>

            </form>

        </div>




        <div role="region" aria-label="My Transactions Filter" class="transaction-tab">
            <div tabindex="0" class="filter-tab" *ngIf="selectedIndex===0">
                <div tabindex="0" aria-label="Filter By" class="medium-font pending-filter" *ngIf="hideRelease2andUp">
                    FILTER BY:
                </div>
                <div tabindex="0" aria-label="Title" class="pending-filter" *ngIf="hideRelease2andUp">
                    Title
                </div>
                <div tabindex="0" aria-label="Author" class="pending-filter" *ngIf="hideRelease2andUp">
                    Author
                </div>
            </div>
            <div tabindex="0" aria=label="Filter By" class="filter-tab" *ngIf="selectedIndex===1">
                <div tabindex="0" aria-label="Filter By" class="medium-font pending-filter" *ngIf="hideRelease2andUp">
                    FILTER BY:
                </div>
                <div tabindex="0" aria-label="Title" class="pending-filter" *ngIf="hideRelease2andUp">
                    Title
                </div>
                <div tabindex="0" aria-label="Publisher" class="pending-filter" *ngIf="hideRelease2andUp">
                    Publisher
                </div>
                <div tabindex="0" aria-label="Rag Status" class="published-filter" *ngIf="hideRelease2andUp">
                    RAG <br> Status
                </div>
                <div tabindex="0" aria-label="Published Date" class="published-filter" *ngIf="hideRelease2andUp">
                    Published <br> Date
                </div>
            </div>
        <mat-tab-group [(selectedIndex)]="selectedIndex" animationDuration="0ms"
                      (selectedTabChange)="onTabClick($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                  <span aria-label="Pending Transactions" tabindex="0">
                    Pending Transactions <br>
                    <p aria-labelledby="pendingCount" id="pendingCount" tabindex="0">{{pendingCount}}</p>
                  </span>
                </ng-template>
                <app-table-component
                  [displayedColumns] = "PendingColumns"
                  [dataSourcePending] = "PendingTransactionSource"
                  [index] = "selectedIndex"
                  (selectedRowClick) = "selectedRow($event)"

                ></app-table-component>
                <mat-paginator #pendingpaginator
                               [pageSize]="50"
                               showFirstLastButtons
                               aria-label="Select page">
                </mat-paginator>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                  <span aria-label="Published Transactions" tabindex="0" >
                    Published Transactions <br>
                    <p aria-labelledby="publishCount" id="publishCount" tabindex="0" >{{publishCount}}</p>
                  </span>
                </ng-template>

                <app-table-component
                  [displayedColumns] = "PublishedColumns"
                  [dataSourcePublish] = "PublishTransactionSource"
                  [index] = "selectedIndex"
                  (selectedRowClick) = "selectedRowPublished($event)"
                  (modalClosed)="modalClosed($event)"
                  ></app-table-component>

                <mat-paginator #publishpaginator
                                   [pageSize]="50"
                                   showFirstLastButtons
                                   aria-label="Select page">
                </mat-paginator>
            </mat-tab>
        </mat-tab-group>
    </div>

    <div  role="region" aria-label="Delete Selected" [className]="form?.data?.transaction?.toBeDeleted?.Pending.length != 0 || form?.data?.transaction?.toBeDeleted?.Publish.length != 0 ? 'delete-button-active' : 'delete-button-disable'"
         (click)="openDialog(form.data.transaction.toBeDeleted)" aria-disabled="true" tabindex="0">
      <img role="img" aria-label="Delete Icon" src={{trashcansrc}}>
      <a> Delete Selected</a>
    </div>

</div>
<app-footer></app-footer>
</div>
