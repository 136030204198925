import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { ResolveComponent } from '../../sharedComponent/resolve/resolve.component';
import { ExpirationComponent } from '../../sharedComponent/expiration/expiration.component';
import { SelectionModel } from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';

export interface Transactions {
  alertID?: string;
  alertStatus?: string;
  alertType?:string;
  bodyHtml?:string;
  category?:string;
  createdBy?:string;
  createdDate?:string;
  etStatus?:string;
  groupID?: number;
  isAD?: boolean;
  modifiedBy?:string;
  modifiedDate?:string;
  publishedDate?:string;
  publisher?:string;
  ragStatus?:string;
  recipientCount?: number;
  recipientFileName?:string;
  recipients?:string;
  reviewer?:string;
  shortDescription?:string;
  title?:string;
}
@Component({
  selector: 'app-table-component',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']

})
export class PublishedTableComponent implements OnInit {

  constructor(public dialog: MatDialog,
    public form: FormService) { }


  @Input() displayedColumns: string[];
  @Input() dataSourcePending : MatTableDataSource<Transactions> = new MatTableDataSource<Transactions>([]);
  @Input() dataSourcePublish : MatTableDataSource<Transactions> = new MatTableDataSource<Transactions>([]);
  @Input() index: number;

  @Output()
  public selectedRowClick: EventEmitter<any> = new EventEmitter();

  @Output()
  public modalClosed: EventEmitter<any> = new EventEmitter();

  @Output() toDelete: any;

  red = '../../../../assets/icons/red-grey-outline.png'
  orange = '../../../../assets/icons/orange-grey-outline.png'
  green = '../../../../assets/icons/green-grey-outline.png'
  pencil = '../../../../assets/icons/pencil_edit_blue.svg'
  // initialSelection = [];
  // allowMultiSelect = true;
  selection0 = new SelectionModel(true, []);
  selection1 = new SelectionModel(true, []);
  masterToggle0 = false;
  masterToggle1 = false;


  public dialogRefResolve;
  public dialogRefExpiration;

  ngOnInit(): void {
    this.index = 0;
  }

  updateSelection() {
    this.masterToggle1 = false
    this.masterToggle0 = false
  }

  isAllSelected(tab) {
    let dataSource = tab == 0 ? this.dataSourcePending : this.dataSourcePublish
    const numSelected = this.form.data.transaction.toBeDeleted.length;
    const numRows = dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(tab) {
    if(tab == 0){
      this.masterToggle0 = !this.masterToggle0
      if(this.masterToggle0){
        this.dataSourcePending.data.forEach(row => this.selection0.select(row))
        this.form.data.transaction.toBeDeleted.Pending.push(...this.dataSourcePending.data)
      }

      if(this.masterToggle0 == false){
        this.selection0.clear()
        this.form.data.transaction.toBeDeleted.Pending = []
      }
    } else {
      this.masterToggle1 = !this.masterToggle1
      if(this.masterToggle1){
        this.dataSourcePublish.data.forEach(row => this.selection1.select(row))
        this.form.data.transaction.toBeDeleted.Publish.push(...this.dataSourcePublish.data)
      }
      if(this.masterToggle1 == false){
        this.selection1.clear()
        this.form.data.transaction.toBeDeleted.Publish = []

      }
    }
  }

  deleteData(event, row, tab) {
    if (event.checked) {
      if (tab == 0){
        this.form.data.transaction.toBeDeleted.Pending.push(row)
        if (this.form.data.transaction.toBeDeleted.Pending.length == this.dataSourcePending.data.length)
          this.masterToggle0 = true
      }
      if(tab == 1){
        this.form.data.transaction.toBeDeleted.Publish.push(row)
        if (this.form.data.transaction.toBeDeleted.Publish.length == this.dataSourcePublish.data.length)
          this.masterToggle0 = true
      }
    } else {
      let id = row.alertID;
      if(tab == 0){
        this.form.data.transaction.toBeDeleted.Pending = this.form.data.transaction.toBeDeleted.Pending.filter(item => item.alertID != id)
        if(this.masterToggle0)
          this.masterToggle0 = false
      }
      if(tab == 1){
        this.form.data.transaction.toBeDeleted.Publish = this.form.data.transaction.toBeDeleted.Publish.filter(item => item.alertID != id)
        if(this.masterToggle1)
          this.masterToggle1 = false

      }
    }
  }

  openDialog(element) {
    this.dialogRefResolve = this.dialog.open(ResolveComponent, {
      disableClose: true,
      data: element
    });

    this.dialogRefResolve.afterClosed().subscribe(data => {

      this.modalClosed.emit(data);

    })

  }

  openDialogDate(element) {
    this.dialogRefExpiration = this.dialog.open(ExpirationComponent, {
      disableClose: true,
      data: element
    });

    this.dialogRefExpiration.afterClosed().subscribe(data => {

      this.modalClosed.emit(data);
    })
  }

  selectedRow(ev) {
    console.log(ev)
    //console.log('selected row', ev)
    // let forEdit = {}


    // dialogRef.afterClosed().subscribe(data =>{
    //   this.modalClosed.emit(data);
    // })
  }

  //format transactions
  selectedCell(ev) {
    this.form.getRecipientById(ev.alertID).subscribe(data => {
      let forEdit = {}
      let tempData: any = data;

      // tempData.recipients == 'EID' || tempData.recipients == 'CSV'
      if(tempData.recipients.includes('@accenture.com') || tempData.recipientFileName || tempData.recipients == 'ALL'){
        ev.recipients = tempData.recipients;
        ev.recipientFileName = tempData.recipientFileName;
      }
      // else if (ev.fileNumbers != ""){
      //   ev.recipients = tempData.recipients;
      //   ev.fileNumbers = tempData.fileNumbers;
      // }
      // else if(ev.numbers != ""){
      //   ev.recipients = tempData.recipients;
      //   ev.numbers = tempData.numbers;
      // }
      else{
        ev.isAD = true;
        ev.recipients = tempData.recipients;
        ev.recipientFileName = tempData.recipientFileName;
      }

      forEdit = this.setValues(ev);
      console.log("for Edit", forEdit);
      console.log("for Edit s", ev);
      this.selectedRowClick.emit(forEdit);

    })
  }

  setValues(ev) {
    let retVal = {}

    let option = {};
    for (let prop in ev) {
      if (prop == "category") {
        // if ( ev.alertType == "SmishingTest"){
        //   retVal['categories'] = {
        //     type: "SmishingTest",
        //     description: "SMISHING Test (SMS)"
        //   }
        // } else {
          retVal["categories"] = this.form.config.categories.find(j => j.type == ev.category);
        // }

      } else if (prop == "alertType") {
        retVal["alertType"] = this.form.config.alerts.find(j => j.type == ev.alertType);
      } else if (prop == "isAD") {
        retVal[prop] = ev[prop];
        if(ev[prop] == true){
          option =  { option: 'ad', ad: ev['recipients'] } ;
          retVal['recipients'] = option;
        }
      } else if (prop == "recipients") {
        option = ev[prop] == 'ALL' ? { option: 'ALL', eid: '' }
              // : ev['eidsmishing'] ? { option: 'eidsmishing', eidsmishing: ev['eidsmishing'] }
              : ev['recipientFileName'] ? { option: 'file', fileValue: ev['recipients'] }
              : ev['isAD'] == true ? { option: 'ad', ad : ev['recipients'] }
              // : ev['fileNumbers'] ? { option: 'fileNumbers', fileValue: ev['recipients'], fileNumbers: ev['fileNumbers']}
              : { option: 'eids', eid: ev['recipients'] }
        retVal['recipients'] = option;
        console.log("RECIPIENTS=>", retVal['recipients']);
      } else if (prop == "recipientFileName") {
        //retVal['recipients'] = retVal['recipients']['fileName'] ? retVal['recipients']['fileName'] : { fileName : "" };
        retVal['recipients']['fileName'] = ev[prop] ? ev[prop] : '';
      } else if (prop == "reviewer") {
        retVal["reviewers"] = ev[prop];
      } else {
        retVal[prop] = ev[prop]
      }
    }
    console.log('is retval~  ', retVal);
    return retVal;
  }


}
