<div class="nav-footer">
    <div class="footer-container">
        <div class="footer-links">
            <div class="footer-link">
                <a class="as-nav-title" target="_blank" role="region" aria-label="Privacy Policy" href="https://in.accenture.com/protectingaccenture/data-security/5422-2/">Privacy Policy</a>
            </div>

            <!-- <div class="footer-link" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
                <a class="as-nav-title" >Cookies Policy</a>
            </div> -->
            <div class="footer-link" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
                <a class="as-nav-title" routerLink="/termsandcondition" role="region" aria-label="Terms of Use">Terms of Use</a>

            </div>

        </div>
        <div class="footer-copyright" role="region" aria-label="Copyright Accenture. All rights reserved.">
          <i>Copyright &#169; 2020 Accenture. All rights reserved.</i>

        </div>

    </div>

</div>
