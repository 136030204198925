import { EsoService } from './core/eso.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var  startDatadogScript;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    public eso: EsoService
    ){
      this.eso.configure();
  }

  title = 'ASA';
  ngOnInit(){
  try{
    startDatadogScript(environment.dataDog);
  }
  catch(e){
    console.log('datadogScriptError',e)
  }

  }
}
