<div *ngFor="let data of dataSource; let i =index " class="questionTbl">
    <div class="questionCol">
        <div class="q-col bold-font">
            QUESTION #{{i +1}}
        </div>
        <div class="t-col bold-font">
            TOTAL READ
        </div>
        <div class="t-col bold-font">
            TOTAL UNREAD
        </div>
    </div>
    <div class="questionCol questionCol-white">
        <div class="q-col semibold-font">
            <!-- <div [innerHTML]="{{data.question}}"></div> -->
            <p [innerHTML]="data.question">
                <!-- {{data.question}} -->
            </p>

        </div>
        <div class="t-col semibold-font" [ngClass]="data.dataRead > 0 ? 'color-blue' : 'color-red' ">
            <span id="{{data.qid}}" (click)="getData('question',$event.target)" [ngClass]="data.dataRead > 0 ? 'colread' : '' ">
                {{data.dataRead}}
            </span>
        </div>
        <div class="t-col semibold-font color-red">
            <span class="colunread"> {{data.dataUnread}} </span>
        </div>
    </div>
    <div class="questionCol">
        <div class="q-col bold-font col-option">
            RESPONSE OPTIONS
        </div>
        <div *ngIf="unknowns[i] > 0" class="t-col t-col-40 bold-font">
            TOTAL RESPONSES:
            <!-- <span><mat-icon (click)="getAllData(i)">save_alt</mat-icon></span> -->
            <span class="total-responses semibold-font"
              [ngClass]="totalResponses[i] > 0 ? 'colread' : '' "
              [ngStyle]="{'color' : totalResponses[i] > 0 ? '' : '#ED021F' }"
              (click)="getAllData(i)">
              {{ totalResponses[i] }}; (Mobile) {{ mobileCounts[i] }}; (Web)  {{ webCounts[i] }}; Unknown {{unknowns[i]}}
            </span>
        </div>
        <div *ngIf="unknowns[i] == 0" class="t-col t-col-40 bold-font">
            TOTAL RESPONSES:
            <!-- <span><mat-icon (click)="getAllData(i)">save_alt</mat-icon></span> -->
            <span class="total-responses semibold-font"
              [ngClass]="totalResponses[i] > 0 ? 'colread' : '' "
              [ngStyle]="{'color' : totalResponses[i] > 0 ? '' : '#ED021F' }"
              (click)="getAllData(i)">
              {{ totalResponses[i] }}; (Mobile) {{ mobileCounts[i] }}; (Web)  {{ webCounts[i] }}
            </span>
        </div>
        
    </div>
    <div class="questionCol questionCol-white" *ngFor="let response of data.responses; let j =index">
        <div class="q-col">
            <span class="col-response"> {{response.response}} </span>
        </div>
        <div class="t-col t-col-40 semibold-font" [ngClass]="response.totalresponse > 0 ? 'color-blue' : 'color-red' ">
            <span id="{{data.qid}}-{{response.aid}}" (click)="getData('answer',$event.target)" [ngClass]="response.totalresponse > 0 ? 'colread' : '' ">
                {{response.totalresponse}}
            </span>
        </div>
        <!-- <div class="t-col color-red">
            {{response.pending}}
        </div> -->
    </div>


    <!-- <div  >
        {{response.response}}
        {{response.totalresponse}}
    </div> -->

</div>
