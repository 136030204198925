
<div class="resolve">
  <div class="dialog-title">
        <img src={{icon}}>

      <div class = "medium-font"> Are you sure you want to change the </div>

      <div class="dialog-desc medium-font">
         <p>Expiration Date of the transaction?</p>

          <div class="border-box">
              <mat-label></mat-label>
      <input matInput readonly [matDatepicker]="picker" (dateInput)="addEvent($event)"  [min]="minDate"  value="{{expValue}}"> 
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
          </div>
      </div>
    </div>
  <p>If you click Yes, your change will be applied immediately.</p>
  <div class="dialog-buttons">

    <br>

      <div class="dialog-button">
        <span>
          <a mat-raised-button class="green-button" (click)="yesClick()">
            YES
          </a>
        </span>
        <span>
          <a mat-raised-button class="red-button" (click)="cancelClick()">
            CANCEL
          </a>
        </span>
      </div>


  </div>


</div>
