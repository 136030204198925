import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EsoService } from './eso.service';

export var StatusCode = {
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  InternalServerError: 500,
  Redirect: 302
}

export var Broadcast = {
  Emitter: new EventEmitter<Error>(),
  ScheduleEmitter: new EventEmitter()
}

export class Error {
  public Status: number;
  public Message: string;
  public Title: string;
}
@Injectable({
  providedIn: 'root'
})
export class AhttpService {
  constructor(public http: HttpClient,
    public eso: EsoService) {
  }

  // override get
  public get(url?: string): Observable<any> {
    return Observable.create(
      (observer) => {
        this.eso.getToken()
          .subscribe((token: any) => {
            //options = options == null ? new RequestOptions() : options;
            //console.log(token);
            console.log("get");
            this.http.get(url, this.createHeader(token))
              .subscribe(
                res => {
                  observer.next(res);
                  observer.complete();
                },
                (error) => {
                  console.log(error);
                  if (error.status == StatusCode.Unauthorized) {
                    //this.eso.ClearToken();
                    this.refreshGet(url, observer);
                    //observer.complete();
                  }
                  else {
                    this.errorOnApi(error, error.status);
                    observer.error(error);
                    //observer.complete();
                  }

                });
          },

            (error) => {
              if (error == "No Network Connection") {
                this.noInternetConnection(error);
              }
              observer.error(error);
              observer.complete();
            });
      }
    );
  }

  // override post
  public post(url?: string, body?: any): Observable<any> {
    return Observable.create(
      (observer) => {

        this.eso.getToken()
          .subscribe((token: any) => {
            this.http.post(url, body, this.createHeader(token))
              .subscribe(
                res => {
                  observer.next(res);
                  observer.complete();
                },
                (error) => {

                  if (error.status == StatusCode.Unauthorized) {
                    this.refreshPost(url, body, observer);
                  }
                  else {
                    this.errorOnApi(error, error.status);
                    observer.error(error);
                  }
                });
          },
            (error) => {
              if (error == "No Network Connection") {
                this.noInternetConnection(error);
              }
              observer.error(error);
              observer.complete();
            });
      }

    );
  }

  // override put
  public put(url?: string, body?: any): Observable<any> {
    return Observable.create(
      (observer) => {

        this.eso.getToken()
          .subscribe((token: any) => {
            this.http.put(url, body, this.createHeader(token))
              .subscribe(
                res => {
                  observer.next(res);
                  observer.complete();
                },
                (error) => {

                  if (error.status == StatusCode.Unauthorized) {
                    this.refreshPut(url, body, observer)
                  }
                  else {
                    this.errorOnApi(error, error.status);
                    observer.error(error);
                  }
                })
          },
            (error) => {
              if (error == "No Network Connection") {
                this.noInternetConnection(error);
              }
              observer.error(error);
              observer.complete();
            });
      }
    );
  }

  // override delete
  public delete(url?: string): Observable<any> {
    return Observable.create(
      (observer) => {

        this.eso.getToken()
          .subscribe((token: any) => {
            this.http.delete(url, this.createHeader(token))
              .subscribe(
                res => {
                  observer.next(res);
                  observer.complete();
                },
                (error) => {
                  // do something in the error
                  if (error.status == StatusCode.Unauthorized) {
                    this.refreshDelete(url, observer)
                    //observer.complete();
                  }
                  else {
                    this.errorOnApi(error, error.status);
                    observer.error(error);
                    //observer.complete();
                  }
                })
          },
            (error) => {
              if (error == "No Network Connection") {
                this.noInternetConnection(error);
              }
              observer.error(error);
              observer.complete();
            });
      }

    );
  }

  private refreshDelete(url: string, observer) {
    this.eso.getToken()
      .subscribe((token: any) => {
        this.http.delete(url, this.createHeader(token))
          .subscribe(
            res => {
              observer.next(res);
              observer.complete();
            },
            (error) => {
              //this.log.write("Second time called => " + error);
              this.errorOnApi(error, error.status);
              observer.error(error);
              observer.complete();
            }
          );
      }, (error) => {
        if (error == "No Network Connection") {
          this.noInternetConnection(error);
        }
        observer.error(error);
        observer.complete();
      })
  }

  private refreshPut(url: string, data, observer) {
    this.eso.getToken()
      .subscribe((token: any) => {

        this.http.put(url, data, this.createHeader(token))
          .subscribe(
            res => {
              observer.next(res);
              observer.complete();
            },
            (error) => {
              this.errorOnApi(error, error.status);
              observer.error(error);
              observer.complete();
            }
          );
      }, (error) => {
        if (error == "No Network Connection") {
          this.noInternetConnection(error);
        }
        observer.error(error);
        observer.complete();

      })
  }

  private refreshGet(url: string, observer) {
    this.eso.getToken()
      .subscribe((token: any) => {

        this.http.get(url, this.createHeader(token))
          .subscribe(
            res => {
              observer.next(res);
              observer.complete();
            },
            (error) => {
              this.errorOnApi(error, error.status);
              observer.error(error);
              observer.complete();
            }
          );
      }, (error) => {
        if (error == "No Network Connection") {
          this.noInternetConnection(error);
        }
        observer.error(error);
        observer.complete();
      })
  }

  private refreshPost(url: string, data, observer) {
    this.eso.getToken()
      .subscribe((token: any) => {
        this.http.post(url, data, this.createHeader(token))
          .subscribe(
            res => {
              observer.next(res);
              observer.complete();
            },
            (error) => {
              this.errorOnApi(error, error.status);
              observer.error(error);
              observer.complete();
            });
      }, (error) => {
        if (error == "No Network Connection") {
          this.noInternetConnection(error);
        }
        observer.error(error);
        observer.complete();
      });
  }

  private createHeader(token: string): any {
    let headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer" + ' ' + token
      })
    }

    console.log(headers);
    return headers;
  }

  private errorOnApi(error: any, source: any) {

    let bError = new Error();

    // if (error.status == 401) {
    //   bError.Message = "Unauthorized Access on the API";
    //   bError.Status = error.status;
    //   bError.Title = "Error";
    // }
    // else if (error.status == 403) {
    //   bError.Message = "Forbidden Access on the API";
    //   bError.Status = error.status;
    //   bError.Title = "Error";
    // }
    // else if (error.status == 500) {
    //   bError.Message = "An error encountered while processing the request.";
    //   bError.Status = error.status;
    //   bError.Title = "Error";
    // }
    // else if (error.status == 404) {
    //   bError.Message = "Server not found. Please contact the administrator";
    //   bError.Status = error.status;
    //   bError.Title = "Error";
    // }
    // else if (error.status == 302) {
    //   bError.Message = "Something went wrong with the server. Please contact the system administrator";
    //   bError.Status = error.status;
    //   bError.Title = "Error";
    // }
    // else if (error.status == 200) {
    //   bError.Message = "No or Slow Internet Connection";
    //   bError.Status = error.status;
    //   bError.Title = "Error";
    // }
    // else {

      let errorMessage = <any>error;
      console.log(error);
      
      // bError.Message = "Something went wrong with the app: " + errorMessage.message;
      bError.Message = "An error encountered while processing the request.";
      bError.Status = error.status;
      bError.Title = "Error";
    // }

    console.log(bError);
    Broadcast.Emitter.emit(bError);
  }

  private noInternetConnection(error: any) {
    let bError = new Error();
    bError.Message = "No Internet Connection";
    bError.Status = error.status;
    bError.Title = "Error";
    Broadcast.Emitter.emit(bError);
  }
}
