<div class="file-container">
    <div class="file-title" role="region" aria-labelledby="uploadTitle">
        <span >
           <img role="img" aria-label="upload icon" src="assets/icons/upload.svg" tabindex="0">
        </span>

        <div class="file-desc" id="uploadTitle" tabindex="0"> Please choose the file to upload <br>
          <!-- <span> NOTE: There is a 12,000 maximum limit </span> -->
        </div>
        <div *ngIf="error != ''" class="dialog-desc-err"> {{error }} </div>

    </div>

    <div class="file-buttons" role="region" aria-label="upload buttons">
        <div class="file-button" tabindex="0">
            <label for="fileUpload" class="fileUpload" >
                <span  [class.btnText]="btnUpdate"> {{buttonFileText}} </span>
            </label>
            <input  id="fileUpload" accept=".csv" type="file" (change)="onFileChange($event)" style="display: none"/>

        </div>
        <div class="dialog-button" >
            <a mat-raised-button  (click)="upload()"  class="uploadBtn" [class.btnUpdate]="btnUpdate" tabindex="0">
                <span>  UPLOAD </span>
            </a>
            <a mat-raised-button  (click)="close()" class="cancelBtn" tabindex="0">
                <span > CANCEL </span>
            </a>
        </div>
    </div>
</div>
