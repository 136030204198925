<div class="white-box" *ngIf="isUnauthorized"></div>
<app-nav></app-nav>
<div role="region" aria-label="Skip" tabindex="-1">
    <a class="skip-main" href="#main">Skip to main content</a>
</div>
<div class="page-height" id="main">
    <div class="admin-container">
        <div role="region" class="admin-button" aria-label="My Transactions button" tabindex="0">

            <a mat-raised-button class="bg-purple"  (click) ="clickMyTransaction()">
                <mat-icon role="region" aria-hidden="false" aria-label="My Transactions button">
                    home
                </mat-icon>
                My Transactions
            </a>

        </div>
        <div class="preview-container">

            <div class="title-container">
                <h3 tabindex="0" aria-label="CREATE TRANSACTION" class="headerTitle" role="region">CREATE TRANSACTION</h3>
                <ul class="breadcrumb preview-breadcrumb" >
                    <li><a role="region" aria-label="Message" tabindex="0">Message</a></li>
                    <li><a role="region" aria-label="Preview" tabindex="0" class="current">Preview</a></li>
                </ul>
            </div>

            <div class="transaction-form" *ngIf="form.data.transaction" role="region" aria-label="Preview Transaction">
                <h3 aria-label="Preview" class="form-title bold-font" tabindex="0">PREVIEW</h3>

                <div class="transaction-details">
                    <div class="transaction-detail">
                        <div class="transaction-label">
                            <span  aria-label="Notification Type" class="semibold-font" tabindex="0">
                                NOTIFICATION TYPE:
                            </span>
                        </div>
                        <div aria-labelledby="tdescription" class="transaction-data" tabindex="0">
                            <span id="tdescription">{{form.data.transaction.alertType.description}}</span>
                        </div>

                    </div>
                    <div class="transaction-detail">
                        <div class="transaction-label">
                            <span aria-label="Category" class="semibold-font" tabindex="0">
                                CATEGORY:
                            </span>
                        </div>
                        <div aria-labelledby="tcdesc"  class="transaction-data" tabindex="0">
                            <span id="tcdesc">{{form.data.transaction.categories.description}}</span>
                        </div>


                    </div>
                    <div class="transaction-detail">
                        <div class="transaction-label">
                            <span aria-label="Rag Status"  class="semibold-font" tabindex="0">
                                RAG STATUS:
                            </span>
                        </div>
                        <div class="transaction-data">
                            <div aria-labelledby="ragStat" class="rag-status" tabindex="0">
                                <mat-radio-group *ngIf="form.data.transaction.ragStatus != 'Emerging Threat' && form.data.transaction.ragStatus != 'CDP Red Accounts'"
                                    labelPosition="before" aria-label="Select an option">
                                    <mat-radio-button name="Urgent" id="ragStat" role="radio" aria-checked=true checked class="{{form.data.transaction.ragStatus}}" value="Urgent">
                                        {{form.data.transaction.ragStatus}} </mat-radio-button>
                                </mat-radio-group>
                                <mat-radio-group name="CDP Red Account" role="radio" aria-checked=true *ngIf="form.data.transaction.ragStatus != 'Emerging Threat' && form.data.transaction.ragStatus == 'CDP Red Accounts'"
                                    labelPosition="before" aria-label="Select an option">
                                    <mat-radio-button id="ragStat" checked class="CDPRedAccounts" value="CDPRedAccounts">
                                        {{form.data.transaction.ragStatus}} </mat-radio-button>
                                </mat-radio-group>
                                <mat-radio-group *ngIf="form.data.transaction.ragStatus == 'Emerging Threat'"
                                    labelPosition="before" aria-label="Select an option">
                                    <mat-radio-button id="ragStat" name="Emerging Threat" role="radio" aria-checked=true checked class="Urgent" value="Emerging Threat">
                                        {{form.data.transaction.ragStatus}}</mat-radio-button>
                                </mat-radio-group>

                            </div>

                        </div>


                    </div>
                    <div class="transaction-detail" *ngIf="form.data.transaction.alertType.type != 'EmergingThreat'">
                        <div class="transaction-label">
                            <span aria-label="Expiration Date"  class="semibold-font" tabindex="0">
                                EXPIRATION DATE:
                            </span>
                        </div>
                        <div aria-labelledby="expDate" class="transaction-data">
                            <span id="expDate"  tabindex="0">
                                {{form.data.transaction.expiration ? (form.data.transaction.expiration | date: 'M/d/yyyy') : '- - -'}}
                            </span>
                        </div>


                    </div>
                    <div class="transaction-detail">
                        <div class="transaction-label">
                            <span aria-label="Recipients" class="semibold-font" tabindex="0">
                                RECIPIENTS:
                            </span>
                        </div>
                        <div aria-labelledby="recOption"  class="transaction-data" tabindex="0">
                            <div id="recOption" *ngIf="form.data.transaction.recipients.option == 'ALL'">
                                <a (click)="downloadAllMobileUsersFile()">
                                    <span class="blue semibold-font">All Mobile Users</span>
                                </a>
                            </div>
                            <div id="recOption" *ngIf="form.data.transaction.recipients.option == 'file'" tabindex="0">
                                <a (click)="downloadFile()">
                                   FILE: <span class="blue semibold-font">{{form.data.transaction.recipients.fileName}} </span>
                                </a>
                            </div>
                            <!-- <div id="recOption" *ngIf="form.data.transaction.recipients.option == 'fileNumbers'" tabindex="0">
                                <a (click)="downloadFile()">
                                   FILE: <span class="blue semibold-font">{{form.data.transaction.recipients.fileNumbers}} </span>
                                </a>
                            </div> -->
                            <div id="recOption" *ngIf="form.data.transaction.recipients.option == 'ad'" tabindex="0">
                                <span>
                                    {{form.data.transaction.recipients.ad}}
                                </span>
                            </div>
                            <div id="recOption" *ngIf="form.data.transaction.recipients.option == 'eids'" tabindex="0">
                                <span *ngFor="let recipient of form.data.transaction.recipients.eid.split(',')">
                                    {{recipient}}
                                </span>
                            </div>
                            <!-- <div id="recOption" *ngIf="form.data.transaction.recipients.option == 'eidsmishing'" tabindex="0">
                                <span>
                                    {{form.data.transaction.recipients.eidsmishing}}
                                </span>
                            </div> -->

                        </div>

                    </div>

                <!-- <div class="transaction-detail" *ngIf="form.data.transaction.alertType.type != 'EmergingThreat'"> -->
                    <div class="transaction-detail" *ngIf="form.data.transaction.recipients.option != 'ad'">
                        <div class="transaction-label">
                            <span aria-label="Total Recipients"  class="semibold-font" tabindex="0">
                                TOTAL RECIPIENTS:
                            </span>
                        </div>
                        <div aria-labelledby="recCount"  class="recipient-data" tabindex="0">
                            <span id="recCount">
                                {{recipientsCount}}
                            </span>
                        </div>

                    </div>


                    <div class="transaction-detail">
                        <div class="transaction-label">
                            <span aria-label="Title" class="semibold-font" tabindex="0">
                                TITLE:
                            </span>
                        </div>
                        <div aria-labelledby="tTitle" class="transaction-data">
                            <span id="tTitle" tabindex="0">{{form.data.transaction.title}}</span>
                        </div>


                    </div>

                    <div class="transaction-detail" >
                        <div class="transaction-label">
                            <span aria-label="Short Description" class="semibold-font" tabindex="0">
                                SHORT DESCRIPTION:
                            </span>
                        </div>
                        <div aria-labelledby="shortDesc"  id="shortDesc" tabindex="0" class="transaction-data" [innerHTML]="form.data.transaction.shortDescription">
                        </div>
                    </div>
                    <div class="transaction-detail" >
                        <div class="transaction-label">
                            <span aria-label="Detailed Description"  class="semibold-font" tabindex="0">
                                DETAILED DESCRIPTION:
                            </span>
                        </div>
                        <div aria-labelledby="detailedDesc" id="detailedDesc" tabindex="0" class="transaction-data" [innerHTML]="form.data.transaction.bodyHtml">

                            <!-- <p><b>Take Action</b> </p>
                                  <p> if you have a LinkedIn account, update your password immediately. <br></p>

                                 <p>
                                   <b> <i>Note: </i> </b> Per policy, do not use your Accenture password logins for any social media accounts
                                 </p>    -->

                        </div>
                    </div>

                    <!-- <div class="transaction-detail" *ngIf="form.data.transaction.alertType.type == 'SmishingTest' ">
                      <div class="transaction-label">
                          <span aria-label="SMS Message"  class="semibold-font" tabindex="0">
                              SMS MESSAGE:
                          </span>
                      </div>
                      <div aria-labelledby="smsMessage" id="smsMessage" tabindex="0" class="transaction-data" [innerHTML]="form.data.transaction.smsMessage">
                      </div>
                  </div> -->

                    <div class="transaction-detail" *ngIf="form.data.transaction.alertType.type == 'EmergingThreat'">
                        <div class="transaction-label">
                            <span aria-label="Emerging Threat Question" tabindex="0" class="semibold-font">
                                EMERGING THREAT QUESTION:
                            </span>
                        </div>
                        <div aria-labelledby="etQ" id="etQ" tabindex="0" class="transaction-data" [innerHTML]="form.data.transaction.emerging.Question">

                            <!-- <p><b>Take Action</b> </p>
                                  <p> if you have a LinkedIn account, update your password immediately. <br></p>

                                 <p>
                                   <b> <i>Note: </i> </b> Per policy, do not use your Accenture password logins for any social media accounts
                                 </p>    -->

                        </div>
                    </div>

                    <div class="transaction-detail" *ngIf="form.data.transaction.alertType.type == 'EmergingThreat'">
                        <div class="transaction-label">
                            <span aria-label="Response Options" class="semibold-font" tabindex="0">
                                RESPONSE OPTIONS:
                            </span>
                        </div>
                        <div class="transaction-data" >
                            <div aria-labelledby="answer" tabindex="0" class="response-datas" *ngFor="let answer of form.data.transaction.emerging.AllAns">
                                {{answer}}
                            </div>
                         </div>
                    </div>

                    <div class="transaction-detail">
                        <div class="transaction-label">
                            <div aria-label="VERIFICATION" class="semibold-font" tabindex="0">
                                VERIFICATION:
                            </div>
                        </div>
                        <div class="transaction-data" aria-labelledby="reviewer" >
                            <span id="reviewer" tabindex="0" *ngFor="let reviewer of form.data.transaction.reviewers.split(',')">
                                {{reviewer}}
                            </span>
                        </div>

                    </div>

                    <div class="submitButton">
                        <div class="bold-font" (click)="back()" role=""button aria-label="Back" tabindex="0">
                            <span class="material-icons">
                                keyboard_arrow_left
                            </span>
                            BACK
                        </div>
                        <button role=""button aria-label="Save" tabindex="0" mat-raised-button class="bg-purple" (click)="save()" [disabled]='submitted'>
                            SAVE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>


</div>
