import { PublishedTableComponent } from './table/table.component';
import { DeleteComponent } from './../sharedComponent/delete/delete.component';
import { MatTableModule } from '@angular/material/table';
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { FormService } from 'src/app/core/form.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingComponent } from '../sharedComponent/loading/loading.component';
import { Config } from 'src/app/core/form.config';
import { Observable, observable } from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

export interface Transactions {
  alertID?: string;
  alertStatus?: string;
  alertType?:string;
  bodyHtml?:string;
  category?:string;
  createdBy?:string;
  createdDate?:string;
  etStatus?:string;
  groupID?: number;
  isAD?: boolean;
  modifiedBy?:string;
  modifiedDate?:string;
  publishedDate?:string;
  publisher?:string;
  ragStatus?:string;
  recipientCount?: number;
  recipientFileName?:string;
  recipients?:string;
  reviewer?:string;
  shortDescription?:string;
  title?:string;
}

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `<app-table-component [toDelete] = "toDelete"></app-table-component>`
})
export class TransactionComponent implements OnInit {
  isUnauthorized: boolean = false;
  hideRelease2andUp: boolean = false

  selectedIndex: number | null
  toDelete: any;

  status:boolean = false;
  //@Output() public isModalClosed: EventEmitter<any> = new EventEmitter();

  @ViewChild(PublishedTableComponent, { static: false }) childC: PublishedTableComponent;
  @ViewChild('pendingpaginator', {static:true}) pendingpaginator: MatPaginator;
  @ViewChild('publishpaginator', {static:true}) publishpaginator: MatPaginator;

  trashcansrc = '../../../assets/icons/trashcan.png'


  PendingColumns: string[] = ['select', 'alertID', 'title', 'createdBy', 'alertStatus', 'modifiedDate'];
  PublishedColumns: string[] = ['select', 'alertID', 'title', 'publisher', 'ragStatus', 'expiration', 'publishedDate', 'followUpPublishedDate'];
  PendingTransactionSource : any = new MatTableDataSource<Transactions>([]);
  PublishTransactionSource : any = new MatTableDataSource<Transactions>([]);
  constructor(public router: Router,
    public form: FormService,
    public dialog: MatDialog

  ) { }

  ngOnInit(): void {
    this.loadData();
  }


  public get pendingCount() {
    let retVal = '0  Item';
    if (this.PendingTransactionSource.data.length > 0) {
      retVal = this.PendingTransactionSource.data.length + ' items'
    }
    return retVal
  }

  public get publishCount() {
    let retVal = '0  Item';
    if (this.PublishTransactionSource.data.length > 0) {
      retVal = this.PublishTransactionSource.data.length + ' items'
    }
    return retVal
  }

  // load data and sort by date and publish date
  loadData() {
    const loading = this.dialog.open(LoadingComponent, {panelClass: 'custom-dialog'});
    console.log("TEST form.data.user: ", this.form.data.user);
    this.isUnauthorized = true;
    this.form.setUserAccess(this.form.data.user.eid).subscribe(userAccessData => {
      console.log("TEST setUserAccess: ", userAccessData)
      console.log("TEST isCDP: ", this.form.data.isCDP);

      if(this.form.data.user.isSuperAdmin == false && this.form.data.user.isStandardAdmin == false){
        if(this.form.data.isCDP){
          // this.router.navigateByUrl('/emergingthreatresponse');
        }else{
          //redirect to unauthorized page
          this.router.navigateByUrl('/unauthorized');
        }
      }else{
        // spinner
        this.isUnauthorized = false;
        this.form.getTransacation({ alertStatus: 'Published-Resolved-Verified-Draft-Verification Sent' }, 0).subscribe((data: any) => {
          console.log('smishing list', data.list.filter(item => item.alertType == "SmishingTest"))
          console.log('data list', data.list)
          this.PublishTransactionSource.data = data.list ? data.list.filter(item => item.alertStatus == "Published" || item.alertStatus == "Resolved").sort(this.form.sortPublishedDate) : [] ;
          //this.form.getTransacation("FVR", { alertStatus: 'Verified-Draft-Verification Sent' }, 0).subscribe((pendingData: any) => {
            this.PendingTransactionSource.data = data.list ? data.list.filter(item => item.alertStatus == "Verified" || item.alertStatus == "Draft" || item.alertStatus == "Verification Sent").sort(this.form.sortModifiedDate) : [] ;
            loading.close();
            this.PendingTransactionSource.paginator = this.pendingpaginator;
            this.PublishTransactionSource.paginator = this.publishpaginator;
            console.log("tobedeleted", this.form.data);
            this.form.checkStatus().subscribe(data => {
              this.status = data;
              console.log('Batch Status:',data);
            })

          //})
        })
        this.selectedIndex = this.form.data.trasactionSelectedIndex ? this.form.data.trasactionSelectedIndex : 0;
      }
    })


  }

  createTransaction() {
    this.form.data.transaction = undefined;
    this.router.navigateByUrl('/create');
  }

  selectedRow(data) {
    console.log("row has been selected")
    this.form.data.transaction = {};
    this.form.data.transaction = data;

    this.form.data.origTransaction = {};
    this.form.data.origTransaction = Object.assign({},data);

    // if (this.form.data.transaction.alertStatus == Config.AlertStatus.Verified) {
    //   this.router.navigateByUrl('/preview');
    // } else {

    if (this.form.data.transaction.alertType.type == 'EmergingThreat') {
      this.getEmergingData().subscribe(data => {
        console.log('Emerging alert loaded');
        this.router.navigateByUrl('/create');
      })
    } else {
      this.checkQID(this.form.data.transaction.alertID).subscribe(data => {
        this.router.navigateByUrl('/create');
      })

    }
    // }
  }



  selectedRowPublished(data) {
    this.form.data.transaction = {};
    this.form.data.transaction = data;
    // let eids = this.form.data.transaction.recipients.eid.trim().split(';')
    // this.form.data.transaction.totalrecipients = eids.length
    console.log("selectedRowPublished", this.form.data);
    const loading = this.dialog.open(LoadingComponent, { panelClass: 'custom-dialog'});
    if(this.form.data.transaction.alertType.type == 'EmergingThreat'){
      this.getReport(this.form.data.transaction.alertID).subscribe(data => {
        loading.close();
        this.router.navigateByUrl('/view-transaction');
      })
    }
    else{
      loading.close();
        this.router.navigateByUrl('/view-transaction');
    }



  }

  getReport(alertid) {
    return new Observable(observable => {
      console.log("getting report")
      this.form.getReport(alertid).subscribe(report => {
        observable.next(report)
      })
    })

  }

  checkQID(alertId) {
    return new Observable((observable) => {
      this.form.checkQID(alertId).subscribe(result => {
        observable.next('done');
      })
    })
  }


  public getEmergingData() {
    return new Observable(observer => {
      console.log("emergin UNDEFINED", this.form.data.transaction)
      this.form.getQA(this.form.data.transaction.alertID).subscribe(result => {
        let answers = []
        let ctr = 0;
        console.log("1st emerging data", this.form.data.transaction.emerging, result)
        for (let prop in this.form.data.transaction.emerging) {
          if (prop.includes('Answer')) {
            let propname = 'Answer' + ctr
            console.log("counter", ctr, prop)
            answers.push(this.form.data.transaction.emerging[prop])
            this.form.data.transaction.emerging[propname] = this.form.data.transaction.emerging[prop]
            ctr++
            delete this.form.data.transaction.emerging[prop]
          }

          console.log("setting ANSWERS")
        }
        this.form.data.transaction.emerging.AllAns = answers
        observer.next('done');
        console.log("2nd emerging data", this.form.data.transaction.emerging)
      })
    })

  }

  modalClosed(data) {
    //console.log(data);
    if (data == "updated") {
      this.form.data.trasactionSelectedIndex = 1;
      this.loadData();
    }

  }

  deleteData() {
    console.log("data to be deleted", this.form.data.transaction.toBeDeleted)
  }

  // delete dialog.
  openDialog(element) {
    const dialogRef = this.dialog.open(DeleteComponent, {
      disableClose: true,
      data: element.length
    });
    dialogRef.afterClosed().subscribe(data => {
      console.log("sucessfully deleted: ", data)
      if(data){
        this.loadData();
        this.childC.updateSelection()
      }
      //this.isModalClosed.emit(data);
    })
  }

  onTabClick(event) {
    console.log("ontab", event.index)
    console.log("todelete: ", this.form.data.transaction.toBeDeleted)
    this.form.data.trasactionSelectedIndex = event.index
    this.PendingTransactionSource.paginator.pageIndex = 0;
    this.PublishTransactionSource.paginator.pageIndex = 0;
    this.PendingTransactionSource.paginator = this.pendingpaginator;
    this.PublishTransactionSource.paginator = this.publishpaginator;
   }

}
