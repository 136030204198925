import { Component, OnInit } from '@angular/core';
import { FormService } from 'src/app/core/form.service';


const ELEMENT_DATA: any[] = [
  {
    'question': 'Do you suspect your client account has been impacted?',
    'responses': [
      {
        "response": "Yes",
        "totalresponse": 200
      },
      {
        'response': 'No',
        "totalresponse": 589
      },
      {
        'response': 'Forward to Team Members',
        "totalresponse": 168
      }
    ],
    'dataRead': 200,
    'dataUnread': 200
  },
  {
    'question': 'When did you learn about the event?',
    'responses': [
      {
        "response": "< 1 Hour",
        "totalresponse": 36,
      },
      {
        'response': 'Today',
        "totalresponse": 18
      },
      {
        "response": "Yesterday",
        "totalresponse": 4
      },
      {
        "response": "This Week",
        "totalresponse": 4
      },
    ],
    'dataRead': 200,
    'dataUnread': 200
  }

];


@Component({
  selector: 'app-emergingquestions',
  templateUrl: './emergingquestions.component.html',
  styleUrls: ['./emergingquestions.component.scss']
})
export class EmergingquestionsComponent implements OnInit {
  dataSource;
  totalResponses = [];
  mobileCounts = [];
  webCounts = [];
  unknowns = [];
  constructor(public form: FormService) { }




  ngOnInit(): void {
    this.dataSource;
    this.initData();

  }

 initData(){
  if (this.form.data.transaction.alertType.type == 'EmergingThreat') this.getReport(this.form.data.transaction.alertID)
    this.dataSource = this.form.data.Report.report
    console.log(this.dataSource);
    this.totalResponses = [];
    this.mobileCounts = [];
    this.webCounts = [];
    this.unknowns = []

    // this.dataSource = ELEMENT_DATA;
 }


  getReport(alertid) {
    this.totalResponses = [];
    this.mobileCounts = [];
    this.webCounts = [];
    this.form.getReport(alertid).subscribe(report => {
      this.dataSource = this.form.data.Report.report
      console.log("datasource", this.dataSource)

      this.dataSource.forEach(question => { //getting totalResponses per question
        let totalResponse = 0;
        let mobileCount= 0;
        let webCount = 0;
        let unknown = 0
        question.responses.forEach(response => {
          totalResponse = totalResponse + response.totalresponse;
          mobileCount = mobileCount + response.mobilecount;
          webCount = webCount + response.webcount;
          unknown = totalResponse - (mobileCount + webCount)
        })
        this.totalResponses.push(totalResponse);
        this.mobileCounts.push(mobileCount);
        this.webCounts.push(webCount);
        this.unknowns.push(unknown)
      });
    })
  }


  getData(type, event) {
    console.log("id", event.id)
    let qid = event.id.split('-')[0]
    let aid = event.id.split('-')[1]
    console.log("qid aid", qid, aid)
    let question = this.form.data.Report.report.filter(item => item.qid == qid)
    console.log(question);
    let eids
    let QnA = [];
    if (type == 'question') {
      eids = question[0].eids
      console.log("fetch eids", eids, question)
    } else {
      let responses = ["Question", question[0].question];
      QnA.push(responses);
      let answers = question[0].responses.filter(item => item.aid == aid)
      responses = ["Respondents", "Client Account", "Response", "Response Date and Time(UTC)", "Response Date and Time(CST)"];
      QnA.push(responses);
      answers[0].clientAccounts.forEach(element => {
        responses = [element.eid, element.clientAccount, answers[0].response, element.responseDate, element.responseDateCST];
        QnA.push(responses);
      });
      eids = answers[0].clientAccounts
      console.log("fetch answers eids", answers)
    }

    if (eids.length != 0) {
      if(type === 'answer'){
        this.form.downloadExcelResponses(QnA, "ASA-Responses EIDs-Response ").subscribe(result => {
          if (result) {
            console.log("excel downloaded")
          }
        })
      }else{
        this.form.downloadExcel(eids, "ASA-Responses EIDs").subscribe(result => {
          if (result) {
            console.log("excel downloaded")
          }
        })
      }
    }

  }


  getAllData(questionIndex:number){
    let QnA = [];
    let question = this.form.data.Report.report;
    console.log(question);
    let responses = ["Question", question[questionIndex].question];
    QnA.push(responses);
    responses = ["Respondents", "Client Account" ,"Latest Response", "Response Date and Time(UTC)", "Response Date and Time(CST)", "Responded Via"];
    QnA.push(responses);

    let data = [];
    for(let j=0; j < question[questionIndex].responses.length; j++){ //per response choice of the question
      if(question[questionIndex].responses[j].eids.length){ //checks if there is a response to the choices, gets the eids
        for(let k=0; k < question[questionIndex].responses[j].clientAccounts.length; k++){ //per clientAccount-eid
          console.log("loop:  ",  question[questionIndex].responses[j].clientAccounts[k].RespondedVia)
          const responseEID = question[questionIndex].responses[j].clientAccounts[k].eid;
          const clientAccount = question[questionIndex].responses[j].clientAccounts[k].clientAccount === 'N/A' ? "" : question[questionIndex].responses[j].clientAccounts[k].clientAccount;
          const response = question[questionIndex].responses[j].response;
          const responseDate = question[questionIndex].responses[j].clientAccounts[k].responseDate;
          const responseDateCST = question[questionIndex].responses[j].clientAccounts[k].responseDateCST;
          const respondedVia = question[questionIndex].responses[j].clientAccounts[k].RespondedVia ? question[questionIndex].responses[j].clientAccounts[k].RespondedVia : " ";
          data = [responseEID,clientAccount,response, responseDate, responseDateCST, respondedVia];
          QnA.push(data);
        }
      }
    }

    if(data.length != 0) {
      this.form.downloadExcel(QnA, "ASA-Responses EIDs-Response").subscribe(result => {
        if (result) {
          console.log("excel downloaded")
        }
      })
    }
  }

}
