export const environment = {
  production: false,
  api:{
    getCountries: "assets/data/location.json",
    transaction: "https://alwayssafemobileapp-api.ciotest.accenture.com/transaction",
    notification: "https://alwayssafemobileapp-api.ciotest.accenture.com/notification",
    notificationAD: "https://alwayssafemobileapp-api.ciotest.accenture.com/notification/ad",
    notificationAllMobileUsers: "https://alwayssafemobileapp-api.ciotest.accenture.com/notification/allmobileusers",
    notificationMultipleUsers: "https://alwayssafemobileapp-api.ciotest.accenture.com/notification/multipleusers",
    // notificationSMS: "https://alwayssafemobileapp-api.ciotest.accenture.com/notification/sms",
    getQADetails: "https://alwayssafemobileapp-api.ciotest.accenture.com/user/response",
    alert: "https://alwayssafemobileapp-api.ciotest.accenture.com/user/alert",
    getReport: "https://alwayssafemobileapp-api.ciotest.accenture.com/transaction/response",
    registration: "https://alwayssafemobileapp-api.ciotest.accenture.com/register",
    cdpUsers: "https://alwayssafemobileapp-api.ciotest.accenture.com/user/cdp-users",
    reminder: "https://alwayssafemobileapp-api.ciotest.accenture.com/reminder",
    accessmapping: "https://alwayssafemobileapp-api.ciotest.accenture.com/user/access-mapping",
    accessreports: "https://alwayssafemobileapp-api.ciotest.accenture.com/user/access-reports",
    getAlertResponse: "https://Alwayssafemobileapp-api.ciotest.accenture.com/user/response",
    Answer: "https://Alwayssafemobileapp-api.ciotest.accenture.com/answer",
    reports: "https://alwayssafemobileapp-api.ciotest.accenture.com/reports",
    getReportsPage: "assets/data/reports_test.json",
    score: "https://Alwayssafemobileapp-api.ciotest.accenture.com/user/score",
    totalReads: "https://alwayssafemobileapp-api.ciotest.accenture.com/transaction/read",
    etstatus: "https://alwayssafemobileapp-api.ciotest.accenture.com/user/alert/etstatus",
    // phoneNumbers: "https://alwayssafemobileapp-api.ciotest.accenture.com/user/phonenumbers"
  },
  providers: 'app',
  isTest: false,
  isTestUser: false,
  mockClient: true,
  eso: {
    Config: {
      scope: 'api://0882e4e2-842c-49ad-a672-f9c1b551fb69/user_impersonation',
      issuer: 'https://sts.windows.net/f3211d0e-125b-42c3-86db-322b19a65a22/',
      authorizationURL: "https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/",
      clientId: "d1a1a18a-52d6-4f41-9304-741fbc4c3411",
      callback: "https://alwayssafemobileapp.ciotest.accenture.com",
      resource: "api://0882e4e2-842c-49ad-a672-f9c1b551fb69",
      state: "xyz",
      logoutUrl: "https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/oauth2/logout",
      openId: "https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/.well-known/openid-configuration",
      oidc: true
    }
  },
  dataDog:{
    clientToken:'pub56008dae985b2fe4b03ca170f37b01f1',
    applicationId:'75a16864-aee5-47b0-8f30-3548b8bd2170',
    service:'83308_alwayssafeappalertsadmintool_rum_testing',
    env:'test'

  }
};
