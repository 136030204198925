
<mat-toolbar class="nav-container">
    <div class="nav-title-container" >

      <img role="img" aria-label="Always Safe App logo" src="assets/Always SafeLogo.png"/>
    <span class="nav-title" role="region" aria-label="Always Safe">ALWAYS SAFE</span>

    </div>


</mat-toolbar>
