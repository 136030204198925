import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-et-table',
  templateUrl: './et-table.component.html',
  styleUrls: ['./et-table.component.scss']
})
export class EtTableComponent implements OnInit {
  @Input() displayedColumns: string[];
  @Input() dataSource = [];
  @Input() index: number;

  @Output()
  public selected: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  select(data){
    this.selected.emit(data);
  }

}
