import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor() { }

  downloadCsv(fileName, value){
    let concatenatedString = "";
    value.split(';').forEach(element => {
      concatenatedString = concatenatedString == "" ? element.trim() : concatenatedString += "\r\n"+ element.trim();
    });;
    var a = document.createElement('a');
    var blob = new Blob([concatenatedString], {type: 'text/csv' }),
    url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  
  downloadCsvArray(fileName, value){
    let concatenatedString = "";
    value.forEach(element => {
      concatenatedString = concatenatedString == "" ? element.trim() : concatenatedString += "\r\n"+ element.trim();
    });;
    var a = document.createElement('a');
    var blob = new Blob([concatenatedString], {type: 'text/csv' }),
    url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
