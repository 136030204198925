import { Component, OnInit, ViewChild, ElementRef, Renderer2, Input } from '@angular/core';

import { FormService } from 'src/app/core/form.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-emergingthreat',
  templateUrl: './emergingthreat.component.html',
  styleUrls: ['./emergingthreat.component.scss']
})
export class EmergingthreatComponent implements OnInit {
  @Input()
  createTransaction: FormGroup;
  @Input()
  errorChecker: boolean;

  @ViewChild("quillEditorAns", {static: false}) quillEditorAns: ElementRef;

  @Input()
  submitted: boolean;
  get f() { return this.createTransaction.controls; }

  currentId = 0;
  addResponses = true

  response = [
    { "label": "0" },
    { "label": "1" }
  ]

  constructor(
    public form: FormService,
    public formBuilder: FormBuilder,
    public renderer: Renderer2) {
  }



  ngOnInit(): void {

    this.createTransaction = this.formBuilder.group({
      question: ['', Validators.required],
      btnRows: this.formBuilder.array([])
    });

    this.createTransaction.valueChanges.subscribe(data => {
      // console.log("createtrasaction f changes", this.createTransaction)
      //console.log("createtrasaction f l", this.f['btnRows']['controls'][0]['controls']['emergingAnswer0'].errors)

      //this.createTransaction.markAllAsTouched();


    })
    this.setInitData();


    this.setNewResponse();
     if (this.errorChecker) this.createTransaction.markAllAsTouched();

    console.log('beginning', this.createTransaction.controls);

  }


  setNewResponse() {

    console.log("formArr", this.formArr);
    console.log("emerginhAns", this.form.data.transaction.emerging)

  }

  setInitData() {
    console.log("alert type", this.form.data.transaction)
    if (this.form.data.transaction.alertType.type == "EmergingThreat") {
      if (this.form.data.transaction.emerging == undefined || this.form.data.transaction.emerging.AllAns == undefined) {
        console.log("EMERGING WAS UNDEFINED OR AllAns was equal to 0")
        this.form.data.transaction.emerging = {}
        for (let i = 0; i < 2; i++) {
          console.log("append i", i)
          this.append(i);
        }
      } else {
        let j = 0;
        for (let prop in this.form.data.transaction.emerging) {
          console.log("EMERGING WAS NOT UNDEFINED", this.form.data.transaction.emerging)
          if (prop.includes('Answer')) {

            this.append(j);
            j++;

          }
        }
      }
      this.form.data.origTransaction = Object.assign({},this.form.data.transaction);
    }


    setTimeout(() => {
      this.setAccessibility();
    }, 2000)

  }

  public get questionCounter() {
    let retVal = 0;
    if (this.form.data.transaction.emerging.Question) {
      let replaced = this.form.data.transaction.emerging.Question.replace(/<[^>]+>/g, '');
      replaced = replaced.replace("&nbsp;", ' ');
      retVal = replaced.length + (this.form.data.transaction.emerging.Question.split('</p><p>').length - 1);
      // retVal = replaced.length;
      if (retVal >= 200) {
        retVal = 200;
      } else {
        retVal = retVal;
      }

    }

    return retVal;
  }

  questionStyle = {
    height: '100px',
    background: '#fff'
  }
  public toolbarOptions = [['bold', 'italic', 'underline'], ['clean'], ['link']];
  public quillLinkRemove = {
    'link': function (value: string) {
      const that: any = this;

      const tooltip = that.quill.theme.tooltip;
      const input = tooltip.root.querySelector("input[data-link]");
      input.dataset.link = "https://";
      input.placeholder = "http://";
      input.dataset.lpignore = true;


      if (value) {
        const range = that.quill.getSelection();
        if (range == null || range.length === 0) { return; }
        let preview = that.quill.getText(range);
        if (
          /^\S+@\S+\.\S+$/.test(preview) &&
          preview.indexOf("mailto:") !== 0
        ) {
          preview = `mailto:${preview}`;
        }
        const { tooltip } = that.quill.theme;
        tooltip.edit("link", preview);
        input.value = "https://" + preview;
      } else {
        that.quill.format("link", false);
      }
    },
  }
  question_detail = {
    toolbar: {
      container: this.toolbarOptions,  // Selector for toolbar container
      handlers: this.quillLinkRemove
    }

  }
  newBtn: boolean = false;

  itemrows(id) {
    let prop = "emergingAnswer" + name;
    return this.formBuilder.group({
      ["emergingAnswer" + id]: ['', Validators.required],
    });
  }

  get formArr() {
    return this.createTransaction.get('btnRows') as FormArray;

  }
  textChanged($event, data) {

    if ($event.editor.getLength() > 200) {
      $event.editor.deleteText(200, $event.editor.getLength());
      if($event.source == 'api'){
        this.form.data.transaction.emerging.Question = $event.html;
      }
    }


  }


  append(id) {

    // var itm = document.getElementById("appendButtons").lastChild;
    // var cln = itm.cloneNode(true);
    // if(document.getElementById("appendButtons").childElementCount < 10){
    //   document.getElementById("appendButtons").appendChild(cln)
    //   document.getElementById("appendButtons").lastElementChild.classList.add('emerging-btn');
    //   //
    // }
    // console.log("edsel", this.formService)
    console.log(this.itemrows(id));
    if (this.formArr.controls.length < 10) {
      this.formArr.push(this.itemrows(id));
      this.currentId = id;

    }
    if (this.formArr.controls.length == 10) {
      this.addResponses = false
    }

  }


  reappend(id) {

    this.formArr.removeAt(id);
    this.deleteData(this.formArr.controls.length)

    //this.deleteData( this.currentId = this.currentId);
    this.currentId = this.currentId - 1;
    // this.formArr.removeAt();
    // if (index == 0) {
    //   console.log("index", index)

    // }
    // else if (index == 1) {
    //   console.log("index", index)

    // }
    // else if (index == 2) {
    //   console.log("index", index)
    //   this.deleteData(index)
    //   this.formArr.removeAt(index);
    // }
    // var item = document.getElementById("appendButtons").lastChild;
    // var itmclass = document.getElementsByClassName("emerging-btn");
    // if(itmclass.length != 0){
    //   item.remove();
    // }
    // console.log("index", index)
    // if (index != 0 || index != 1) {
    //   this.deleteData(index)
    //   this.formArr.removeAt(index);
    // }

    if (this.formArr.controls.length < 10) {
      this.addResponses = true
    }

  }

  deleteData(index) {
    delete this.form.data.transaction.emerging['Answer' + index]
    if (this.form.data.transaction.emerging.hasOwnProperty('AllAns')) {
      console.log("has all ans")
      delete this.form.data.transaction.emerging.AllAns[index]
    }
    console.log("deleted updated answers", this.form.data.transaction.emerging)
  }

  public setAccessibility(){
    const queryQL =   this.quillEditorAns.nativeElement.getElementsByClassName('ql-toolbar');
    if (queryQL.length !== 1) {
      return;
    }

    const qltoolBar = queryQL[0];

    // apply aria labels to base buttons
    const buttons = qltoolBar.getElementsByTagName('button');
    for (let i = 0; i < buttons.length; i++) {
      const button = buttons[i];
      const className = button.getAttribute('class').toLowerCase();

      if (className.indexOf('bold') >= 0) {
        button.setAttribute('aria-label', 'Toggle bold text');
      } else if (className.indexOf('italic') >= 0) {
        button.setAttribute('aria-label', 'Toggle italic text');
      } else if (className.indexOf('underline') >= 0) {
        button.setAttribute('aria-label', 'Toggle underline text');
      } else if (className.indexOf('clean') >= 0) {
        button.setAttribute('aria-label', 'Toggle clean text');
      } else if (className.indexOf('link')>= 0) {
        button.setAttribute('aria-label', 'Toggle link text');
      }
    }

  }



}
