import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormService } from 'src/app/core/form.service';
@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  private formService

  status:boolean;

  constructor(public form: FormService) {
    this.formService = form
   }


  ngOnInit(): void {
    console.log("formservice", this.formService)
    this.form.checkStatus().subscribe(data => {
      this.status = data;
    })
  }

  @Input()
  public show;


  @Input()
  public buttonText;

  @Input()
  public hideButton;

  @Input()
  public type;

  @Input()
  public confirmMessage;

  @Input()
  public disableEdit

  @Output()
  public clicked: EventEmitter<{eventName:string,batchStatus:boolean}> = new EventEmitter();


  clickSave() {
    this.clicked.next({eventName:this.type,batchStatus:this.status});
  }

  transaction(ev){
    this.clicked.next({eventName:ev,batchStatus:this.status});
  }
  //*ngIf="form.data.user.isSuperAdmin"
}
