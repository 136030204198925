import { Component, OnInit } from '@angular/core';
import { FormService } from 'src/app/core/form.service';
import { Router } from '@angular/router';

import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { OAuthStorage } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginDisplay = false;
  authenticated = false;

  constructor(
    private form: FormService,
    private authService: MsalService,
    public router: Router,
    private oauthStorage: OAuthStorage
  ) { }

  ngOnInit(): void {

    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        this.form.setUser();
        if (result) {
            console.log('redirect obs',result);
            this.authService.instance.setActiveAccount(result.account);
            let value = this.oauthStorage.getItem('url');
            if(value == null || value == ''){
              this.router.navigateByUrl('/transaction');
            }
          }
          // Sets login display when using redirects
        this.setLoginDisplay();
      },
      error: (error) => console.log(error)
    });

  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

}
